export const testSampleConstants = {

    GET_ALL_TEST_SAMPLES : 'GET_ALL_TEST_SAMPLES',
    GET_ALL_TEST_SAMPLES_PENDING : 'GET_ALL_TEST_SAMPLES_PENDING',
    GET_ALL_TEST_SAMPLES_FULFILLED : 'GET_ALL_TEST_SAMPLES_FULFILLED',
    GET_ALL_TEST_SAMPLES_FAILURE : 'GET_ALL_TEST_SAMPLES_FAILURE',
    GET_ALL_TEST_SAMPLES_REJECTED : 'GET_ALL_TEST_SAMPLES_REJECTED',

    
};
