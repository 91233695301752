import React, { useState, useEffect } from 'react';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Button, Card, Form, Dropdown, ButtonGroup, DropdownButton } from 'react-bootstrap';
import { FaCaretSquareDown, FaSync, FaToggleOff, FaArrowsAlt, FaThList } from "react-icons/fa";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
import ReactPaginate from 'react-paginate';
import {AiFillForward} from 'react-icons/ai'
import {HiRewind} from 'react-icons/hi'
import ShowForPermissionComponent from '../../permissions/permission'



const TableAction = (props) => {
    const handle = useFullScreenHandle();
    const [selected, setSelected] = useState([]);
    const [isShowSearch, setIsShowSearch] = useState(true)
    const [isShowRefresh, setIsShowRefresh] = useState(true)
    const [selectRow, setSelectedRow] = useState({
        mode: 'checkbox',
        clickToSelect: true,
        // selected: selected,
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll
    })

    useEffect(() => {
        setIsShowSearch(props?.isShowSearch?? true)
        setIsShowRefresh(props?.isShowRefresh?? true)
        console.log("🚀 ~ file: actions.js ~ line 26 ~ useEffect ~ props", props)
    }, [props])

    function handleOnSelect(row, isSelect) {
        if( row?.id){
            if (isSelect ) {
                setSelected([...selected, row?.id])
            } else {
                setSelected(selected.filter(x => x !== row?.id))
            }
        }
    }

    function handleOnSelectAll(isSelect, rows) {
        const ids = rows.map(r => r?.id);
        if (isSelect === true) {
            setSelected(ids);
        } else {
            setSelected([]);
        }
    }

    useEffect(() => {
        if(props.isHideSelectBox){
            setSelectedRow ({
                mode: 'checkbox',
                clickToSelect: true,
                hideSelectColumn: true,
                bgColor: '#00BFFF'
            })
        }
    }, [props.isHideSelectBox])

    const onSearch =(val) => {
        props.onSearch(val);
    } 

    const onPageChangeTable = (val) => {
        props.onPageChange(val.selected+1)
    }




    return (
        <FullScreen handle={handle} className="h-100 w-100" >
            <Card>
                <Card.Header className="pb-2  border-0 d-flex align-items-center justify-content-between">
                {
                    props?.title?.length>0 &&
                    <h3 className="mb-0">Manage {props.title[0]}</h3>
                }
                    <div>
                        {
                            props?.export &&

                            // <DropdownButton variant="info" id="dropdown-basic-button" title="Export">
                                <button href="#" className="btn btn-primary btn-lg " onClick={()=>props?.export('excel')}>Excel</button>
                                // {/* <Dropdown.Item href="#/action-2">PDF</Dropdown.Item> */}
                            // </DropdownButton>
                        }
                        {' '}
                        {(props.isShowTransfer  && selected && selected.length > 0) ?
                            <button href="#" onClick={() => props.onTransfer(selected)} className="btn btn-warning btn-md ">{props.title[2]}</button> : <></>}
                        {' '}
                        {typeof (props.onAdd) === 'function' ?
                            props.addPermission ?
                            <ShowForPermissionComponent permission={props?.addPermission}> 
                                <button href="#" onClick={props.onAdd} className="btn btn-success btn-lg ">{props.title[1]}</button> : <></>
                            </ShowForPermissionComponent>
                            :
                            <button href="#" onClick={props.onAdd} className="btn btn-success btn-lg ">{props.title[1]}</button> : <></>
                        }

                    </div>

                </Card.Header>
                <Card.Body>
                    <RemotePagination
                        handle={handle}
                        data={props.data??[]}
                        columns={props.columns}
                        page={props.page}
                        sizePerPage={props.sizePerPage}
                        totalSize={props.totalSize}
                        totalPage={props.totalPage}
                        onTableChange={props.handleTableChange}
                        selectRow={selectRow}
                        onFresh={props.onFresh}
                        inputRef={props.inputRef}
                        onPageChange={onPageChangeTable}
                        loading={props.loading ?? false}
                        // isShowCheckBox={props.isShowCheckBox ?? true}
                        keyField={props.keyField ?? 'id'}
                        onSearch={(val) => {props.onSearch(val);console.log("dddddddddddddddddd",val.target.value);}}
                        isShowSearch={isShowSearch}
                        isShowRefresh={isShowRefresh}
                    />
                </Card.Body>
            </Card>
        </FullScreen>
    )
};

export default TableAction;


const RemotePagination = ({ data,keyField,loading,isShowSearch,isShowCheckBox, inputRef,columns, handle, page,onFresh, sizePerPage, onTableChange,totalPage, totalSize, selectRow,onSearch,onPageChange,pageButtonRenderer,isShowRefresh = true }) => (

   

    <ToolkitProvider
        boostrap4={true}
        data={data ?? []}
        columns={columns}
        keyField={keyField}
        loading={true}
        remote={{
            filter: false,
            pagination: true,
            sort: true,
            cellEdit: false
        }}
        columnToggle
    >
        {toolProps => (
            <div>
                <div className="fixed-table-toolbar pb-2 mt-n4">
                    <div className="bs-bars float-left">
                        <div id="toolbar">
                        </div>
                    </div>
                    {
                        isShowRefresh &&
                            <ButtonGroup variant="light">
                                {/* <Button variant="light"><FaCaretSquareDown /></Button> */}
                                <Button variant="light" onClick={onFresh} ><FaSync /></Button>
                                {/* <Button variant="light"><FaToggleOff /></Button> */}
                                <Button variant="light" onClick={handle.enter}><FaArrowsAlt /></Button>
                                <CustomToggleList {...toolProps.columnToggleProps} />
                            </ButtonGroup>
                    }
                    {
                        isShowSearch &&    
                        <div className="float-right search btn-group">
                            <input className="form-control search-input" ref={inputRef}  onChange={(val) => onSearch(val)} type="search" placeholder="Search" autoComplete="off" />
                        </div>
                    }
                </div>
                <PaginationProvider
                    pagination={
                        paginationFactory({
                            custom: true,
                            page,
                            sizePerPage,
                            totalSize,
                            // pageButtonRenderer
                            // onPageChange
                        })
                    }
                >
                    {
                        ({
                            paginationProps,
                            paginationTableProps
                        }) => (
                                <div>

                                    <BootstrapTable
                                        {...toolProps.baseProps}
                                        {...paginationTableProps}
                                        onTableChange={onTableChange}
                                        selectRow={selectRow ?? false}
                                        loading={ loading } 
                                        overlay={ overlayFactory({ spinner: true, background: 'rgba(242, 44, 44, 1)' }) }
                                        noDataIndication="No Data Is Available"
                                        />
                                    <div className="pt-2">
                                        <PaginationTotalStandalone
                                            {...paginationProps}
                                        />{' '}
                                        {/* <SizePerPageDropdownStandalone
                                            btnContextual
                                            {...paginationProps}
                                        /> */}
                                        {/* <PaginationListStandalone 
                                            {...paginationProps}
                                            // {...paginationTableProps}
                                            // onPageChange={onPageChange}
                                            onPageChange={onPageChange}
                                        /> */}
                                        <ReactPaginate
                                            previousLabel={<HiRewind/>}
                                            nextLabel={<AiFillForward/>}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={totalPage??10}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={onPageChange}
                                            containerClassName={'pagination'}
                                            activeClassName={'active'}
                                            />
                                    </div>

                                </div>
                            )
                    }
                </PaginationProvider>
            </div>
        )}
    </ToolkitProvider>
);

const CustomToggleList = ({ columns, onColumnToggle, toggles }) => (
    <DropdownButton as={ButtonGroup} title={<FaThList />} id="bg-nested-dropdown" variant="light">
        {columns
            .map(column => ({
                ...column,
                toggle: toggles[column.dataField]
            }))
            .map((column, index) => (
                <div className="pt-2 pb-2 pr-2 pl-3">

                    <Form.Check
                        type="checkbox"
                        key={column.dataField}
                        inline
                        label={column.text}
                        id={column.dataField}
                        //   aria-pressed={(column.toggle) ? "true" : "false"}
                        checked={column.toggle}
                        aria-checked={column.toggle ? "true" : "false"}
                        onChange={() => onColumnToggle(column.dataField)}
                    //   onClick={() => onColumnToggle(column.dataField)}
                    />
                </div>
            ))}
    </DropdownButton>
);