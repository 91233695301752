import { applyMiddleware, createStore } from 'redux';
import { combineReducers } from 'redux';

import logger from 'redux-logger';
import thunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import userReducer from './pages/login/redux/reducer.js';
import laravelLoginReducer from './pages/login_loader/redux/reducer.js';
import patientReducer from './pages/patient_or_guardian/redux/reducer.js';
import appointmentReducer from './pages/services/appointments/redux/reducer.js';
import serviceScheduleReducer from './pages/services/schedule/redux/reducer.js';
import serviceHomeReducer from './pages/services/home/redux/reducer.js';
import myServicePendingReducer from './pages/my_service_pending/redux/reducer.js';
import usersSelectReducer from './components/users/redux/reducer.js';
import testTypesSelectReducer from './components/test/redux/reducer.js';
import serviceCentersSelectReducer from './components/service_center/redux/reducer.js';
import serviceTypesSelectReducer from './components/service/redux/reducer.js';
import pendingServiceApprovalReducer from './pages/services/service_approval_pending/redux/reducer.js';
import serviceApprovedReducer from './pages/services/service_approved/redux/reducer.js';
import serviceApprovedCheckedReducer from './pages/services/service_approval_checked/redux/reducer.js';
import serviceApprovedSignedReducer from './pages/services/service_approval_signed/redux/reducer.js';
import getSamplesReducer from './pages/labs/get_sample/redux/reducer.js';
import productSelectReducer from './components/Products/redux/reducer.js';
import serviceAppointmentReducer from './pages/service_appointments/redux/reducer.js';
import usageTemplateReducer from './pages/services/usage_template/redux/reducer.js';

// Labs
import appointmentLabReducer from './pages/labs/appointments/redux/reducer.js';
import myLabPendingReducer from './pages/labs/my_lab_pending/redux/reducer.js';
import reportTemplateReducer from './pages/labs/report_template/redux/reducer.js';
import labApprovedCheckedReducer from './pages/labs/lab_approval_checked/redux/reducer.js';
import labApprovedSignedReducer from './pages/labs/lab_approval_signed/redux/reducer.js';
import labApprovalReducer from './pages/labs/lab_approval_approved/redux/reducer.js';
import dashboardLabReducer from './pages/labs/dashboard/redux/reducer.js'
import testSampleReducer from './pages/labs/test_samples/redux/reducer.js'

const middleware = applyMiddleware(promiseMiddleware, thunk, logger);

export default createStore(combineReducers({
    userReducer,
    laravelLoginReducer,
    patientReducer,
    appointmentReducer,
    serviceScheduleReducer,
    serviceHomeReducer,
    myServicePendingReducer,
    usersSelectReducer,
    serviceTypesSelectReducer,
    pendingServiceApprovalReducer,
    serviceApprovedReducer,
    productSelectReducer,
    serviceAppointmentReducer,
    serviceApprovedCheckedReducer,
    serviceApprovedSignedReducer,
    usageTemplateReducer,

    // LAbs
    appointmentLabReducer,
    myLabPendingReducer,
    labApprovedCheckedReducer,
    labApprovedSignedReducer,
    getSamplesReducer,
    reportTemplateReducer,
    testTypesSelectReducer,
    serviceCentersSelectReducer,
    labApprovalReducer,
    dashboardLabReducer,
    testSampleReducer


}), middleware)