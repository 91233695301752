
import {appointmentConstants} from './constants.js';
import {myServicePendingActionsConstants} from '../../../my_service_pending/redux/constants.js';
export default function appointmentReducer(state={
    error: null,
    loading: {
        getAll:false,
        patientSearch:false,
        referredDoctorSearch:false,
        referredCenterSearch:false,
    },
    fetched: false,
    // Add Patients
    patients: [],
    patient: {},
    saved: {},
    // appointment: {},
    partners: [],
    partner: {},
    guardians: [],
    referredDoctors: [],

    patientAppointment: {},
    patientType: [],
    doctors: [],
    
    doctorTimeSlots: [],
    doctorTimeSlotAppointments: [],
    canceledAppointment:{},
    isDeletedAppointment:false,
    cancelTimeSlot:false,    

  }, action) {

    state = {...state,error:{},cancelTimeSlot:false, }

    switch (action.type) {

         //GET Doctor Time Slots for doctor id 
        case appointmentConstants.GET_ALL_APPOINTMENTS_PENDING: {
            return {...state,loading:{...state.loading ,getAll:true }}
        }  
        case  appointmentConstants.GET_ALL_APPOINTMENTS_FULFILLED: {
            return {...state,loading:{...state.loading ,getAll:false },allAppointments:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_ALL_APPOINTMENTS_FAILURE:  {
            return {...state, loading:{...state.loading ,getAll:false },error:action.error}
        }
        case appointmentConstants.GET_ALL_APPOINTMENTS_REJECTED:  {
            return {...state, loading:{...state.loading ,getAll:false },error:action.error}
        }


    
        case appointmentConstants.GET_PATIENTS_FOR_ADD_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,patientSearch:true }}
        }  
        case  appointmentConstants.GET_PATIENTS_FOR_ADD_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,patientSearch:false } , patients:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_PATIENTS_FOR_ADD_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,patientSearch:false } ,error:action.error}
        }
        case appointmentConstants.GET_PATIENTS_FOR_ADD_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,patientSearch:false } ,error:action.error}
        }
    
        case appointmentConstants.GET_GUARDIAN_FOR_ADD_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,guardianSearch:true }}
        }  
        case  appointmentConstants.GET_GUARDIAN_FOR_ADD_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,guardianSearch:false } , guardians:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_GUARDIAN_FOR_ADD_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,guardianSearch:false } ,error:action.error}
        }
        case appointmentConstants.GET_GUARDIAN_FOR_ADD_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,guardianSearch:false } ,error:action.error}
        }
    
        case appointmentConstants.UPDATE_APPOINTMENT_STATUS_PENDING: {
            return {...state,loading:{...state.loading ,appointmentUpdateStatus:true } , saved:{...state.saved , appointmentUpdateStatus:false}}
        }  
        case  appointmentConstants.UPDATE_APPOINTMENT_STATUS_FULFILLED: {
            return {...state,loading:{...state.loading ,appointmentUpdateStatus:false } , saved:{...state.saved , appointmentUpdateStatus:true}, appointmentUpdateStatus:action?.payload?.data?.data}
        }    
        case appointmentConstants.UPDATE_APPOINTMENT_STATUS_FAILURE:  {
            return {...state,loading:{...state.loading ,appointmentUpdateStatus:false } , saved:{...state.saved , appointmentUpdateStatus:false} ,error:action.error}
        }
        case appointmentConstants.UPDATE_APPOINTMENT_STATUS_REJECTED:  {
            console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeee",action);
            return {...state,loading:{...state.loading ,appointmentUpdateStatus:false } , saved:{...state.saved , appointmentUpdateStatus:false} ,error:action.payload?.error}
        }
    
        case appointmentConstants.GET_REFERRED_DOCTOR_ADD_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,referredDoctorSearch:true }}
        }  
        case  appointmentConstants.GET_REFERRED_DOCTOR_ADD_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,referredDoctorSearch:false } , referredDoctors:action?.payload?.data?.data?.data}
        }    
        case appointmentConstants.GET_REFERRED_DOCTOR_ADD_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,referredDoctorSearch:false } ,error:action.error}
        }
        case appointmentConstants.GET_REFERRED_DOCTOR_ADD_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,referredDoctorSearch:false } ,error:action.error}
        }
    
        case appointmentConstants.GET_REFERRED_CENTER_ADD_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,referredCenterSearch:true }}
        }  
        case  appointmentConstants.GET_REFERRED_CENTER_ADD_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,referredCenterSearch:false } , referredCenters:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_REFERRED_CENTER_ADD_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,referredCenterSearch:false } ,error:action.error}
        }
        case appointmentConstants.GET_REFERRED_CENTER_ADD_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,referredCenterSearch:false } ,error:action.error}
        }


        case appointmentConstants.GET_ALL_SERVICES_ADD_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,servicesGetAll:true }}
        }  
        case  appointmentConstants.GET_ALL_SERVICES_ADD_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,servicesGetAll:false } , services:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_ALL_SERVICES_ADD_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,servicesGetAll:false } ,error:action.error}
        }
        case appointmentConstants.GET_ALL_SERVICES_ADD_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,servicesGetAll:false } ,error:action.error}
        }

        case appointmentConstants.GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,servicesGetAllTimeSlot:true }}
        }  
        case  appointmentConstants.GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,servicesGetAllTimeSlot:false } , serviceTimeSlots:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,servicesGetAllTimeSlot:false } ,error:action.error}
        }
        case appointmentConstants.GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,servicesGetAllTimeSlot:false } ,error:action.error}
        }



        case appointmentConstants.GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,servicesGetAllTimeSlotAppointment:true }}
        }  
        case  appointmentConstants.GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,servicesGetAllTimeSlotAppointment:false } , serviceTimeSlotsAppointment:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,servicesGetAllTimeSlotAppointment:false } ,error:action.error}
        }
        case appointmentConstants.GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,servicesGetAllTimeSlotAppointment:false } ,error:action.error}
        }


        case appointmentConstants.GET_SERVICES_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE_PENDING: {
            return {...state,loading:{...state.loading ,servicesGetAllTimeSlotAppointmentPatient:true }}
        }  
        case  appointmentConstants.GET_SERVICES_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE_FULFILLED: {
            return {...state,loading:{...state.loading ,servicesGetAllTimeSlotAppointmentPatient:false } , serviceTimeSlotsAppointmentPatient:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_SERVICES_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE_FAILURE:  {
            return {...state, loading:{...state.loading ,servicesGetAllTimeSlotAppointmentPatient:false } ,error:action.error}
        }
        case appointmentConstants.GET_SERVICES_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE_REJECTED:  {
            return {...state, loading:{...state.loading ,servicesGetAllTimeSlotAppointmentPatient:false } ,error:action.error}
        }

        case appointmentConstants.GET_ALL_IPD_PATIENT_ADD_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,getAllIpdAppointment:true }}
        }  
        case  appointmentConstants.GET_ALL_IPD_PATIENT_ADD_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,getAllIpdAppointment:false } , ipdPatients:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_ALL_IPD_PATIENT_ADD_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,getAllIpdAppointment:false } ,error:action.error}
        }
        case appointmentConstants.GET_ALL_IPD_PATIENT_ADD_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,getAllIpdAppointment:false } ,error:action.error}
        }

         // Patient cancel Appointment
         case appointmentConstants.CANCEL_PATIENT_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading , cancelAppointment:true},cancelTimeSlot: false,isDeletedAppointment:false,  user: action.payload}
        }  
        case  appointmentConstants.CANCEL_PATIENT_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading , cancelAppointment:false},cancelTimeSlot: true,canceledAppointment:action?.payload?.data?.data}
        }    
        case appointmentConstants.CANCEL_PATIENT_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading , cancelAppointment:false},cancelTimeSlot: false,isDeletedAppointment:false,error:action.error}
        }
        case appointmentConstants.CANCEL_PATIENT_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading , cancelAppointment:false},cancelTimeSlot: false,isDeletedAppointment:false,error:action.payload}
        }


        case appointmentConstants.SAVE_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,appointmentSave:true } , saved:{...state.saved , appointmentSave:false}}
        }  
        case  appointmentConstants.SAVE_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,appointmentSave:false } , saved:{...state.saved , appointmentSave:true} , serviceAppointment:action?.payload?.data?.data}
        }    
        case appointmentConstants.SAVE_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,appointmentSave:false } , saved:{...state.saved , appointmentSave:false} ,error:action.error}
        }
        case appointmentConstants.SAVE_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,appointmentSave:false } , saved:{...state.saved , appointmentSave:false} ,error:action.payload}
        }

        case appointmentConstants.UPDATE_SERVICE_APPOINTMENT_SERVICE_PENDING: {
            return {...state,loading:{...state.loading ,appointmentUpdate:true } , saved:{...state.saved , appointmentUpdate:false}}
        }  
        case  appointmentConstants.UPDATE_SERVICE_APPOINTMENT_SERVICE_FULFILLED: {
            return {...state,loading:{...state.loading ,appointmentUpdate:false } , saved:{...state.saved , appointmentUpdate:true} , serviceAppointment:action?.payload?.data?.data}
        }    
        case appointmentConstants.UPDATE_SERVICE_APPOINTMENT_SERVICE_FAILURE:  {
            return {...state, loading:{...state.loading ,appointmentUpdate:false } , saved:{...state.saved , appointmentUpdate:false} ,error:action.error}
        }
        case appointmentConstants.UPDATE_SERVICE_APPOINTMENT_SERVICE_REJECTED:  {
            return {...state, loading:{...state.loading ,appointmentUpdate:false } , saved:{...state.saved , appointmentUpdate:false} ,error:action.payload}
        }

        case appointmentConstants.GET_MAIN_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,appointmentGetMain:true } , saved:{...state.saved , appointmentGetMain:false}}
        }  
        case  appointmentConstants.GET_MAIN_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,appointmentGetMain:false } , saved:{...state.saved , appointmentGetMain:true} , serviceMainAppointment:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_MAIN_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,appointmentGetMain:false } , saved:{...state.saved , appointmentGetMain:false} ,error:action.error}
        }
        case appointmentConstants.GET_MAIN_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,appointmentGetMain:false } , saved:{...state.saved , appointmentGetMain:false} ,error:action.payload}
        }


        case appointmentConstants.GET_SERVICE_APPOINTMENT_SERVICE_PENDING: {
            return {...state,loading:{...state.loading ,appointmentGetService:true } , saved:{...state.saved , appointmentGetService:false}}
        }  
        case  appointmentConstants.GET_SERVICE_APPOINTMENT_SERVICE_FULFILLED: {
            return {...state,loading:{...state.loading ,appointmentGetService:false } , saved:{...state.saved , appointmentGetService:true} , serviceAppointmentService:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_SERVICE_APPOINTMENT_SERVICE_FAILURE:  {
            return {...state, loading:{...state.loading ,appointmentGetService:false } , saved:{...state.saved , appointmentGetService:false} ,error:action.error}
        }
        case appointmentConstants.GET_SERVICE_APPOINTMENT_SERVICE_REJECTED:  {
            return {...state, loading:{...state.loading ,appointmentGetService:false } , saved:{...state.saved , appointmentGetService:false} ,error:action.payload}
        }

        case appointmentConstants.GET_ALL_SERVICE_TECHNICIAN_PENDING : {
            return {...state,loading:{...state.loading , getAllServicesTechnician:true },}
        }  
        case  appointmentConstants.GET_ALL_SERVICE_TECHNICIAN_FULFILLED: {
            return {...state,loading:{...state.loading , getAllServicesTechnician:false } , serviceTechnician:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_ALL_SERVICE_TECHNICIAN_FAILURE :  {
            return {...state, loading:{...state.loading , getAllServicesTechnician:false } ,error:action.error}
        }
        case appointmentConstants.GET_ALL_SERVICE_TECHNICIAN_REJECTED :  {
            return {...state, loading:{...state.loading , getAllServicesTechnician:false } ,error:action.error}
        }
        
        
        case appointmentConstants.GET_ALL_SERVICE_ITEMS_PENDING : {
            return {...state,loading:{...state.loading , getAllServicesItems:true },}
        }  
        case  appointmentConstants.GET_ALL_SERVICE_ITEMS_FULFILLED: {
            return {...state,loading:{...state.loading , getAllServicesItems:false } , serviceItems:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_ALL_SERVICE_ITEMS_FAILURE :  {
            return {...state, loading:{...state.loading , getAllServicesItems:false } ,error:action.error}
        }
        case appointmentConstants.GET_ALL_SERVICE_ITEMS_REJECTED :  {
            return {...state, loading:{...state.loading , getAllServicesItems:false } ,error:action.error}
        }
        case appointmentConstants.GET_ALL_SERVICE_MACHINES_PENDING : {
            return {...state,loading:{...state.loading , getAllServicesMachines:true },}
        }  
        case  appointmentConstants.GET_ALL_SERVICE_MACHINES_FULFILLED: {
            return {...state,loading:{...state.loading , getAllServicesMachines:false } , serviceMachines:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_ALL_SERVICE_MACHINES_FAILURE :  {
            return {...state, loading:{...state.loading , getAllServicesMachines:false } ,error:action.error}
        }
        case appointmentConstants.GET_ALL_SERVICE_MACHINES_REJECTED :  {
            return {...state, loading:{...state.loading , getAllServicesMachines:false } ,error:action.error}
        }


        case appointmentConstants.SAVE_SERVICE_HR_USAGE_PENDING : {
            return {...state,loading:{...state.loading , saveSaveServiceHr:true },saved:{...state.saved , saveSaveServiceHr:false } ,}
        }  
        case  appointmentConstants.SAVE_SERVICE_HR_USAGE_FULFILLED: {
            return {...state,loading:{...state.loading , saveSaveServiceHr:false } ,saved:{...state.saved , saveSaveServiceHr:true } , serviceHrUsage:action?.payload?.data?.data}
        }    
        case appointmentConstants.SAVE_SERVICE_HR_USAGE_FAILURE :  {
            return {...state, loading:{...state.loading , saveSaveServiceHr:false } ,saved:{...state.saved , saveSaveServiceHr:false } ,error:action.error}
        }
        case appointmentConstants.SAVE_SERVICE_HR_USAGE_REJECTED :  {
            return {...state, loading:{...state.loading , saveSaveServiceHr:false } ,saved:{...state.saved , saveSaveServiceHr:false } ,error:action.payload}
        }

        case appointmentConstants.SAVE_SERVICE_MACHINES_PENDING : {
            return {...state,loading:{...state.loading , saveSaveServiceMachine:true },saved:{...state.saved , saveSaveServiceMachine:false } ,}
        }  
        case  appointmentConstants.SAVE_SERVICE_MACHINES_FULFILLED: {
            return {...state,loading:{...state.loading , saveSaveServiceMachine:false } ,saved:{...state.saved , saveSaveServiceMachine:true } , serviceMachineUsage:action?.payload?.data?.data}
        }    
        case appointmentConstants.SAVE_SERVICE_MACHINES_FAILURE :  {
            return {...state, loading:{...state.loading , saveSaveServiceMachine:false } ,saved:{...state.saved , saveSaveServiceMachine:false } ,error:action.error}
        }
        case appointmentConstants.SAVE_SERVICE_MACHINES_REJECTED :  {
            return {...state, loading:{...state.loading , saveSaveServiceMachine:false } ,saved:{...state.saved , saveSaveServiceMachine:false } ,error:action.payload}
        }
        
        case appointmentConstants.UPDATE_SERVICE_MACHINES_PENDING : {
            return {...state,loading:{...state.loading , updateSaveServiceMachine:true },saved:{...state.saved , updateSaveServiceMachine:false } ,}
        }  
        case  appointmentConstants.UPDATE_SERVICE_MACHINES_FULFILLED: {
            return {...state,loading:{...state.loading , updateSaveServiceMachine:false } ,saved:{...state.saved , updateSaveServiceMachine:true } , serviceMachineUsage:action?.payload?.data?.data}
        }    
        case appointmentConstants.UPDATE_SERVICE_MACHINES_FAILURE :  {
            return {...state, loading:{...state.loading , updateSaveServiceMachine:false } ,saved:{...state.saved , updateSaveServiceMachine:false } ,error:action.error}
        }
        case appointmentConstants.UPDATE_SERVICE_MACHINES_REJECTED :  {
            return {...state, loading:{...state.loading , updateSaveServiceMachine:false } ,saved:{...state.saved , updateSaveServiceMachine:false } ,error:action.payload}
        }

        case appointmentConstants.SAVE_SERVICE_ITEM_USAGE_PENDING : {
            return {...state,loading:{...state.loading , saveServiceItem:true },saved:{...state.saved , saveServiceItem:false } ,}
        }  
        case  appointmentConstants.SAVE_SERVICE_ITEM_USAGE_FULFILLED: {
            return {...state,loading:{...state.loading , saveServiceItem:false } ,saved:{...state.saved , saveServiceItem:true } , serviceItemUsage:action?.payload?.data?.data}
        }    
        case appointmentConstants.SAVE_SERVICE_ITEM_USAGE_FAILURE :  {
            return {...state, loading:{...state.loading , saveServiceItem:false } ,saved:{...state.saved , saveServiceItem:false } ,error:action.error}
        }
        case appointmentConstants.SAVE_SERVICE_ITEM_USAGE_REJECTED :  {
            return {...state, loading:{...state.loading , saveServiceItem:false } ,saved:{...state.saved , saveServiceItem:false } ,error:action.payload}
        }

        case appointmentConstants.UPDATE_SERVICE_ITEM_USAGE_PENDING : {
            return {...state,loading:{...state.loading , updateServiceItem:true },saved:{...state.saved , updateServiceItem:false } ,}
        }  
        case  appointmentConstants.UPDATE_SERVICE_ITEM_USAGE_FULFILLED: {
            return {...state,loading:{...state.loading , updateServiceItem:false } ,saved:{...state.saved , updateServiceItem:true } , serviceItemUsage:action?.payload?.data?.data}
        }    
        case appointmentConstants.UPDATE_SERVICE_ITEM_USAGE_FAILURE :  {
            return {...state, loading:{...state.loading , updateServiceItem:false } ,saved:{...state.saved , updateServiceItem:false } ,error:action.error}
        }
        case appointmentConstants.UPDATE_SERVICE_ITEM_USAGE_REJECTED :  {
            return {...state, loading:{...state.loading , updateServiceItem:false } ,saved:{...state.saved , updateServiceItem:false } ,error:action.payload}
        }

        case appointmentConstants.DELETE_SERVICE_ITEM_USAGE_PENDING : {
            return {...state,loading:{...state.loading , deleteServiceItem:true },saved:{...state.saved , deleteServiceItem:false } ,}
        }  
        case  appointmentConstants.DELETE_SERVICE_ITEM_USAGE_FULFILLED: {
            return {...state,loading:{...state.loading , deleteServiceItem:false } ,saved:{...state.saved , deleteServiceItem:true }}
        }    
        case appointmentConstants.DELETE_SERVICE_ITEM_USAGE_FAILURE :  {
            return {...state, loading:{...state.loading , deleteServiceItem:false } ,saved:{...state.saved , deleteServiceItem:false } ,error:action.error}
        }
        case appointmentConstants.DELETE_SERVICE_ITEM_USAGE_REJECTED :  {
            return {...state, loading:{...state.loading , deleteServiceItem:false } ,saved:{...state.saved , deleteServiceItem:false } ,error:action.payload}
        }

        case appointmentConstants.SAVE_SERVICE_REPORT_PENDING : {
            return {...state,loading:{...state.loading , saveServiceReport:true },saved:{...state.saved , saveServiceReport:false } ,}
        }  
        case  appointmentConstants.SAVE_SERVICE_REPORT_FULFILLED: {
            return {...state,loading:{...state.loading , saveServiceReport:false } ,saved:{...state.saved , saveServiceReport:true } , serviceReport:action?.payload?.data?.data}
        }    
        case appointmentConstants.SAVE_SERVICE_REPORT_FAILURE :  {
            return {...state, loading:{...state.loading , saveServiceReport:false } ,saved:{...state.saved , saveServiceReport:false } ,error:action.error}
        }
        case appointmentConstants.SAVE_SERVICE_REPORT_REJECTED :  {
            return {...state, loading:{...state.loading , saveServiceReport:false } ,saved:{...state.saved , saveServiceReport:false } ,error:action.payload}
        }



        case appointmentConstants.SAVE_FALSE :  {
            return {...state,saved:{...state.saved , 
                     getAllServicesTechnician:false ,appointmentSave:false, 
                     appointmentGetMain:false, appointmentGetService:false,
                     saveServiceItem:false,saveSaveServiceMachine:false,
                     updateSaveServiceMachine:false,updateServiceItem:false,deleteServiceItem:false,appointmentGetOneLabTestSampleStart:false,appointmentGetOneLabTestSample:false,
                     saveServiceReport:false,
                     appointmentUpdate:false,
                      saveSaveServiceHr:false, } 
                      ,error:{}}
        }

        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_START_PENDING: {
            return {...state,loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceStart:false } ,user: action.payload,saved:false}
        }  
        case  myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_START_FULFILLED: {
            return {...state,loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceStart:true }, myServiceStart:action?.payload?.data?.data}
        }    
        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_START_FAILURE:  {
            return {...state, loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceStart:false },error:action.error}
        }
        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_START_REJECTED:  {
            return {...state, loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceStart:false  },error:action.payload}
        }

        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_END_PENDING: {
            return {...state,loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceEnd:false } ,user: action.payload,saved:false}
        }  
        case  myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_END_FULFILLED: {
            return {...state,loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceEnd:true }, myServiceEnd:action?.payload?.data?.data}
        }    
        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_END_FAILURE:  {
            return {...state, loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceEnd:false },error:action.error}
        }
        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_END_REJECTED:  {
            return {...state, loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceEnd:false  },error:action.payload}
        }

        case appointmentConstants.SAVE_SERVICE_APPOINTMENT_FINISH_PENDING : {
            return {...state,loading:{...state.loading , saveServiceFinish:true },saved:{...state.saved , saveServiceFinish:false } ,}
        }  
        case  appointmentConstants.SAVE_SERVICE_APPOINTMENT_FINISH_FULFILLED: {
            return {...state,loading:{...state.loading , saveServiceFinish:false } ,saved:{...state.saved , saveServiceFinish:true } , serviceFinish:action?.payload?.data?.data}
        }    
        case appointmentConstants.SAVE_SERVICE_APPOINTMENT_FINISH_FAILURE :  {
            return {...state, loading:{...state.loading , saveServiceFinish:false } ,saved:{...state.saved , saveServiceFinish:false } ,error:action.error}
        }
        case appointmentConstants.SAVE_SERVICE_APPOINTMENT_FINISH_REJECTED :  {
            return {...state, loading:{...state.loading , saveServiceFinish:false } ,saved:{...state.saved , saveServiceFinish:false } ,error:action.payload}
        }
        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_PENDING: {
            return {...state,loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceDelivered:false } ,user: action.payload,saved:false}
        }  
        case  myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_FULFILLED: {
            return {...state,loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceDelivered:true }, myserviceDelivered:action?.payload?.data?.data}
        }    
        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_FAILURE:  {
            return {...state, loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceDelivered:false },error:action.error}
        }
        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_REJECTED:  {
            return {...state, loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceDelivered:false  },error:action.payload}
        }
       

        //Update Appointment
        case myServicePendingActionsConstants.UPDATE_APPOINTMENT_STATUS: {
            return {...state,loading:true,saved:false,fetched: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  myServicePendingActionsConstants.UPDATE_APPOINTMENT_STATUS_PENDING: {
            return {...state,loading: false,saved:true,fetched: false, appointmentStatus:action?.payload?.data?.data}
        }    
        case myServicePendingActionsConstants.UPDATE_APPOINTMENT_STATUS_FULFILLED:  {
            return {...state, loading: false,saved:false,fetched: false,isDeletedAppointment:false,error:action.error}
        }
        case myServicePendingActionsConstants.UPDATE_APPOINTMENT_STATUS:  {
            return {...state, loading: false,saved:false,fetched: false,isDeletedAppointment:false,error:action?.payload}
        }

        //LEAVE TIME SLOT
        case appointmentConstants.LEAVE_SERVICE_TIME_SLOT_PENDING : {
            return {...state,loading:{...state.loading , timeSlotLeave:true },saved:{...state.saved , leaveTimeSlot:false }}
        }  
        case  appointmentConstants.LEAVE_SERVICE_TIME_SLOT_FULFILLED: {
            return {...state,loading:{...state.loading , timeSlotLeave:false },saved:{...state.saved , leaveTimeSlot:true }}
        }    
        case appointmentConstants.LEAVE_SERVICE_TIME_SLOT_FAILURE :  {
            return {...state, loading:{...state.loading , timeSlotLeave:false },saved:{...state.saved , leaveTimeSlot:false }}
        }
        case appointmentConstants.LEAVE_SERVICE_TIME_SLOT_REJECTED :  {
            return {...state, loading:{...state.loading , timeSlotLeave:false },saved:{...state.saved , leaveTimeSlot:false },error:action.payload}
        }


        //SHORT LEAVE TIME SLOT
        case appointmentConstants.SHORT_LEAVE_SERVICE_TIME_SLOT_PENDING : {
            return {...state,loading:{...state.loading },saved:{...state.saved , shortLeaveTimeSlot:false }}
        }  
        case  appointmentConstants.SHORT_LEAVE_SERVICE_TIME_SLOT_FULFILLED: {
            return {...state,loading:{...state.loading},saved:{...state.saved , shortLeaveTimeSlot:true }}
        }    
        case appointmentConstants.SHORT_LEAVE_SERVICE_TIME_SLOT_FAILURE :  {
            return {...state, loading:{...state.loading},saved:{...state.saved , shortLeaveTimeSlot:false }}
        }
        case appointmentConstants.SHORT_LEAVE_SERVICE_TIME_SLOT_REJECTED :  {
            return {...state, loading:{...state.loading},saved:{...state.saved , shortLeaveTimeSlot:false }}
        }


        case appointmentConstants.TRANSFER_SERVICE_APPOINTMENTS_PENDING : {
            return {...state,loading:{...state.loading },saved:{...state.saved , transferAppointmentsStatus:false }}
        }  
        case  appointmentConstants.TRANSFER_SERVICE_APPOINTMENTS_FULFILLED: {
            return {...state,loading:{...state.loading},saved:{...state.saved , transferAppointmentsStatus:true }}
        }    
        case appointmentConstants.TRANSFER_SERVICE_APPOINTMENTS_FAILURE :  {
            return {...state, loading:{...state.loading},saved:{...state.saved , transferAppointmentsStatus:false }}
        }
        case appointmentConstants.TRANSFER_SERVICE_APPOINTMENTS_REJECTED :  {
            return {...state, loading:{...state.loading},saved:{...state.saved , transferAppointmentsStatus:false }}
        }

        
        case appointmentConstants.SERVICE_APPOINTMENT_STATUS_CHANGE :  {
            return {...state,cancelTimeSlot:false, loading:{...state.loading , saveServiceFinish:false,getAll:false,
                patientSearch:false,
                guardianSearch:false,
                referredDoctorSearch:false,
                referredCenterSearch:false,
                servicesGetAll:false,
                servicesGetAllTimeSlot:false,
                servicesGetAllTimeSlotAppointment:false,
                appointmentSave:false,
                appointmentUpdate:false,
                appointmentGetMain:false,
                appointmentGetService:false,
                getAllServicesTechnician:false,
                getAllServicesItems:false,
                getAllServicesMachines:false,
                saveSaveServiceHr:false,
                saveSaveServiceMachine:false,
                updateSaveServiceMachine:false,
                updateServiceItem:false,
                deleteServiceItem:false,
                servicesGetAllTimeSlotAppointmentPatient:false,
                saveServiceItem:false,
                saveServiceReport:false,
                saveAppointmentService:false, } 
            ,saved:{...state.saved , saveServiceFinish:false,appointmentSave:false,
                appointmentGetMain:false,
                appointmentGetService:false,
                getAllServicesTechnician:false,
                getAllServicesItems:false,
                appointmentUpdate:false,
                getAllServicesMachines:false,
                saveSaveServiceHr:false,
                saveSaveServiceMachine:false,
                updateSaveServiceMachine:false,
                updateServiceItem:false,
                deleteServiceItem:false,
                saveServiceItem:false,
                saveServiceReport:false,
                serviceStart:false,
                serviceDelivered:false,
                serviceEnd:false, } 
            
            }
        }

        case appointmentConstants.CLEAR:  {
            console.log("ssssssssssssssssssssssssssssssssssssssssssssssssssss");
            return {...state, loading: false,saved:{},fetched: false,isDeletedAppointment:false}
        }

        
        default :
            return state;
    }

}
