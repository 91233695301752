export const pendingServiceApprovalConstants = {


    GET_ALL_MY_SERVICE_PENDING_APPROVAL: 'GET_ALL_MY_SERVICE_PENDING_APPROVAL',
    GET_ALL_MY_SERVICE_PENDING_APPROVAL_PENDING: 'GET_ALL_MY_SERVICE_PENDING_APPROVAL_PENDING',
    GET_ALL_MY_SERVICE_PENDING_APPROVAL_FULFILLED: 'GET_ALL_MY_SERVICE_PENDING_APPROVAL_FULFILLED',
    GET_ALL_MY_SERVICE_PENDING_APPROVAL_FAILURE: 'GET_ALL_MY_SERVICE_PENDING_APPROVAL_FAILURE',
    GET_ALL_MY_SERVICE_PENDING_APPROVAL_REJECTED: 'GET_ALL_MY_SERVICE_PENDING_APPROVAL_REJECTED',


    GET_ALL_OTHERS_SERVICE_PENDING_APPROVAL: 'GET_ALL_OTHERS_SERVICE_PENDING_APPROVAL',
    GET_ALL_OTHERS_SERVICE_PENDING_APPROVAL_PENDING: 'GET_ALL_OTHERS_SERVICE_PENDING_APPROVAL_PENDING',
    GET_ALL_OTHERS_SERVICE_PENDING_APPROVAL_FULFILLED: 'GET_ALL_OTHERS_SERVICE_PENDING_APPROVAL_FULFILLED',
    GET_ALL_OTHERS_SERVICE_PENDING_APPROVAL_FAILURE: 'GET_ALL_OTHERS_SERVICE_PENDING_APPROVAL_FAILURE',
    GET_ALL_OTHERS_SERVICE_PENDING_APPROVAL_REJECTED: 'GET_ALL_OTHERS_SERVICE_PENDING_APPROVAL_REJECTED',


};
