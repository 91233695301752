
import {getSamplesConstants} from './constants.js';
export default function getSamplesReducer(state={
    myServicesApproval: {},
    fetched: false,
    loadingSearch:false,
    loading:false,
    saved:false,
  }, action) {

    state = {...state,saved:{...state.saved ,serviceStart:false},loading:{...state.loading ,saveSampleReceived:false }}
    switch (action.type) {



        case getSamplesConstants.GET_ALL_SAMPLE_DATA_PENDING: {
            return {...state,loading:{...state.loading ,getSampleReceived:true }, sampleReceived: action.payload,saved:false}
        }  
        case  getSamplesConstants.GET_ALL_SAMPLE_DATA_FULFILLED: {
            return {...state,loading:{...state.loading ,getSampleReceived:false }, sampleReceived:action?.payload?.data?.data}
        }    
        case getSamplesConstants.GET_ALL_SAMPLE_DATA_FAILURE:  {
            return {...state, loading:{...state.loading ,getSampleReceived:false },error:action.error}
        }
        case getSamplesConstants.GET_ALL_SAMPLE_DATA_REJECTED:  {
            return {...state, loading:{...state.loading ,getSampleReceived:false },error:action.payload}
        }

        case getSamplesConstants.SAVE_SAMPLE_RECEIVED_PENDING: {
            return {...state,loading:{...state.loading ,saveSampleReceived:true },saved:{...state.saved ,saveSampleReceived:false}, saveSampleReceived: action.payload,saved:false}
        }  
        case  getSamplesConstants.SAVE_SAMPLE_RECEIVED_FULFILLED: {
            return {...state,loading:{...state.loading ,saveSampleReceived:false },saved:{...state.saved ,saveSampleReceived:true}, saveSampleReceived:action?.payload?.data?.data}
        }    
        case getSamplesConstants.SAVE_SAMPLE_RECEIVED_FAILURE:  {
            return {...state, loading:{...state.loading ,saveSampleReceived:false },saved:{...state.saved ,saveSampleReceived:false},error:action.error}
        }
        case getSamplesConstants.SAVE_SAMPLE_RECEIVED_REJECTED:  {
            return {...state, loading:{...state.loading ,saveSampleReceived:false },saved:{...state.saved ,saveSampleReceived:false},error:action.payload}
        }

       

        case getSamplesConstants.SAVE_LAB_FALSE :  {
            return {...state,save:{...state.save , 
                     saveSampleReceived:false,
                     getSampleReceived:false,
                     } ,loading:{...state.loading ,saveSampleReceived:false,getSampleReceived:false}
                      ,error:{}}
        }
       
        default :
            return state;
    }

}
