
import {serviceAppointmentActionsConstants} from './constants.js';
export default function serviceAppointmentReducer(state={
    myServices: [],
    fetched: false,
    loadingSearch:false,
    loading:false,
    saved:false,
  }, action) {

    state = {...state,saved:{...state.saved ,serviceStart:false},loading:{...state.loading ,getService:false }}
    switch (action.type) {



        // Add Patients
        case serviceAppointmentActionsConstants.GET_ALL_SERVICES_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,getService:false }, user: action.payload,saved:false}
        }  
        case  serviceAppointmentActionsConstants.GET_ALL_SERVICES_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,getService:false }, servicesAppointments:action?.payload?.data?.data}
        }    
        case serviceAppointmentActionsConstants.GET_ALL_SERVICES_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,getService:false },error:action.error}
        }
        case serviceAppointmentActionsConstants.GET_ALL_SERVICES_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,getService:false },error:action.payload}
        }

        case serviceAppointmentActionsConstants.TRANSFER_SERVICE_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,getService:false },saved:{...state.saved, transferService : false}}
        }  
        case  serviceAppointmentActionsConstants.TRANSFER_SERVICE_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,getService:false },saved:{...state.saved, transferService : true}}
        }    
        case serviceAppointmentActionsConstants.TRANSFER_SERVICE_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,getService:false },saved:{...state.saved, transferService : false},error:action.error}
        }
        case serviceAppointmentActionsConstants.TRANSFER_SERVICE_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,getService:false },saved:{...state.saved, transferService : false},error:action.payload}
        }
        
        
       
        default :
            return state;
    }

}
