export const usersSelectActionsConstants = {


    GET_SEARCH_DOCTOR: 'GET_SEARCH_DOCTOR',
    GET_SEARCH_DOCTOR_PENDING: 'GET_SEARCH_DOCTOR_PENDING',
    GET_SEARCH_DOCTOR_FULFILLED: 'GET_SEARCH_DOCTOR_FULFILLED',
    GET_SEARCH_DOCTOR_FAILURE: 'GET_SEARCH_DOCTOR_FAILURE',
    GET_SEARCH_DOCTOR_REJECTED: 'GET_SEARCH_DOCTOR_REJECTED',


};
