
import {productsSelectActionsConstants} from './constants.js';
export default function productSelectReducer(state={
    myServices: [],
    fetched: false,
    loadingSearch:false,
    loading:false,
    saved:false,
  }, action) {

    state = {...state,saved:false}
    switch (action.type) {



        // Add Patients
        case productsSelectActionsConstants.GET_SEARCH_PRODUCTS_PENDING: {
            return {...state,loading:{...state.loading , productLoading:true}, user: action.payload,saved:false}
        }  
        case  productsSelectActionsConstants.GET_SEARCH_PRODUCTS_FULFILLED: {
            return {...state,loading:{...state.loading , productLoading:false}, products:action?.payload?.data?.data}
        }    
        case productsSelectActionsConstants.GET_SEARCH_PRODUCTS_FAILURE:  {
            return {...state, loading:{...state.loading , productLoading:false},error:action.error}
        }
        case productsSelectActionsConstants.GET_SEARCH_PRODUCTS_REJECTED:  {
            return {...state, loading:{...state.loading , productLoading:false},error:action.payload}
        }
        
        
        case productsSelectActionsConstants.GET_SEARCH_PRODUCT_BATCHES_PENDING: {
            return {...state,loading:{...state.loading , productBatchLoading:true}, user: action.payload,saved:false}
        }  
        case  productsSelectActionsConstants.GET_SEARCH_PRODUCT_BATCHES_FULFILLED: {
            return {...state,loading:{...state.loading , productBatchLoading:false}, productBatches:action?.payload?.data?.data}
        }    
        case productsSelectActionsConstants.GET_SEARCH_PRODUCT_BATCHES_FAILURE:  {
            return {...state, loading:{...state.loading , productBatchLoading:false},error:action.error}
        }
        case productsSelectActionsConstants.GET_SEARCH_PRODUCT_BATCHES_REJECTED:  {
            return {...state, loading:{...state.loading , productBatchLoading:false},error:action.payload}
        }



        default :
            return state;
    }

}
