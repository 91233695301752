export const serviceApprovedConstants = {

    GET_ALL_MY_SERVICE_APPROVAL_APPROVED: 'GET_ALL_MY_SERVICE_APPROVAL_APPROVED',
    GET_ALL_MY_SERVICE_APPROVAL_APPROVED_PENDING: 'GET_ALL_MY_SERVICE_APPROVAL_APPROVED_PENDING',
    GET_ALL_MY_SERVICE_APPROVAL_APPROVED_FULFILLED: 'GET_ALL_MY_SERVICE_APPROVAL_APPROVED_FULFILLED',
    GET_ALL_MY_SERVICE_APPROVAL_APPROVED_FAILURE: 'GET_ALL_MY_SERVICE_APPROVAL_APPROVED_FAILURE',
    GET_ALL_MY_SERVICE_APPROVAL_APPROVED_REJECTED: 'GET_ALL_MY_SERVICE_APPROVAL_APPROVED_REJECTED',

    
    SAVE_SERVICE_APPROVAL_APPROVED: 'SAVE_SERVICE_APPROVAL_APPROVED',
    SAVE_SERVICE_APPROVAL_APPROVED_PENDING: 'SAVE_SERVICE_APPROVAL_APPROVED_PENDING',
    SAVE_SERVICE_APPROVAL_APPROVED_FULFILLED: 'SAVE_SERVICE_APPROVAL_APPROVED_FULFILLED',
    SAVE_SERVICE_APPROVAL_APPROVED_FAILURE: 'SAVE_SERVICE_APPROVAL_APPROVED_FAILURE',
    SAVE_SERVICE_APPROVAL_APPROVED_REJECTED: 'SAVE_SERVICE_APPROVAL_APPROVED_REJECTED',

    SAVE_FALSE : 'SAVE_FALSE',

};
