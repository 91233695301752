
import {usersSelectActionsConstants} from './constants.js';
export default function usersSelectReducer(state={
    myServices: [],
    fetched: false,
    loadingSearch:false,
    loading:false,
    saved:false,
  }, action) {

    state = {...state,saved:false}
    switch (action.type) {



        // Add Patients
        case usersSelectActionsConstants.GET_SEARCH_DOCTOR_PENDING: {
            return {...state,loading:{...state.loading , doctorLoading:true}, user: action.payload,saved:false}
        }  
        case  usersSelectActionsConstants.GET_SEARCH_DOCTOR_FULFILLED: {
            return {...state,loading:{...state.loading , doctorLoading:false}, doctors:action?.payload?.data?.data}
        }    
        case usersSelectActionsConstants.GET_SEARCH_DOCTOR_FAILURE:  {
            return {...state, loading:{...state.loading , doctorLoading:false},error:action.error}
        }
        case usersSelectActionsConstants.GET_SEARCH_DOCTOR_REJECTED:  {
            return {...state, loading:{...state.loading , doctorLoading:false},error:action.payload}
        }



        default :
            return state;
    }

}
