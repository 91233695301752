export const myLabsPendingActionsConstants = {


    GET_ALL_MY_LABS: 'GET_ALL_MY_LABS',
    GET_ALL_MY_LABS_PENDING: 'GET_ALL_MY_LABS_PENDING',
    GET_ALL_MY_LABS_FULFILLED: 'GET_ALL_MY_LABS_FULFILLED',
    GET_ALL_MY_LABS_FAILURE: 'GET_ALL_MY_LABS_FAILURE',
    GET_ALL_MY_LABS_REJECTED: 'GET_ALL_MY_LABS_REJECTED',

    GET_ALL_OTHERS_LABS: 'GET_ALL_OTHERS_LABS',
    GET_ALL_OTHERS_LABS_PENDING: 'GET_ALL_OTHERS_LABS_PENDING',
    GET_ALL_OTHERS_LABS_FULFILLED: 'GET_ALL_OTHERS_LABS_FULFILLED',
    GET_ALL_OTHERS_LABS_FAILURE: 'GET_ALL_OTHERS_LABS_FAILURE',
    GET_ALL_OTHERS_LABS_REJECTED: 'GET_ALL_OTHERS_LABS_REJECTED',

    SAVE_LAB_APPOINTMENT_START: 'SAVE_LAB_APPOINTMENT_START',
    SAVE_LAB_APPOINTMENT_START_PENDING: 'SAVE_LAB_APPOINTMENT_START_PENDING',
    SAVE_LAB_APPOINTMENT_START_FULFILLED: 'SAVE_LAB_APPOINTMENT_START_FULFILLED',
    SAVE_LAB_APPOINTMENT_START_FAILURE: 'SAVE_LAB_APPOINTMENT_START_FAILURE',
    SAVE_LAB_APPOINTMENT_START_REJECTED: 'SAVE_LAB_APPOINTMENT_START_REJECTED',

    SAVE_LAB_APPOINTMENT_END: 'SAVE_LAB_APPOINTMENT_END',
    SAVE_LAB_APPOINTMENT_END_PENDING: 'SAVE_LAB_APPOINTMENT_END_PENDING',
    SAVE_LAB_APPOINTMENT_END_FULFILLED: 'SAVE_LAB_APPOINTMENT_END_FULFILLED',
    SAVE_LAB_APPOINTMENT_END_FAILURE: 'SAVE_LAB_APPOINTMENT_END_FAILURE',
    SAVE_LAB_APPOINTMENT_END_REJECTED: 'SAVE_LAB_APPOINTMENT_END_REJECTED',

    SAVE_LAB_APPOINTMENT_DELIVERED_REPORT: 'SAVE_LAB_APPOINTMENT_DELIVERED_REPORT',
    SAVE_LAB_APPOINTMENT_DELIVERED_REPORT_PENDING: 'SAVE_LAB_APPOINTMENT_DELIVERED_REPORT_PENDING',
    SAVE_LAB_APPOINTMENT_DELIVERED_REPORT_FULFILLED: 'SAVE_LAB_APPOINTMENT_DELIVERED_REPORT_FULFILLED',
    SAVE_LAB_APPOINTMENT_DELIVERED_REPORT_FAILURE: 'SAVE_LAB_APPOINTMENT_DELIVERED_REPORT_FAILURE',
    SAVE_LAB_APPOINTMENT_DELIVERED_REPORT_REJECTED: 'SAVE_LAB_APPOINTMENT_DELIVERED_REPORT_REJECTED',
};
