
import {patientConstants} from './constants.js';
export default function patientReducer(state={
    patients: {},
    loadingSearch:true,loading:false,
    loading: {
        getAllPatients:false,
        getAllReferredDoctor:false,
        getAllReferredCenter:false,
        savedLoading:false,
    },
    saved:{},
  }, action) {

    state = {...state,saved:{...state.saved ,savedPatient:false , savedGuardian:false},selectedPatient:false,addSearchPatients:[],addOldSearchPatients:[],error:{}}

    switch (action.type) {

        // Get ALL Patients
        case patientConstants.GET_PATIENTS_PENDING :
        case patientConstants.GET_SECTION_PATIENTS_PENDING: {
            return {...state,loading:{...state.loading ,getAllPatients:true},saved:false}
        }  
        case patientConstants.GET_PATIENTS_FULFILLED :
        case patientConstants.GET_SECTION_PATIENTS_FULFILLED: {
            console.log("🚀 ~ file: reducer.js ~ line 27 ~ action.payload?.data?.data", action.payload?.data?.data)
            return {...state,loadingSearch:false,loading:{...state.loading ,getAllPatients:false}, patients: action.payload?.data?.data}
        }  
        case patientConstants.GET_PATIENTS_FAILURE :
        case patientConstants.GET_SECTION_PATIENTS_FAILURE: {
            return {...state,loadingSearch:false,loading:{...state.loading ,getAllPatients:false}, error: action.payload}
        }  
        case patientConstants.GET_PATIENTS_REJECTED: 
        case patientConstants.GET_SECTION_PATIENTS_REJECTED: {
            return {...state,loadingSearch:false,loading:{...state.loading ,getAllPatients:false}, error: action.payload}
        }  


        // Add Patients
        case patientConstants.GET_SEARCH_PENDING: {
            return {...state,loadingSearch:true,loading:{...state.loading ,getSearch:true}, user: action.payload,saved:false}
        }  
        case  patientConstants.GET_SEARCH_FULFILLED: {
            return {...state,loadingSearch:false,loading:{...state.loading ,getSearch:true},fetched: false, addSearchPatients:action?.payload.data.data}
        }    
        case patientConstants.GET_SEARCH_FAILURE:  {
            return {...state, loadingSearch:false,loading:{...state.loading ,getSearch:true},error:action.error}
        }

        case patientConstants.GET_OLD_PATIENTS_PENDING: {
            return {...state,loadingSearch:true,loading:{...state.loading ,getSearch:true}, user: action.payload,saved:false}
        }  
        case  patientConstants.GET_OLD_PATIENTS_FULFILLED: {
            return {...state,loadingSearch:false,loading:{...state.loading ,getSearch:true},fetched: false, addOldSearchPatients:action.payload.data}
        }    
        case patientConstants.GET_OLD_PATIENTS_FAILURE:  {
            return {...state, loadingSearch:false,loading:{...state.loading ,getSearch:true},error:action.error}
        }


        case patientConstants.GET_ONE_FOR_EDIT_PATIENTS_PENDING: {
            return {...state,loadingSearch:true,loading:false, user: action.payload}
        }  
        case  patientConstants.GET_ONE_FOR_EDIT_PATIENTS_FULFILLED: {
            return {...state,loadingSearch:false,loading:false,fetched: false, editPatient:action?.payload?.data?.data}
        }    
        case patientConstants.GET_ONE_FOR_EDIT_PATIENTS_FAILURE:  {
            return {...state, loadingSearch:false,loading:false,error:action.error}
        }
        case patientConstants.GET_ONE_FOR_EDIT_PATIENTS_REJECTED:  {
            return {...state, loadingSearch:false,loading:false,error:action.payload}
        }

        //Get Patient type 
        case patientConstants.GET_PATIENT_TYPE_PENDING: {
            return {...state,loadingSearch:true, user: action.payload}
        }  
        case  patientConstants.GET_PATIENT_TYPE_FULFILLED: {
            return {...state,loadingSearch: false,fetched: false, patientType:action?.payload?.data?.data?.data}
        }    
        case patientConstants.GET_PATIENT_TYPE_FAILURE:  {
            return {...state, loadingSearch: false,error:action.error}
        }

        //Get Doctors
        case patientConstants.GET_DOCTORS_PENDING: {
            return {...state,loading:{...state.loading ,getAllReferredDoctor:true}, user: action.payload}
        }  
        case  patientConstants.GET_DOCTORS_FULFILLED: {
            return {...state,loading:{...state.loading ,getAllReferredDoctor:false},fetched: false, referredDoctors:action?.payload?.data?.data}
        }    
        case patientConstants.GET_DOCTORS_FAILURE:  {
            return {...state,loading:{...state.loading ,getAllReferredDoctor:false}, error:action.error}
        }
        case patientConstants.GET_DOCTORS_REJECTED:  {
            return {...state,loading:{...state.loading ,getAllReferredDoctor:false},error:action.payload}
        }

        //Get Partners
        case patientConstants.GET_PARTNERS_PENDING: {
            return {...state,loading:{...state.loading ,getAllReferredCenter:true}, user: action.payload}
        }  
        case  patientConstants.GET_PARTNERS_FULFILLED: {
            return {...state,loading:{...state.loading ,getAllReferredCenter:false},fetched: false, referredCenters:action?.payload?.data?.data}
        }    
        case patientConstants.GET_PARTNERS_FAILURE:  {
            return {...state, loading:{...state.loading ,getAllReferredCenter:false},error:action.error}
        }
        case patientConstants.GET_PARTNERS_REJECTED:  {
            return {...state, loading:{...state.loading ,getAllReferredCenter:false},error:action.payload}
        }
        

        //Save Partners
        case patientConstants.SAVE_PATIENTS_PENDING: {
            return {...state,loading:{...state.loading ,savedLoading:true},saved:{...state.saved , savedPatient:false}, user: action.payload}
        }  
        case  patientConstants.SAVE_PATIENTS_FULFILLED: {
            return {...state,loading:{...state.loading ,savedLoading:false},saved:{...state.saved , savedPatient:true},fetched: false, patient:action?.payload?.data?.data}
        }    
        case patientConstants.SAVE_PATIENTS_FAILURE:  {
            return {...state, loading:{...state.loading ,savedLoading:false},saved:{...state.saved , savedPatient:false},error:action.error}
        }
        case patientConstants.SAVE_PATIENTS_REJECTED:  {
            return {...state, loading:{...state.loading ,savedLoading:false},saved:{...state.saved , savedPatient:false},error:action.payload}
        }


        //Save Partners
        case patientConstants.SAVE_GUARDIANS_PENDING: {
            return {...state,loading:{...state.loading ,savedLoading:true},saved:{...state.saved , savedGuardian:false}, user: action.payload}
        }  
        case  patientConstants.SAVE_GUARDIANS_FULFILLED: {
            return {...state,loading:{...state.loading ,savedLoading:false},saved:{...state.saved , savedGuardian:true},fetched: false, guardian:action?.payload?.data?.data}
        }    
        case patientConstants.SAVE_GUARDIANS_FAILURE:  {
            return {...state, loading:{...state.loading ,savedLoading:false},saved:{...state.saved , savedGuardian:false},error:action.error}
        }
        case patientConstants.SAVE_GUARDIANS_REJECTED:  {
            return {...state, loading:{...state.loading ,savedLoading:false},saved:{...state.saved , savedGuardian:false},error:action.payload}
        }


        //Edit Partners
        case patientConstants.EDIT_PATIENTS_PENDING: {
            return {...state,loading:{...state.loading ,savedLoading:true},saved:{...state.saved , savedPatient:false}, user: action.payload}
        }  
        case  patientConstants.EDIT_PATIENTS_FULFILLED: {
            return {...state,loading:{...state.loading ,savedLoading:false},saved:{...state.saved , savedPatient:true},fetched: false, patient:action?.payload?.data?.data}
        }    
        case patientConstants.EDIT_PATIENTS_FAILURE:  {
            return {...state, loading:{...state.loading ,savedLoading:false},saved:{...state.saved , savedPatient:false},error:action.error}
        }
        case patientConstants.EDIT_PATIENTS_REJECTED:  {
            return {...state, loading:{...state.loading ,savedLoading:false},saved:{...state.saved , savedPatient:false},error:action.payload}
        }
        //Edit Partners
        case patientConstants.EDIT_GUARDIAN_PENDING: {
            return {...state,loading:{...state.loading ,savedLoading:true},saved:{...state.saved , savedGuardian:false}, user: action.payload}
        }  
        case  patientConstants.EDIT_GUARDIAN_FULFILLED: {
            return {...state,loading:{...state.loading ,savedLoading:false},saved:{...state.saved , savedGuardian:true},fetched: false, patient:action?.payload?.data?.data}
        }    
        case patientConstants.EDIT_GUARDIAN_FAILURE:  {
            return {...state, loading:{...state.loading ,savedLoading:false},saved:{...state.saved , savedGuardian:false},error:action.error}
        }
        case patientConstants.EDIT_GUARDIAN_REJECTED:  {
            return {...state, loading:{...state.loading ,savedLoading:false},saved:{...state.saved , savedGuardian:false},error:action.payload}
        }
        //One Partners
        case patientConstants.GET_ONE_PATIENTS_PENDING: {
            return {...state,loading:{...state.loading , getOnePatient:true},saved:false, user: action.payload}
        }  
        case  patientConstants.GET_ONE_PATIENTS_FULFILLED: {
            return {...state,loading:{...state.loading , getOnePatient:false},saved:false,fetched: false, patient:action?.payload?.data?.data}
        }    
        case patientConstants.GET_ONE_PATIENTS_FAILURE:  {
            return {...state, loading:{...state.loading , getOnePatient:false},saved:false,error:action.error}
        }
        case patientConstants.GET_ONE_PATIENTS_REJECTED:  {
            return {...state, loading:{...state.loading , getOnePatient:false},saved:false,error:action.payload}
        }
        //One Partners
        case patientConstants.GET_PATIENTS_CHECKUPS_PENDING: {
            return {...state,loading:{...state.loading , getOnePatientCheckup:true},saved:false, user: action.payload}
        }  
        case  patientConstants.GET_PATIENTS_CHECKUPS_FULFILLED: {
            return {...state,loading:{...state.loading , getOnePatientCheckup:false},saved:false,fetched: false, patientCheckup:action?.payload?.data?.data}
        }    
        case patientConstants.GET_PATIENTS_CHECKUPS_FAILURE:  {
            return {...state, loading:{...state.loading , getOnePatientCheckup:false},saved:false,error:action.error}
        }
        case patientConstants.GET_PATIENTS_CHECKUPS_REJECTED:  {
            return {...state, loading:{...state.loading , getOnePatientCheckup:false},saved:false,error:action.payload}
        }
        
        

        //Create an appointment for this patient
        case patientConstants.CREATE_AN_APPOINTMENT: {
            return {...state,loadingSave: false,saved:true,selectedPatient:true,fetched: false, patient:action.payload }
        }
        case patientConstants.PATIENT_OR_GUARDIAN_SAVED_STATUS_CHANGE: {
            return {...state,loadingSave: false,selectedPatient:false,loading:{...state.loading ,savedLoading:false},saved:{...state.saved , savedGuardian:false,savedPatient:false},fetched: false};
        }

        default :
            return state;
    }

}
