export const serviceCentersSelectActionsConstants = {


    GET_SEARCH_SERVICE_CENTER: 'GET_SEARCH_SERVICE_CENTER',
    GET_SEARCH_SERVICE_CENTER_PENDING: 'GET_SEARCH_SERVICE_CENTER_PENDING',
    GET_SEARCH_SERVICE_CENTER_FULFILLED: 'GET_SEARCH_SERVICE_CENTER_FULFILLED',
    GET_SEARCH_SERVICE_CENTER_FAILURE: 'GET_SEARCH_SERVICE_CENTER_FAILURE',
    GET_SEARCH_SERVICE_CENTER_REJECTED: 'GET_SEARCH_SERVICE_CENTER_REJECTED',


};
