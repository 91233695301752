export const currencySymbol = "LKR "

export function formatCurrency(number) {
    let decimals = 2
    let decpoint = '.' // Or Number(0.1).toLocaleString().substring(1, 2)
    let thousand = ',' // Or Number(10000).toLocaleString().substring(2, 3)

    let n = Math.abs(number).toFixed(decimals).split('.')
    n[0] = n[0].split('').reverse().map((c, i, a) =>
    i > 0 && i < a.length && i % 3 === 0 ? c + thousand : c
    ).reverse().join('')
    let final = (Math.sign(number) < 0 ? '-' : '') + n.join(decpoint)

    return final.toLocaleString();
}


export function formatCurrencyWithAdd(arrayMoney){
    let decimals = 2
    let decpoint = '.' // Or Number(0.1).toLocaleString().substring(1, 2)
    let thousand = ',' // Or Number(10000).toLocaleString().substring(2, 3)
    let total = 0 ;
    let n
    let final

    arrayMoney.forEach(value => {
        total += parseFloat(value);
    });



    n = Math.abs(total).toFixed(decimals).split('.')
    n[0] = n[0].split('').reverse().map((c, i, a) =>
    i > 0 && i < a.length && i % 3 === 0 ? c + thousand : c
    ).reverse().join('')
    final = (Math.sign(total) < 0 ? '-' : '') + n.join(decpoint)

    return final.toLocaleString();
}


export function formatCurrencyWithAddWithReturnTax(arrayMoney,tax){
    let total = 0 ;

    arrayMoney.forEach(value => {
        total += parseFloat(value??0);
    });
    // total += parseFloat(tax??0);
    tax =  Number.isNaN(tax) ? 0 : tax;
    return  (total*parseFloat((100+parseFloat(tax) )/100)).toFixed(2);
    // return parseFloat(total).toFixed(2);

}
