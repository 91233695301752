export const getSamplesConstants = {


    GET_ALL_SAMPLE_DATA: 'GET_ALL_SAMPLE_DATA',
    GET_ALL_SAMPLE_DATA_PENDING: 'GET_ALL_SAMPLE_DATA_PENDING',
    GET_ALL_SAMPLE_DATA_FULFILLED: 'GET_ALL_SAMPLE_DATA_FULFILLED',
    GET_ALL_SAMPLE_DATA_FAILURE: 'GET_ALL_SAMPLE_DATA_FAILURE',
    GET_ALL_SAMPLE_DATA_REJECTED: 'GET_ALL_SAMPLE_DATA_REJECTED',

    SAVE_SAMPLE_RECEIVED: 'SAVE_SAMPLE_RECEIVED',
    SAVE_SAMPLE_RECEIVED_PENDING: 'SAVE_SAMPLE_RECEIVED_PENDING',
    SAVE_SAMPLE_RECEIVED_FULFILLED: 'SAVE_SAMPLE_RECEIVED_FULFILLED',
    SAVE_SAMPLE_RECEIVED_FAILURE: 'SAVE_SAMPLE_RECEIVED_FAILURE',
    SAVE_SAMPLE_RECEIVED_REJECTED: 'SAVE_SAMPLE_RECEIVED_REJECTED',

    SAVE_LAB_FALSE : 'SAVE_LAB_FALSE',

};
