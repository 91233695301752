
import {myLabsPendingActionsConstants} from './constants.js';
export default function myLabPendingReducer(state={
    myLabs: [],
    fetched: false,
    loadingSearch:false,
    loading:false,
    saved:false,
  }, action) {

    state = {...state,saved:{...state.saved ,labStart:false},loading:{...state.loading ,getLab:false }}
    switch (action.type) {



        // Add Patients
        case myLabsPendingActionsConstants.GET_ALL_MY_LABS_PENDING: {
            return {...state,loading:{...state.loading ,getLab:true }, user: action.payload,saved:false}
        }  
        case  myLabsPendingActionsConstants.GET_ALL_MY_LABS_FULFILLED: {
            return {...state,loading:{...state.loading ,getLab:false }, myLabs:action?.payload?.data?.data}
        }    
        case myLabsPendingActionsConstants.GET_ALL_MY_LABS_FAILURE:  {
            return {...state, loading:{...state.loading ,getLab:false },error:action.error}
        }
        case myLabsPendingActionsConstants.GET_ALL_MY_LABS_REJECTED:  {
            return {...state, loading:{...state.loading ,getLab:false },error:action.payload}
        }
        
        case myLabsPendingActionsConstants.GET_ALL_OTHERS_LABS_PENDING: {
            return {...state,loading:{...state.loading ,getLab:true }, user: action.payload,saved:false}
        }  
        case  myLabsPendingActionsConstants.GET_ALL_OTHERS_LABS_FULFILLED: {
            return {...state,loading:{...state.loading ,getLab:false }, otherLabs:action?.payload?.data?.data}
        }    
        case myLabsPendingActionsConstants.GET_ALL_OTHERS_LABS_FAILURE:  {
            return {...state, loading:{...state.loading ,getLab:false },error:action.error}
        }
        case myLabsPendingActionsConstants.GET_ALL_OTHERS_LABS_REJECTED:  {
            return {...state, loading:{...state.loading ,getLab:false },error:action.payload}
        }


        case myLabsPendingActionsConstants.SAVE_LAB_APPOINTMENT_START_PENDING: {
            return {...state,loading:{...state.loading ,saveAppointmentLab:true },saved:{...state.saved ,labStart:false } ,user: action.payload,saved:false}
        }  
        case  myLabsPendingActionsConstants.SAVE_LAB_APPOINTMENT_START_FULFILLED: {
            return {...state,loading:{...state.loading ,saveAppointmentLab:false },saved:{...state.saved ,labStart:true }, myLabStart:action?.payload?.data?.data}
        }    
        case myLabsPendingActionsConstants.SAVE_LAB_APPOINTMENT_START_FAILURE:  {
            return {...state, loading:{...state.loading ,saveAppointmentLab:false },saved:{...state.saved ,labStart:false },error:action.error}
        }
        case myLabsPendingActionsConstants.SAVE_LAB_APPOINTMENT_START_REJECTED:  {
            return {...state, loading:{...state.loading ,saveAppointmentLab:false },saved:{...state.saved ,labStart:false  },error:action.payload}
        }

        case myLabsPendingActionsConstants.SAVE_LAB_APPOINTMENT_END_PENDING: {
            return {...state,loading:{...state.loading ,saveAppointmentLab:true },saved:{...state.saved ,labEnd:false } ,user: action.payload,saved:false}
        }  
        case  myLabsPendingActionsConstants.SAVE_LAB_APPOINTMENT_END_FULFILLED: {
            return {...state,loading:{...state.loading ,saveAppointmentLab:false },saved:{...state.saved ,labEnd:true }, myLabEnd:action?.payload?.data?.data}
        }    
        case myLabsPendingActionsConstants.SAVE_LAB_APPOINTMENT_END_FAILURE:  {
            return {...state, loading:{...state.loading ,saveAppointmentLab:false },saved:{...state.saved ,labEnd:false },error:action.error}
        }
        case myLabsPendingActionsConstants.SAVE_LAB_APPOINTMENT_END_REJECTED:  {
            return {...state, loading:{...state.loading ,saveAppointmentLab:false },saved:{...state.saved ,labEnd:false  },error:action.payload}
        }


        case myLabsPendingActionsConstants.SAVE_LAB_APPOINTMENT_DELIVERED_REPORT_PENDING: {
            return {...state,loading:{...state.loading ,saveAppointmentLab:true },saved:{...state.saved ,labEnd:false } ,user: action.payload,saved:false}
        }  
        case  myLabsPendingActionsConstants.SAVE_LAB_APPOINTMENT_DELIVERED_REPORT_FULFILLED: {
            return {...state,loading:{...state.loading ,saveAppointmentLab:false },saved:{...state.saved ,labEnd:true }, myLabEnd:action?.payload?.data?.data}
        }    
        case myLabsPendingActionsConstants.SAVE_LAB_APPOINTMENT_DELIVERED_REPORT_FAILURE:  {
            return {...state, loading:{...state.loading ,saveAppointmentLab:false },saved:{...state.saved ,labEnd:false },error:action.error}
        }
        case myLabsPendingActionsConstants.SAVE_LAB_APPOINTMENT_DELIVERED_REPORT_REJECTED:  {
            return {...state, loading:{...state.loading ,saveAppointmentLab:false },saved:{...state.saved ,labEnd:false  },error:action.payload}
        }



        default :
            return state;
    }

}
