export const serviceTypesSelectActionsConstants = {


    GET_SEARCH_SERVICE_TYPE: 'GET_SEARCH_SERVICE_TYPE',
    GET_SEARCH_SERVICE_TYPE_PENDING: 'GET_SEARCH_SERVICE_TYPE_PENDING',
    GET_SEARCH_SERVICE_TYPE_FULFILLED: 'GET_SEARCH_SERVICE_TYPE_FULFILLED',
    GET_SEARCH_SERVICE_TYPE_FAILURE: 'GET_SEARCH_SERVICE_TYPE_FAILURE',
    GET_SEARCH_SERVICE_TYPE_REJECTED: 'GET_SEARCH_SERVICE_TYPE_REJECTED',


};
