
import {pendingServiceApprovalConstants} from './constants.js';
export default function pendingServiceApprovalReducer(state={
    myServicesApproval: [],
    othersServicesApproval: [],
    fetched: false,
    loadingSearch:false,
    loading:false,
    saved:false,
  }, action) {

    state = {...state,saved:{...state.saved ,serviceStart:false},loading:{...state.loading ,getService:false }}
    switch (action.type) {



        // My 
        case pendingServiceApprovalConstants.GET_ALL_MY_SERVICE_PENDING_APPROVAL_CHECKED_PENDING: {
            return {...state,loading:{...state.loading ,getService:false }, user: action.payload,saved:false}
        }  
        case  pendingServiceApprovalConstants.GET_ALL_MY_SERVICE_PENDING_APPROVAL_CHECKED_FULFILLED: {
            return {...state,loading:{...state.loading ,getService:false }, myServicesApproval:action?.payload?.data?.data}
        }    
        case pendingServiceApprovalConstants.GET_ALL_MY_SERVICE_PENDING_APPROVAL_CHECKED_FAILURE:  {
            return {...state, loading:{...state.loading ,getService:false },error:action.error}
        }
        case pendingServiceApprovalConstants.GET_ALL_MY_SERVICE_PENDING_APPROVAL_CHECKED_REJECTED:  {
            return {...state, loading:{...state.loading ,getService:false },error:action.payload}
        }


         // Others
        case pendingServiceApprovalConstants.GET_ALL_OTHERS_SERVICE_PENDING_APPROVAL_CHECKED_PENDING: {
            return {...state,loading:{...state.loading ,getService:false }, user: action.payload,saved:false}
        }  
        case  pendingServiceApprovalConstants.GET_ALL_OTHERS_SERVICE_PENDING_APPROVAL_CHECKED_FULFILLED: {
            return {...state,loading:{...state.loading ,getService:false }, othersServicesApproval:action?.payload?.data?.data}
        }    
        case pendingServiceApprovalConstants.GET_ALL_OTHERS_SERVICE_PENDING_APPROVAL_CHECKED_FAILURE:  {
            return {...state, loading:{...state.loading ,getService:false },error:action.error}
        }
        case pendingServiceApprovalConstants.GET_ALL_OTHERS_SERVICE_PENDING_APPROVAL_CHECKED_REJECTED:  {
            return {...state, loading:{...state.loading ,getService:false },error:action.payload}
        }

       
        default :
            return state;
    }

}
