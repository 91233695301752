export const serviceApprovalSignedConstants = {


    GET_ALL_MY_SERVICE_APPROVAL_SIGNED: 'GET_ALL_MY_SERVICE_APPROVAL_SIGNED',
    GET_ALL_MY_SERVICE_APPROVAL_SIGNED_PENDING: 'GET_ALL_MY_SERVICE_APPROVAL_SIGNED_PENDING',
    GET_ALL_MY_SERVICE_APPROVAL_SIGNED_FULFILLED: 'GET_ALL_MY_SERVICE_APPROVAL_SIGNED_FULFILLED',
    GET_ALL_MY_SERVICE_APPROVAL_SIGNED_FAILURE: 'GET_ALL_MY_SERVICE_APPROVAL_SIGNED_FAILURE',
    GET_ALL_MY_SERVICE_APPROVAL_SIGNED_REJECTED: 'GET_ALL_MY_SERVICE_APPROVAL_SIGNED_REJECTED',


    SAVE_SERVICE_APPROVAL_CHECKED: 'SAVE_SERVICE_APPROVAL_CHECKED',
    SAVE_SERVICE_APPROVAL_CHECKED_PENDING: 'SAVE_SERVICE_APPROVAL_CHECKED_PENDING',
    SAVE_SERVICE_APPROVAL_CHECKED_FULFILLED: 'SAVE_SERVICE_APPROVAL_CHECKED_FULFILLED',
    SAVE_SERVICE_APPROVAL_CHECKED_FAILURE: 'SAVE_SERVICE_APPROVAL_CHECKED_FAILURE',
    SAVE_SERVICE_APPROVAL_CHECKED_REJECTED: 'SAVE_SERVICE_APPROVAL_CHECKED_REJECTED',

    
    SAVE_SERVICE_APPROVAL_SIGNED: 'SAVE_SERVICE_APPROVAL_SIGNED',
    SAVE_SERVICE_APPROVAL_SIGNED_PENDING: 'SAVE_SERVICE_APPROVAL_SIGNED_PENDING',
    SAVE_SERVICE_APPROVAL_SIGNED_FULFILLED: 'SAVE_SERVICE_APPROVAL_SIGNED_FULFILLED',
    SAVE_SERVICE_APPROVAL_SIGNED_FAILURE: 'SAVE_SERVICE_APPROVAL_SIGNED_FAILURE',
    SAVE_SERVICE_APPROVAL_SIGNED_REJECTED: 'SAVE_SERVICE_APPROVAL_SIGNED_REJECTED',

    SAVE_FALSE : 'SAVE_FALSE',

};
