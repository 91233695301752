export const serviceAppointmentActionsConstants = {


    GET_ALL_SERVICES_APPOINTMENT: 'GET_ALL_SERVICES_APPOINTMENT',
    GET_ALL_SERVICES_APPOINTMENT_PENDING: 'GET_ALL_SERVICES_APPOINTMENT_PENDING',
    GET_ALL_SERVICES_APPOINTMENT_FULFILLED: 'GET_ALL_SERVICES_APPOINTMENT_FULFILLED',
    GET_ALL_SERVICES_APPOINTMENT_FAILURE: 'GET_ALL_SERVICES_APPOINTMENT_FAILURE',
    GET_ALL_SERVICES_APPOINTMENT_REJECTED: 'GET_ALL_SERVICES_APPOINTMENT_REJECTED',

    GET_ALL_OTHERS_SERVICES: 'GET_ALL_OTHERS_SERVICES',
    GET_ALL_OTHERS_SERVICES_PENDING: 'GET_ALL_OTHERS_SERVICES_PENDING',
    GET_ALL_OTHERS_SERVICES_FULFILLED: 'GET_ALL_OTHERS_SERVICES_FULFILLED',
    GET_ALL_OTHERS_SERVICES_FAILURE: 'GET_ALL_OTHERS_SERVICES_FAILURE',
    GET_ALL_OTHERS_SERVICES_REJECTED: 'GET_ALL_OTHERS_SERVICES_REJECTED',

    SAVE_SERVICE_APPOINTMENT_START: 'SAVE_SERVICE_APPOINTMENT_START',
    SAVE_SERVICE_APPOINTMENT_START_PENDING: 'SAVE_SERVICE_APPOINTMENT_START_PENDING',
    SAVE_SERVICE_APPOINTMENT_START_FULFILLED: 'SAVE_SERVICE_APPOINTMENT_START_FULFILLED',
    SAVE_SERVICE_APPOINTMENT_START_FAILURE: 'SAVE_SERVICE_APPOINTMENT_START_FAILURE',
    SAVE_SERVICE_APPOINTMENT_START_REJECTED: 'SAVE_SERVICE_APPOINTMENT_START_REJECTED',

    SAVE_SERVICE_APPOINTMENT_END: 'SAVE_SERVICE_APPOINTMENT_END',
    SAVE_SERVICE_APPOINTMENT_END_PENDING: 'SAVE_SERVICE_APPOINTMENT_END_PENDING',
    SAVE_SERVICE_APPOINTMENT_END_FULFILLED: 'SAVE_SERVICE_APPOINTMENT_END_FULFILLED',
    SAVE_SERVICE_APPOINTMENT_END_FAILURE: 'SAVE_SERVICE_APPOINTMENT_END_FAILURE',
    SAVE_SERVICE_APPOINTMENT_END_REJECTED: 'SAVE_SERVICE_APPOINTMENT_END_REJECTED',

    TRANSFER_SERVICE_APPOINTMENT: 'TRANSFER_SERVICE_APPOINTMENT',
    TRANSFER_SERVICE_APPOINTMENT_PENDING: 'TRANSFER_SERVICE_APPOINTMENT_PENDING',
    TRANSFER_SERVICE_APPOINTMENT_FULFILLED: 'TRANSFER_SERVICE_APPOINTMENT_FULFILLED',
    TRANSFER_SERVICE_APPOINTMENT_FAILURE: 'TRANSFER_SERVICE_APPOINTMENT_FAILURE',
    TRANSFER_SERVICE_APPOINTMENT_REJECTED: 'TRANSFER_SERVICE_APPOINTMENT_REJECTED',

    CLEAR_SERVICE_APPOINTMENT: 'CLEAR_SERVICE_APPOINTMENT',


};
