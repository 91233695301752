export const messagesEnum = {
    SUCCESS : "Success",
    ERROR : "Error",


    SERVICE_SCHEDULE_ADDED_SUCCESS:'Service schedule added successfully' ,
    PLEASE_SELECT_SERVICE:'Please select service.' ,
    PLEASE_TECHNICIAN_SERVICE:'Please select technician.' ,
    PLEASE_DOCTOR_SERVICE:'Please select doctor.' ,

    // Appointment
    APPOINTMENT_SAVED_SUCCESSFULLY:'Appointment saved successfully' ,
    APPOINTMENT_UPDATED_SUCCESSFULLY:'Appointment updated successfully' ,
    APPOINTMENT_RESCHEDULE_SAVED_SUCCESSFULLY:'Appointment reschedule saved successfully' ,
    PATIENT_SAVED_SUCCESSFULLY:'Patient saved successfully' ,
    PATIENT_UPDATED_SUCCESSFULLY:'Patient update successfully' ,
    SERVICE_START_SUCCESSFULLY:'Service start successfully' ,
    SERVICE_END_SUCCESSFULLY:'Service end successfully' ,
    SERVICE_FINISHED_SUCCESSFULLY:'Service finished successfully' ,
    SAVE_SERVICE_HR_USAGES:'Service hr usage  successfully' ,
    SAVE_SERVICE_ITEM_USAGES:'Service item usage  successfully' ,
    UPDATE_SERVICE_ITEM_USAGES:'Service item usage updated  successfully' ,
    SAVE_SERVICE_MACHINE_USAGES:'Service machine usage  successfully' ,
    UPDATE_SERVICE_MACHINE_USAGES:'Service machine usage updated successfully' ,
    SAVE_SERVICE_REPORT:'Report added  successfully' ,
    SAVE_SERVICE_CHECKED:'Report checked successfully' ,
    SAVE_SERVICE_CHECKED_ERROR : 'Please Fill All Values ',
    SERVICE_REPORT_DELIVERED_SUCCESSFULLY:'Report delivered successfully' ,
    SAVE_USAGE_TEMPLATE:'Usage Template saved successfully' ,
    UPDATE_USAGE_TEMPLATE:'Usage Template updated successfully' ,
    SERVICE_STATUS_CHANGED_SUCCESSFULLY:'Service status updated successfully' ,




    DOCTOR_SCHEDULE_CANCELED_SUCCESS:'Doctor schedule cancelled successfully' ,
    DOCTOR_SCHEDULE_CANCELED_ERROR:'Doctor schedule not cancelled ',


    DOCTOR_SCHEDULE_LEAVED_SUCCESS:'Doctor get leave for this time slot' ,
    DOCTOR_SCHEDULE_LEAVED_ERROR:'Something went wrong',


    PATIENT_SCHEDULE_CANCELED_SUCCESS:'Patient schedule cancelled successfully' ,
    PATIENT_SCHEDULE_CANCELED_ERROR:'Patient schedule not cancelled ',




    // Checkups

    CHECKUP_ROOM_ALLOCATION_SAVED_SUCCESSFULLY:"Doctor checkup room has been allocated successfully.",
    CHECKUP_ROOM_ALLOCATION_SAVED_ERROR:"Doctor checkup room hasn't allocated.",

    PLEASE_SELECT_PRODUCT_BATCH:"Please select product batch.",

    // Lab Tests
    SAVE_TEST_SAMPLE_ADDED : 'Test saved sample added  successfully.',
    LAB_START_SUCCESSFULLY:'Lab start successfully' ,
    LAB_END_SUCCESSFULLY:'Lab end successfully' ,
    SAVE_LAB_HR_USAGES:'Lab hr usage  successfully' ,
    SAVE_LAB_MACHINE_USAGES:'Lab machine usage  successfully' ,
    UPDATE_LAB_MACHINE_USAGES:'Lab machine usage updated successfully' ,
    SAVE_LAB_ITEM_USAGES:'Lab item usage updated successfully' ,
    UPDATE_LAB_ITEM_USAGES:'Lab item usage updated successfully' ,
    SAVE_LAB_TEST_SAMPLE_TRANSFER_IN:'Lab test sample transfer in successfully' ,
    SAVE_LAB_TEST_SAMPLE_TRANSFER_OUT:'Lab test sample transfer out successfully' ,
    SAVE_LAB_REPORT:'Lab test report added successfully ' ,
    EDIT_LAB_REPORT:'Lab test report updated successfully' ,
    SAVE_LAB_CHECKED:'Lab test report checked successfully' ,
    SAVE_LAB_APPROVE:'Lab test report approved successfully' ,
    LAB_REPORT_DELIVERED_SUCCESSFULLY:'Lab report delivered successfully' ,
    LAB_STATUS_CHANGED_SUCCESSFULLY:'Lab appointment status  successfully' ,
    LAB_RETEST_SAMPLE_SUCCESS:'Lab test created for this sample  successfully' ,
    PLEASE_ADD_REPORT_TO_THIS_TEST:'Please add report to this test.' ,
    SAVE_LAB_SAMPLE_RECEIVED:'Test sample received.' ,
    ERROR_TEMPLATE_CREATE_FOR_THIS_TEST:'Report template not create for this test.' ,
    UPDATE_USAGE_TEMPLATE:'Template updated successfully.' ,
    CHECKED_REPORT_EMPTY_ERROR:'Please add test report.' ,


    SESSION_OUT:'Session Expired Please login...' ,
    VOUCHER_CREATED : 'Voucher has been created for this appointment'
}
