
import {labApprovalSignedConstants} from './constants.js';
export default function labApprovedSignedReducer(state={
    myLabsApproval: {},
    fetched: false,
    loadingSearch:false,
    loading:false,
    saved:false,
  }, action) {

    state = {...state,saved:{...state.saved ,labStart:false},loading:{...state.loading ,getLab:false }}
    switch (action.type) {



        case labApprovalSignedConstants.GET_ALL_MY_LAB_APPROVAL_SIGNED_PENDING: {
            return {...state,loading:{...state.loading ,getLab:false }, user: action.payload,saved:false}
        }  
        case  labApprovalSignedConstants.GET_ALL_MY_LAB_APPROVAL_SIGNED_FULFILLED: {
            return {...state,loading:{...state.loading ,getLab:false }, myLabsApproval:action?.payload?.data?.data}
        }    
        case labApprovalSignedConstants.GET_ALL_MY_LAB_APPROVAL_SIGNED_FAILURE:  {
            return {...state, loading:{...state.loading ,getLab:false },error:action.error}
        }
        case labApprovalSignedConstants.GET_ALL_MY_LAB_APPROVAL_SIGNED_REJECTED:  {
            return {...state, loading:{...state.loading ,getLab:false },error:action.payload}
        }

        case labApprovalSignedConstants.SAVE_LAB_APPROVAL_CHECKED_PENDING: {
            return {...state,loading:{...state.loading , saveLabReport:true },save:{...state.save , saveLabReport:false } ,}
        }  
        case  labApprovalSignedConstants.SAVE_LAB_APPROVAL_CHECKED_FULFILLED: {
            return {...state,loading:{...state.loading , saveLabReport:false } ,save:{...state.save , saveLabReport:true } , labReport:action?.payload?.data?.data}
        }    
        case labApprovalSignedConstants.SAVE_LAB_APPROVAL_CHECKED_FAILURE:  {
            return {...state, loading:{...state.loading , saveLabReport:false } ,save:{...state.save , saveLabReport:false } ,error:action.error}
        }
        case labApprovalSignedConstants.SAVE_LAB_APPROVAL_CHECKED_REJECTED:  {
            return {...state, loading:{...state.loading , saveLabReport:false } ,save:{...state.save , saveLabReport:false } ,error:action.payload}
        }

        case labApprovalSignedConstants.SAVE_LAB_APPROVAL_SIGNED_PENDING: {
            return {...state,loading:{...state.loading , saveLabReport:true },save:{...state.save , saveLabReport:false } ,}
        }  
        case  labApprovalSignedConstants.SAVE_LAB_APPROVAL_SIGNED_FULFILLED: {
            return {...state,loading:{...state.loading , saveLabReport:false } ,save:{...state.save , saveLabReport:true } , labReport:action?.payload?.data?.data}
        }    
        case labApprovalSignedConstants.SAVE_LAB_APPROVAL_SIGNED_FAILURE:  {
            return {...state, loading:{...state.loading , saveLabReport:false } ,save:{...state.save , saveLabReport:false } ,error:action.error}
        }
        case labApprovalSignedConstants.SAVE_LAB_APPROVAL_SIGNED_REJECTED:  {
            return {...state, loading:{...state.loading , saveLabReport:false } ,save:{...state.save , saveLabReport:false } ,error:action.payload}
        }

        case labApprovalSignedConstants.SAVE_LAB_FALSE :  {
            return {...state,save:{...state.save , 
                    
                     saveLabReport:false } 
                      ,error:{}}
        }
       
        default :
            return state;
    }

}
