
import { dashboardConstants } from './constants.js';
export default function dashboardLabReducer(state={
    error: null,
    loading: {
        getAll:false,
    },
    fetched: false,
    data : []
  }, action) {

    state = {...state,error:{} }

    switch (action.type) {
    
        case dashboardConstants.GET_ALL_DASHBOARD_PENDING: {
            return {...state,loading:{...state.loading ,getAll:true },fetched:false}
        }  
        case  dashboardConstants.GET_ALL_DASHBOARD_FULFILLED: {
            return {...state,loading:{...state.loading ,getAll:false },fetched:true , data:action?.payload?.data?.data}
        }    
        case dashboardConstants.GET_ALL_DASHBOARD_FAILURE:  {
            return {...state, loading:{...state.loading ,getAll:false },fetched:false ,error:action.error}
        }
        case dashboardConstants.GET_ALL_DASHBOARD_REJECTED:  {
            return {...state, loading:{...state.loading ,getAll:false },fetched:false ,error:action.error}
        }
        
        default :
            return state;
    }

}
