export const appointmentConstants = {




    GET_ONE_APPOINTMENT_DETAILS : 'GET_ONE_APPOINTMENT_DETAILS',
    GET_ONE_APPOINTMENT_DETAILS_PENDING : 'GET_ONE_APPOINTMENT_DETAILS_PENDING',
    GET_ONE_APPOINTMENT_DETAILS_FULFILLED : 'GET_ONE_APPOINTMENT_DETAILS_FULFILLED',
    GET_ONE_APPOINTMENT_DETAILS_FAILURE : 'GET_ONE_APPOINTMENT_DETAILS_FAILURE',
    GET_ONE_APPOINTMENT_DETAILS_REJECTED : 'GET_ONE_APPOINTMENT_DETAILS_REJECTED',

    GET_ALL_SERVICE_TECHNICIAN : 'GET_ALL_SERVICE_TECHNICIAN',
    GET_ALL_SERVICE_TECHNICIAN_PENDING : 'GET_ALL_SERVICE_TECHNICIAN_PENDING',
    GET_ALL_SERVICE_TECHNICIAN_FULFILLED : 'GET_ALL_SERVICE_TECHNICIAN_FULFILLED',
    GET_ALL_SERVICE_TECHNICIAN_FAILURE : 'GET_ALL_SERVICE_TECHNICIAN_FAILURE',
    GET_ALL_SERVICE_TECHNICIAN_REJECTED : 'GET_ALL_SERVICE_TECHNICIAN_REJECTED',

    GET_ALL_IPD_PATIENT_ADD_APPOINTMENT : 'GET_ALL_IPD_PATIENT_ADD_APPOINTMENT',
    GET_ALL_IPD_PATIENT_ADD_APPOINTMENT_PENDING : 'GET_ALL_IPD_PATIENT_ADD_APPOINTMENT_PENDING',
    GET_ALL_IPD_PATIENT_ADD_APPOINTMENT_FULFILLED : 'GET_ALL_IPD_PATIENT_ADD_APPOINTMENT_FULFILLED',
    GET_ALL_IPD_PATIENT_ADD_APPOINTMENT_FAILURE : 'GET_ALL_IPD_PATIENT_ADD_APPOINTMENT_FAILURE',
    GET_ALL_IPD_PATIENT_ADD_APPOINTMENT_REJECTED : 'GET_ALL_IPD_PATIENT_ADD_APPOINTMENT_REJECTED',

    PATIENT_SAVE_RESCHEDULE_APPOINTMENT : 'PATIENT_SAVE_RESCHEDULE_APPOINTMENT',
    PATIENT_SAVE_RESCHEDULE_APPOINTMENT_PENDING : 'PATIENT_SAVE_RESCHEDULE_APPOINTMENT_PENDING',
    PATIENT_SAVE_RESCHEDULE_APPOINTMENT_FULFILLED : 'PATIENT_SAVE_RESCHEDULE_APPOINTMENT_FULFILLED',
    PATIENT_SAVE_RESCHEDULE_APPOINTMENT_FAILURE : 'PATIENT_SAVE_RESCHEDULE_APPOINTMENT_FAILURE',
    PATIENT_SAVE_RESCHEDULE_APPOINTMENT_REJECTED : 'PATIENT_SAVE_RESCHEDULE_APPOINTMENT_REJECTED',

    CANCEL_PATIENT_APPOINTMENT : 'CANCEL_PATIENT_APPOINTMENT',
    CANCEL_PATIENT_APPOINTMENT_PENDING : 'CANCEL_PATIENT_APPOINTMENT_PENDING',
    CANCEL_PATIENT_APPOINTMENT_FULFILLED : 'CANCEL_PATIENT_APPOINTMENT_FULFILLED',
    CANCEL_PATIENT_APPOINTMENT_FAILURE : 'CANCEL_PATIENT_APPOINTMENT_FAILURE',
    CANCEL_PATIENT_APPOINTMENT_REJECTED : 'CANCEL_PATIENT_APPOINTMENT_REJECTED',


    GET_ALL_SERVICE_ITEMS : 'GET_ALL_SERVICE_ITEMS',
    GET_ALL_SERVICE_ITEMS_PENDING : 'GET_ALL_SERVICE_ITEMS_PENDING',
    GET_ALL_SERVICE_ITEMS_FULFILLED : 'GET_ALL_SERVICE_ITEMS_FULFILLED',
    GET_ALL_SERVICE_ITEMS_FAILURE : 'GET_ALL_SERVICE_ITEMS_FAILURE',
    GET_ALL_SERVICE_ITEMS_REJECTED : 'GET_ALL_SERVICE_ITEMS_REJECTED',

    UPDATE_APPOINTMENT_STATUS : 'UPDATE_APPOINTMENT_STATUS',
    UPDATE_APPOINTMENT_STATUS_PENDING : 'UPDATE_APPOINTMENT_STATUS_PENDING',
    UPDATE_APPOINTMENT_STATUS_FULFILLED : 'UPDATE_APPOINTMENT_STATUS_FULFILLED',
    UPDATE_APPOINTMENT_STATUS_FAILURE : 'UPDATE_APPOINTMENT_STATUS_FAILURE',
    UPDATE_APPOINTMENT_STATUS_REJECTED : 'UPDATE_APPOINTMENT_STATUS_REJECTED',

    GET_ALL_SERVICE_MACHINES : 'GET_ALL_SERVICE_MACHINES',
    GET_ALL_SERVICE_MACHINES_PENDING : 'GET_ALL_SERVICE_MACHINES_PENDING',
    GET_ALL_SERVICE_MACHINES_FULFILLED : 'GET_ALL_SERVICE_MACHINES_FULFILLED',
    GET_ALL_SERVICE_MACHINES_FAILURE : 'GET_ALL_SERVICE_MACHINES_FAILURE',
    GET_ALL_SERVICE_MACHINES_REJECTED : 'GET_ALL_SERVICE_MACHINES_REJECTED',

    SAVE_SERVICE_HR_USAGE : 'SAVE_SERVICE_HR_USAGE',
    SAVE_SERVICE_HR_USAGE_PENDING : 'SAVE_SERVICE_HR_USAGE_PENDING',
    SAVE_SERVICE_HR_USAGE_FULFILLED : 'SAVE_SERVICE_HR_USAGE_FULFILLED',
    SAVE_SERVICE_HR_USAGE_FAILURE : 'SAVE_SERVICE_HR_USAGE_FAILURE',
    SAVE_SERVICE_HR_USAGE_REJECTED : 'SAVE_SERVICE_HR_USAGE_REJECTED',


    SAVE_SERVICE_ITEM_USAGE : 'SAVE_SERVICE_ITEM_USAGE',
    SAVE_SERVICE_ITEM_USAGE_PENDING : 'SAVE_SERVICE_ITEM_USAGE_PENDING',
    SAVE_SERVICE_ITEM_USAGE_FULFILLED : 'SAVE_SERVICE_ITEM_USAGE_FULFILLED',
    SAVE_SERVICE_ITEM_USAGE_FAILURE : 'SAVE_SERVICE_ITEM_USAGE_FAILURE',
    SAVE_SERVICE_ITEM_USAGE_REJECTED : 'SAVE_SERVICE_ITEM_USAGE_REJECTED',

    UPDATE_SERVICE_ITEM_USAGE : 'UPDATE_SERVICE_ITEM_USAGE',
    UPDATE_SERVICE_ITEM_USAGE_PENDING : 'UPDATE_SERVICE_ITEM_USAGE_PENDING',
    UPDATE_SERVICE_ITEM_USAGE_FULFILLED : 'UPDATE_SERVICE_ITEM_USAGE_FULFILLED',
    UPDATE_SERVICE_ITEM_USAGE_FAILURE : 'UPDATE_SERVICE_ITEM_USAGE_FAILURE',
    UPDATE_SERVICE_ITEM_USAGE_REJECTED : 'UPDATE_SERVICE_ITEM_USAGE_REJECTED',

    DELETE_SERVICE_ITEM_USAGE : 'DELETE_SERVICE_ITEM_USAGE',
    DELETE_SERVICE_ITEM_USAGE_PENDING : 'DELETE_SERVICE_ITEM_USAGE_PENDING',
    DELETE_SERVICE_ITEM_USAGE_FULFILLED : 'DELETE_SERVICE_ITEM_USAGE_FULFILLED',
    DELETE_SERVICE_ITEM_USAGE_FAILURE : 'DELETE_SERVICE_ITEM_USAGE_FAILURE',
    DELETE_SERVICE_ITEM_USAGE_REJECTED : 'DELETE_SERVICE_ITEM_USAGE_REJECTED',


    SAVE_SERVICE_MACHINES : 'SAVE_SERVICE_MACHINES',
    SAVE_SERVICE_MACHINES_PENDING : 'SAVE_SERVICE_MACHINES_PENDING',
    SAVE_SERVICE_MACHINES_FULFILLED : 'SAVE_SERVICE_MACHINES_FULFILLED',
    SAVE_SERVICE_MACHINES_FAILURE : 'SAVE_SERVICE_MACHINES_FAILURE',
    SAVE_SERVICE_MACHINES_REJECTED : 'SAVE_SERVICE_MACHINES_REJECTED',

    UPDATE_SERVICE_MACHINES : 'UPDATE_SERVICE_MACHINES',
    UPDATE_SERVICE_MACHINES_PENDING : 'UPDATE_SERVICE_MACHINES_PENDING',
    UPDATE_SERVICE_MACHINES_FULFILLED : 'UPDATE_SERVICE_MACHINES_FULFILLED',
    UPDATE_SERVICE_MACHINES_FAILURE : 'UPDATE_SERVICE_MACHINES_FAILURE',
    UPDATE_SERVICE_MACHINES_REJECTED : 'UPDATE_SERVICE_MACHINES_REJECTED',

    SAVE_SERVICE_REPORT : 'SAVE_SERVICE_REPORT',
    SAVE_SERVICE_REPORT_PENDING : 'SAVE_SERVICE_REPORT_PENDING',
    SAVE_SERVICE_REPORT_FULFILLED : 'SAVE_SERVICE_REPORT_FULFILLED',
    SAVE_SERVICE_REPORT_FAILURE : 'SAVE_SERVICE_REPORT_FAILURE',
    SAVE_SERVICE_REPORT_REJECTED : 'SAVE_SERVICE_REPORT_REJECTED',


    GET_PATIENTS_FOR_ADD_APPOINTMENT : 'GET_PATIENTS_FOR_ADD_APPOINTMENT',
    GET_PATIENTS_FOR_ADD_APPOINTMENT_PENDING : 'GET_PATIENTS_FOR_ADD_APPOINTMENT_PENDING',
    GET_PATIENTS_FOR_ADD_APPOINTMENT_FULFILLED : 'GET_PATIENTS_FOR_ADD_APPOINTMENT_FULFILLED',
    GET_PATIENTS_FOR_ADD_APPOINTMENT_FAILURE : 'GET_PATIENTS_FOR_ADD_APPOINTMENT_FAILURE',
    GET_PATIENTS_FOR_ADD_APPOINTMENT_REJECTED : 'GET_PATIENTS_FOR_ADD_APPOINTMENT_REJECTED',

    GET_SERVICES_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE : 'GET_SERVICES_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE',
    GET_SERVICES_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE_PENDING : 'GET_SERVICES_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE_PENDING',
    GET_SERVICES_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE_FULFILLED : 'GET_SERVICES_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE_FULFILLED',
    GET_SERVICES_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE_FAILURE : 'GET_SERVICES_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE_FAILURE',
    GET_SERVICES_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE_REJECTED : 'GET_SERVICES_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE_REJECTED',

    GET_GUARDIAN_FOR_ADD_APPOINTMENT : 'GET_GUARDIAN_FOR_ADD_APPOINTMENT',
    GET_GUARDIAN_FOR_ADD_APPOINTMENT_PENDING : 'GET_GUARDIAN_FOR_ADD_APPOINTMENT_PENDING',
    GET_GUARDIAN_FOR_ADD_APPOINTMENT_FULFILLED : 'GET_GUARDIAN_FOR_ADD_APPOINTMENT_FULFILLED',
    GET_GUARDIAN_FOR_ADD_APPOINTMENT_FAILURE : 'GET_GUARDIAN_FOR_ADD_APPOINTMENT_FAILURE',
    GET_GUARDIAN_FOR_ADD_APPOINTMENT_REJECTED : 'GET_GUARDIAN_FOR_ADD_APPOINTMENT_REJECTED',

    GET_REFERRED_DOCTOR_ADD_APPOINTMENT : 'GET_REFERRED_DOCTOR_ADD_APPOINTMENT',
    GET_REFERRED_DOCTOR_ADD_APPOINTMENT_PENDING : 'GET_REFERRED_DOCTOR_ADD_APPOINTMENT_PENDING',
    GET_REFERRED_DOCTOR_ADD_APPOINTMENT_FULFILLED : 'GET_REFERRED_DOCTOR_ADD_APPOINTMENT_FULFILLED',
    GET_REFERRED_DOCTOR_ADD_APPOINTMENT_FAILURE : 'GET_REFERRED_DOCTOR_ADD_APPOINTMENT_FAILURE',
    GET_REFERRED_DOCTOR_ADD_APPOINTMENT_REJECTED : 'GET_REFERRED_DOCTOR_ADD_APPOINTMENT_REJECTED',

    GET_REFERRED_CENTER_ADD_APPOINTMENT : 'GET_REFERRED_CENTER_ADD_APPOINTMENT',
    GET_REFERRED_CENTER_ADD_APPOINTMENT_PENDING : 'GET_REFERRED_CENTER_ADD_APPOINTMENT_PENDING',
    GET_REFERRED_CENTER_ADD_APPOINTMENT_FULFILLED : 'GET_REFERRED_CENTER_ADD_APPOINTMENT_FULFILLED',
    GET_REFERRED_CENTER_ADD_APPOINTMENT_FAILURE : 'GET_REFERRED_CENTER_ADD_APPOINTMENT_FAILURE',
    GET_REFERRED_CENTER_ADD_APPOINTMENT_REJECTED : 'GET_REFERRED_CENTER_ADD_APPOINTMENT_REJECTED',

    GET_ALL_SERVICES_ADD_APPOINTMENT : 'GET_ALL_SERVICES_ADD_APPOINTMENT',
    GET_ALL_SERVICES_ADD_APPOINTMENT_PENDING : 'GET_ALL_SERVICES_ADD_APPOINTMENT_PENDING',
    GET_ALL_SERVICES_ADD_APPOINTMENT_FULFILLED : 'GET_ALL_SERVICES_ADD_APPOINTMENT_FULFILLED',
    GET_ALL_SERVICES_ADD_APPOINTMENT_FAILURE : 'GET_ALL_SERVICES_ADD_APPOINTMENT_FAILURE',
    GET_ALL_SERVICES_ADD_APPOINTMENT_REJECTED : 'GET_ALL_SERVICES_ADD_APPOINTMENT_REJECTED',

    GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT : 'GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT',
    GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT_PENDING : 'GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT_PENDING',
    GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT_FULFILLED : 'GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT_FULFILLED',
    GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT_FAILURE : 'GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT_FAILURE',
    GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT_REJECTED : 'GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT_REJECTED',

    GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT : 'GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT',
    GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_PENDING : 'GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_PENDING',
    GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_FULFILLED : 'GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_FULFILLED',
    GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_FAILURE : 'GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_FAILURE',
    GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_REJECTED : 'GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_REJECTED',

    SAVE_APPOINTMENT : 'SAVE_APPOINTMENT',
    SAVE_APPOINTMENT_PENDING : 'SAVE_APPOINTMENT_PENDING',
    SAVE_APPOINTMENT_FULFILLED : 'SAVE_APPOINTMENT_FULFILLED',
    SAVE_APPOINTMENT_FAILURE : 'SAVE_APPOINTMENT_FAILURE',
    SAVE_APPOINTMENT_REJECTED : 'SAVE_APPOINTMENT_REJECTED',

    UPDATE_SERVICE_APPOINTMENT_SERVICE : 'UPDATE_SERVICE_APPOINTMENT_SERVICE',
    UPDATE_SERVICE_APPOINTMENT_SERVICE_PENDING : 'UPDATE_SERVICE_APPOINTMENT_SERVICE_PENDING',
    UPDATE_SERVICE_APPOINTMENT_SERVICE_FULFILLED : 'UPDATE_SERVICE_APPOINTMENT_SERVICE_FULFILLED',
    UPDATE_SERVICE_APPOINTMENT_SERVICE_FAILURE : 'UPDATE_SERVICE_APPOINTMENT_SERVICE_FAILURE',
    UPDATE_SERVICE_APPOINTMENT_SERVICE_REJECTED : 'UPDATE_SERVICE_APPOINTMENT_SERVICE_REJECTED',

    SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT : 'SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT',
    SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_PENDING : 'SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_PENDING',
    SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_FULFILLED : 'SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_FULFILLED',
    SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_FAILURE : 'SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_FAILURE',
    SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_REJECTED : 'SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_REJECTED',


    GET_MAIN_APPOINTMENT : 'GET_MAIN_APPOINTMENT',
    GET_MAIN_APPOINTMENT_PENDING : 'GET_MAIN_APPOINTMENT_PENDING',
    GET_MAIN_APPOINTMENT_FULFILLED : 'GET_MAIN_APPOINTMENT_FULFILLED',
    GET_MAIN_APPOINTMENT_FAILURE : 'GET_MAIN_APPOINTMENT_FAILURE',
    GET_MAIN_APPOINTMENT_REJECTED : 'GET_MAIN_APPOINTMENT_REJECTED',

    GET_SERVICE_APPOINTMENT_SERVICE : 'GET_SERVICE_APPOINTMENT_SERVICE',
    GET_SERVICE_APPOINTMENT_SERVICE_PENDING : 'GET_SERVICE_APPOINTMENT_SERVICE_PENDING',
    GET_SERVICE_APPOINTMENT_SERVICE_FULFILLED : 'GET_SERVICE_APPOINTMENT_SERVICE_FULFILLED',
    GET_SERVICE_APPOINTMENT_SERVICE_FAILURE : 'GET_SERVICE_APPOINTMENT_SERVICE_FAILURE',
    GET_SERVICE_APPOINTMENT_SERVICE_REJECTED : 'GET_SERVICE_APPOINTMENT_SERVICE_REJECTED',

    SAVE_SERVICE_APPOINTMENT_FINISH : 'SAVE_SERVICE_APPOINTMENT_FINISH',
    SAVE_SERVICE_APPOINTMENT_FINISH_PENDING : 'SAVE_SERVICE_APPOINTMENT_FINISH_PENDING',
    SAVE_SERVICE_APPOINTMENT_FINISH_FULFILLED : 'SAVE_SERVICE_APPOINTMENT_FINISH_FULFILLED',
    SAVE_SERVICE_APPOINTMENT_FINISH_FAILURE : 'SAVE_SERVICE_APPOINTMENT_FINISH_FAILURE',
    SAVE_SERVICE_APPOINTMENT_FINISH_REJECTED : 'SAVE_SERVICE_APPOINTMENT_FINISH_REJECTED',

    LEAVE_SERVICE_TIME_SLOT : 'LEAVE_SERVICE_TIME_SLOT',
    LEAVE_SERVICE_TIME_SLOT_PENDING : 'LEAVE_SERVICE_TIME_SLOT_PENDING',
    LEAVE_SERVICE_TIME_SLOT_FULFILLED : 'LEAVE_SERVICE_TIME_SLOT_FULFILLED',
    LEAVE_SERVICE_TIME_SLOT_FAILURE : 'LEAVE_SERVICE_TIME_SLOT_FAILURE',
    LEAVE_SERVICE_TIME_SLOT_REJECTED : 'LEAVE_SERVICE_TIME_SLOT_REJECTED',

    SHORT_LEAVE_SERVICE_TIME_SLOT : 'SHORT_LEAVE_SERVICE_TIME_SLOT',
    SHORT_LEAVE_SERVICE_TIME_SLOT_PENDING : 'SHORT_LEAVE_SERVICE_TIME_SLOT_PENDING',
    SHORT_LEAVE_SERVICE_TIME_SLOT_FULFILLED : 'SHORT_LEAVE_SERVICE_TIME_SLOT_FULFILLED',
    SHORT_LEAVE_SERVICE_TIME_SLOT_FAILURE : 'SHORT_LEAVE_SERVICE_TIME_SLOT_FAILURE',
    SHORT_LEAVE_SERVICE_TIME_SLOT_REJECTED : 'SHORT_LEAVE_SERVICE_TIME_SLOT_REJECTED',

    TRANSFER_SERVICE_APPOINTMENTS : 'TRANSFER_SERVICE_APPOINTMENTS',
    TRANSFER_SERVICE_APPOINTMENTS_PENDING : 'TRANSFER_SERVICE_APPOINTMENTS_PENDING',
    TRANSFER_SERVICE_APPOINTMENTS_FULFILLED : 'TRANSFER_SERVICE_APPOINTMENTS_FULFILLED',
    TRANSFER_SERVICE_APPOINTMENTS_FAILURE : 'TRANSFER_SERVICE_APPOINTMENTS_FAILURE',
    TRANSFER_SERVICE_APPOINTMENTS_REJECTED : 'TRANSFER_SERVICE_APPOINTMENTS_REJECTED',


    SAVE_FALSE : 'SAVE_FALSE',
    CLEAR : 'CLEAR',
    SERVICE_APPOINTMENT_STATUS_CHANGE : 'SERVICE_APPOINTMENT_STATUS_CHANGE',

   

};
