import PropTypes from 'prop-types';
import { getPermissions } from "../pages/login_loader/redux/actions";
import { connect } from 'react-redux';
import { useEffect } from "react";

const ShowForPermissionComponent = (props) => {

    useEffect(() => {
        props.getPermissions()
     }, []);
     

    if(props?.permission?.split(",").every(e => props?.permissions?.includes(e) )){
        return props.children;
     }else{
        return  null;
     }
};

ShowForPermissionComponent.propTypes = {
    permission : PropTypes.string.isRequired,
    permissions: PropTypes.array.isRequired
};

function mapState(state) {
    const { permissions} = state.laravelLoginReducer;
    return { permissions};
}

const actionCreators = {
    getPermissions: getPermissions
}

export default connect(mapState, actionCreators)(ShowForPermissionComponent);

export function IsHavePermission(permission) {
    let permissions = JSON.parse(localStorage.getItem('permissions'));
    
    if(permission?.split(",").every(e => permissions?.includes(e) )){
        return true
    }else{
        return false;
    }
}

// const mapStateToProps = state => ({
//     laravelLoginReducer: store.laravelLoginReducer //<--- here you will get permissions for your user from Redux store
// });

// export const ShowForPermission = connect(mapStateToProps)(ShowForPermissionComponent);


// import PropTypes from 'prop-types';
// // import { connect } from 'react-redux';

// const ShowForPermissionComponent = (props) => {
//     const couldShow ='DELETEsasd';
//     return couldShow === 'DELETE' ? 'DELETE' : null;
// };

// ShowForPermissionComponent.propTypes = {
//     permission: PropTypes.string.isRequired,
//     userPermissions: PropTypes.array.isRequired
// };


// // const mapStateToProps = state => ({
// //     userPermissions: state.user.permission //<--- here you will get permissions for your user from Redux store
// // });

// export const ShowForPermission = (ShowForPermissionComponent);