import React, { Component } from 'react';
import { connect } from "react-redux";
import { backURL } from '../../connections/apptimedRequest.js';
import { laravelLogin } from "./redux/actions.js"
class LoginLoaderScreen extends Component {

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const did = params.get('did'); 
    const tid = params.get('tid'); 
    const sid = params.get('sid');
    const laravel_token = params.get('token');
    const redirect_to = params.get('redirect_to');  // Chashier
    const redirect = params.get('go_to'); // local use
    localStorage.setItem('did', did);
    localStorage.setItem('tid', tid);
    localStorage.setItem('sid', sid);
    localStorage.setItem('redirect', redirect);
    localStorage.setItem('laravel_token', laravel_token);
    localStorage.setItem('redirect_to', redirect_to);

    this.props.dispatch(laravelLogin(this.props.location.search));
  }
  componentDidUpdate() {
    if (this.props.laravelLoginReducer.fetched) {

      localStorage.setItem('user', JSON.stringify(this.props.laravelLoginReducer.user));
      localStorage.setItem('token', this.props.laravelLoginReducer.token);
      localStorage.setItem('permissions', JSON.stringify(this.props.laravelLoginReducer.permissions));
      localStorage.setItem('role', JSON.stringify(this.props.laravelLoginReducer.role));
      this.goToScreen();
    }
    if (this.props.laravelLoginReducer.fetchedError) {
      window.location.assign(`${backURL}login`);
      // window.location.assign('http://localhost:8000/login');
    }
  }

  goToScreen() {
    const redirect = localStorage.getItem('redirect');
    localStorage.removeItem('redirect');
    if(redirect && redirect !== ''){
      window.location.assign(redirect);
    }else{
        window.location.assign('/home');

    }
    // this.props.history.push({ pathname: '/home' });
  }

  render() {
    return (
      <div className="container-loader">
        <div className="item-1"></div>
        <div className="item-2"></div>
        <div className="item-3"></div>
        <div className="item-4"></div>
        <div className="item-5"></div>
      </div>
    );
  }
}
export default connect(
  (store) => {
    return {
      laravelLoginReducer: store.laravelLoginReducer
    }
  }
)(LoginLoaderScreen);

