
import {reportTemplateConstants} from './constants.js';
import {myServicePendingActionsConstants} from '../../../my_service_pending/redux/constants.js';
export default function reportTemplateReducer(state={
    error: null,
    loading: {
        getAll:false,
        patientSearch:false,
        referredDoctorSearch:false,
        referredCenterSearch:false,
    },
    fetched: false,
    // Add Patients
    patients: [],
    patient: {},
    saved: {},
    // appointment: {},
    partners: [],
    partner: {},
    guardians: [],
    referredDoctors: [],

    patientAppointment: {},
    patientType: [],
    doctors: [],
    
    doctorTimeSlots: [],
    doctorTimeSlotAppointments: [],
    canceledAppointment:{},
    isDeletedAppointment:false,
    cancelTimeSlot:false
    

  }, action) {

    state = {...state,error:{} ,saved:{reportTemplateSaved:false ,updateUsageTemplate:false} }

    switch (action.type) {

         //GET Doctor Time Slots for doctor id 
        case reportTemplateConstants.GET_ALL_TEST_TEMPLATE_PENDING: {
            return {...state,loading:{...state.loading ,getAll:true }}
        }  
        case  reportTemplateConstants.GET_ALL_TEST_TEMPLATE_FULFILLED: {
            return {...state,loading:{...state.loading ,getAll:false },allTestTemplate:action?.payload?.data}
        }    
        case reportTemplateConstants.GET_ALL_TEST_TEMPLATE_FAILURE:  {
            return {...state, loading:{...state.loading ,getAll:false },error:action.error}
        }
        case reportTemplateConstants.GET_ALL_TEST_TEMPLATE_REJECTED:  {
            return {...state, loading:{...state.loading ,getAll:false },error:action.error}
        }


    
        case reportTemplateConstants.GET_PATIENTS_FOR_ADD_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,patientSearch:true }}
        }  
        case  reportTemplateConstants.GET_PATIENTS_FOR_ADD_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,patientSearch:false } , patients:action?.payload?.data?.data}
        }    
        case reportTemplateConstants.GET_PATIENTS_FOR_ADD_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,patientSearch:false } ,error:action.error}
        }
        case reportTemplateConstants.GET_PATIENTS_FOR_ADD_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,patientSearch:false } ,error:action.error}
        }
    
        case reportTemplateConstants.SAVE_REPORT_TEMPLATE_PENDING: {
            return {...state,loading:{...state.loading ,reportTemplateSaved:true }}
        }  
        case  reportTemplateConstants.SAVE_REPORT_TEMPLATE_FULFILLED: {
            return {...state,loading:{...state.loading ,reportTemplateSaved:false } ,saved:{...state.saved, reportTemplateSaved:true} , reportTemplate:action?.payload?.data?.data}
        }    
        case reportTemplateConstants.SAVE_REPORT_TEMPLATE_FAILURE:  {
            return {...state, loading:{...state.loading ,reportTemplateSaved:false } ,error:action.error}
        }
        case reportTemplateConstants.SAVE_REPORT_TEMPLATE_REJECTED:  {
            return {...state, loading:{...state.loading ,reportTemplateSaved:false } ,error:action.payload}
        }
    
        case reportTemplateConstants.UPDATE_USAGE_TEMPLATE_PENDING: {
            return {...state,loading:{...state.loading ,updateUsageTemplate:true }}
        }  
        case  reportTemplateConstants.UPDATE_USAGE_TEMPLATE_FULFILLED: {
            return {...state,loading:{...state.loading ,updateUsageTemplate:false } ,saved:{...state.saved, updateUsageTemplate:true} , usageTemplate:action?.payload?.data?.data}
        }    
        case reportTemplateConstants.UPDATE_USAGE_TEMPLATE_FAILURE:  {
            return {...state, loading:{...state.loading ,updateUsageTemplate:false } ,error:action.error}
        }
        case reportTemplateConstants.UPDATE_USAGE_TEMPLATE_REJECTED:  {
            return {...state, loading:{...state.loading ,updateUsageTemplate:false } ,error:action.payload}
        }
    
        case reportTemplateConstants.GET_ONE_TEST_REPORT_TEMPLATE_PENDING: {
            return {...state,loading:{...state.loading ,getOneTestTemplateTemplate:true }}
        }  
        case  reportTemplateConstants.GET_ONE_TEST_REPORT_TEMPLATE_FULFILLED: {
            return {...state,loading:{...state.loading ,getOneTestTemplateTemplate:false } , oneTestReportTemplate:action?.payload?.data?.data}
        }    
        case reportTemplateConstants.GET_ONE_TEST_REPORT_TEMPLATE_FAILURE:  {
            return {...state, loading:{...state.loading ,getOneTestTemplateTemplate:false } ,error:action.error}
        }
        case reportTemplateConstants.GET_ONE_TEST_REPORT_TEMPLATE_REJECTED:  {
            return {...state, loading:{...state.loading ,getOneTestTemplateTemplate:false } ,error:action.payload}
        }


        case reportTemplateConstants.GET_ONE_TEST_SAMPLE_TEMPLATES_BY_TEST_ID_PENDING: {
            return {...state,loading:{...state.loading ,getOneTestTemplateTestId:true }}
        }  
        case  reportTemplateConstants.GET_ONE_TEST_SAMPLE_TEMPLATES_BY_TEST_ID_FULFILLED: {
            return {...state,loading:{...state.loading ,getOneTestTemplateTestId:false } , oneTestReportTemplateTestId:action?.payload?.data?.data}
        }    
        case reportTemplateConstants.GET_ONE_TEST_SAMPLE_TEMPLATES_BY_TEST_ID_FAILURE:  {
            return {...state, loading:{...state.loading ,getOneTestTemplateTestId:false } ,error:action.error}
        }
        case reportTemplateConstants.GET_ONE_TEST_SAMPLE_TEMPLATES_BY_TEST_ID_REJECTED:  {
            return {...state, loading:{...state.loading ,getOneTestTemplateTestId:false } ,error:action.payload}
        }
    
        case reportTemplateConstants.GET_GUARDIAN_FOR_ADD_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,guardianSearch:true }}
        }  
        case  reportTemplateConstants.GET_GUARDIAN_FOR_ADD_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,guardianSearch:false } , guardians:action?.payload?.data?.data}
        }    
        case reportTemplateConstants.GET_GUARDIAN_FOR_ADD_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,guardianSearch:false } ,error:action.error}
        }
        case reportTemplateConstants.GET_GUARDIAN_FOR_ADD_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,guardianSearch:false } ,error:action.error}
        }
    
        case reportTemplateConstants.GET_REFERRED_DOCTOR_ADD_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,referredDoctorSearch:true }}
        }  
        case  reportTemplateConstants.GET_REFERRED_DOCTOR_ADD_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,referredDoctorSearch:false } , referredDoctors:action?.payload?.data?.data}
        }    
        case reportTemplateConstants.GET_REFERRED_DOCTOR_ADD_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,referredDoctorSearch:false } ,error:action.error}
        }
        case reportTemplateConstants.GET_REFERRED_DOCTOR_ADD_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,referredDoctorSearch:false } ,error:action.error}
        }
    
        case reportTemplateConstants.GET_REFERRED_CENTER_ADD_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,referredCenterSearch:true }}
        }  
        case  reportTemplateConstants.GET_REFERRED_CENTER_ADD_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,referredCenterSearch:false } , referredCenters:action?.payload?.data?.data}
        }    
        case reportTemplateConstants.GET_REFERRED_CENTER_ADD_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,referredCenterSearch:false } ,error:action.error}
        }
        case reportTemplateConstants.GET_REFERRED_CENTER_ADD_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,referredCenterSearch:false } ,error:action.error}
        }


        case reportTemplateConstants.GET_ALL_SERVICES_ADD_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,servicesGetAll:true }}
        }  
        case  reportTemplateConstants.GET_ALL_SERVICES_ADD_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,servicesGetAll:false } , services:action?.payload?.data?.data}
        }    
        case reportTemplateConstants.GET_ALL_SERVICES_ADD_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,servicesGetAll:false } ,error:action.error}
        }
        case reportTemplateConstants.GET_ALL_SERVICES_ADD_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,servicesGetAll:false } ,error:action.error}
        }

        case reportTemplateConstants.GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,servicesGetAllTimeSlot:true }}
        }  
        case  reportTemplateConstants.GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,servicesGetAllTimeSlot:false } , serviceTimeSlots:action?.payload?.data?.data}
        }    
        case reportTemplateConstants.GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,servicesGetAllTimeSlot:false } ,error:action.error}
        }
        case reportTemplateConstants.GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,servicesGetAllTimeSlot:false } ,error:action.error}
        }



        case reportTemplateConstants.GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,servicesGetAllTimeSlotAppointment:true }}
        }  
        case  reportTemplateConstants.GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,servicesGetAllTimeSlotAppointment:false } , serviceTimeSlotsAppointment:action?.payload?.data?.data}
        }    
        case reportTemplateConstants.GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,servicesGetAllTimeSlotAppointment:false } ,error:action.error}
        }
        case reportTemplateConstants.GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,servicesGetAllTimeSlotAppointment:false } ,error:action.error}
        }



        case reportTemplateConstants.SAVE_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,appointmentSave:true } , saved:{...state.saved , appointmentSave:false}}
        }  
        case  reportTemplateConstants.SAVE_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,appointmentSave:false } , saved:{...state.saved , appointmentSave:true} , serviceAppointment:action?.payload?.data?.data}
        }    
        case reportTemplateConstants.SAVE_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,appointmentSave:false } , saved:{...state.saved , appointmentSave:false} ,error:action.error}
        }
        case reportTemplateConstants.SAVE_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,appointmentSave:false } , saved:{...state.saved , appointmentSave:false} ,error:action.payload}
        }

        case reportTemplateConstants.GET_MAIN_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,appointmentGetMain:true } , saved:{...state.saved , appointmentGetMain:false}}
        }  
        case  reportTemplateConstants.GET_MAIN_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,appointmentGetMain:false } , saved:{...state.saved , appointmentGetMain:true} , serviceMainAppointment:action?.payload?.data?.data}
        }    
        case reportTemplateConstants.GET_MAIN_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,appointmentGetMain:false } , saved:{...state.saved , appointmentGetMain:false} ,error:action.error}
        }
        case reportTemplateConstants.GET_MAIN_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,appointmentGetMain:false } , saved:{...state.saved , appointmentGetMain:false} ,error:action.payload}
        }


        case reportTemplateConstants.GET_SERVICE_APPOINTMENT_SERVICE_PENDING: {
            return {...state,loading:{...state.loading ,appointmentGetService:true } , saved:{...state.saved , appointmentGetService:false}}
        }  
        case  reportTemplateConstants.GET_SERVICE_APPOINTMENT_SERVICE_FULFILLED: {
            return {...state,loading:{...state.loading ,appointmentGetService:false } , saved:{...state.saved , appointmentGetService:true} , serviceAppointmentService:action?.payload?.data?.data}
        }    
        case reportTemplateConstants.GET_SERVICE_APPOINTMENT_SERVICE_FAILURE:  {
            return {...state, loading:{...state.loading ,appointmentGetService:false } , saved:{...state.saved , appointmentGetService:false} ,error:action.error}
        }
        case reportTemplateConstants.GET_SERVICE_APPOINTMENT_SERVICE_REJECTED:  {
            return {...state, loading:{...state.loading ,appointmentGetService:false } , saved:{...state.saved , appointmentGetService:false} ,error:action.payload}
        }

        case reportTemplateConstants.GET_ALL_SERVICE_TECHNICIAN_PENDING : {
            return {...state,loading:{...state.loading , getAllServicesTechnician:true },}
        }  
        case  reportTemplateConstants.GET_ALL_SERVICE_TECHNICIAN_FULFILLED: {
            return {...state,loading:{...state.loading , getAllServicesTechnician:false } , serviceTechnician:action?.payload?.data?.data}
        }    
        case reportTemplateConstants.GET_ALL_SERVICE_TECHNICIAN_FAILURE :  {
            return {...state, loading:{...state.loading , getAllServicesTechnician:false } ,error:action.error}
        }
        case reportTemplateConstants.GET_ALL_SERVICE_TECHNICIAN_REJECTED :  {
            return {...state, loading:{...state.loading , getAllServicesTechnician:false } ,error:action.error}
        }
        
        
        case reportTemplateConstants.GET_ALL_SERVICE_ITEMS_PENDING : {
            return {...state,loading:{...state.loading , getAllServicesItems:true },}
        }  
        case  reportTemplateConstants.GET_ALL_SERVICE_ITEMS_FULFILLED: {
            return {...state,loading:{...state.loading , getAllServicesItems:false } , serviceItems:action?.payload?.data?.data}
        }    
        case reportTemplateConstants.GET_ALL_SERVICE_ITEMS_FAILURE :  {
            return {...state, loading:{...state.loading , getAllServicesItems:false } ,error:action.error}
        }
        case reportTemplateConstants.GET_ALL_SERVICE_ITEMS_REJECTED :  {
            return {...state, loading:{...state.loading , getAllServicesItems:false } ,error:action.error}
        }
        case reportTemplateConstants.GET_ALL_SERVICE_MACHINES_PENDING : {
            return {...state,loading:{...state.loading , getAllServicesMachines:true },}
        }  
        case  reportTemplateConstants.GET_ALL_SERVICE_MACHINES_FULFILLED: {
            return {...state,loading:{...state.loading , getAllServicesMachines:false } , serviceMachines:action?.payload?.data?.data}
        }    
        case reportTemplateConstants.GET_ALL_SERVICE_MACHINES_FAILURE :  {
            return {...state, loading:{...state.loading , getAllServicesMachines:false } ,error:action.error}
        }
        case reportTemplateConstants.GET_ALL_SERVICE_MACHINES_REJECTED :  {
            return {...state, loading:{...state.loading , getAllServicesMachines:false } ,error:action.error}
        }


        case reportTemplateConstants.SAVE_SERVICE_HR_USAGE_PENDING : {
            return {...state,loading:{...state.loading , saveSaveServiceHr:true },saved:{...state.saved , saveSaveServiceHr:false } ,}
        }  
        case  reportTemplateConstants.SAVE_SERVICE_HR_USAGE_FULFILLED: {
            return {...state,loading:{...state.loading , saveSaveServiceHr:false } ,saved:{...state.saved , saveSaveServiceHr:true } , serviceHrUsage:action?.payload?.data?.data}
        }    
        case reportTemplateConstants.SAVE_SERVICE_HR_USAGE_FAILURE :  {
            return {...state, loading:{...state.loading , saveSaveServiceHr:false } ,saved:{...state.saved , saveSaveServiceHr:false } ,error:action.error}
        }
        case reportTemplateConstants.SAVE_SERVICE_HR_USAGE_REJECTED :  {
            return {...state, loading:{...state.loading , saveSaveServiceHr:false } ,saved:{...state.saved , saveSaveServiceHr:false } ,error:action.payload}
        }

        case reportTemplateConstants.SAVE_SERVICE_MACHINES_PENDING : {
            return {...state,loading:{...state.loading , saveSaveServiceMachine:true },saved:{...state.saved , saveSaveServiceMachine:false } ,}
        }  
        case  reportTemplateConstants.SAVE_SERVICE_MACHINES_FULFILLED: {
            return {...state,loading:{...state.loading , saveSaveServiceMachine:false } ,saved:{...state.saved , saveSaveServiceMachine:true } , serviceMachineUsage:action?.payload?.data?.data}
        }    
        case reportTemplateConstants.SAVE_SERVICE_MACHINES_FAILURE :  {
            return {...state, loading:{...state.loading , saveSaveServiceMachine:false } ,saved:{...state.saved , saveSaveServiceMachine:false } ,error:action.error}
        }
        case reportTemplateConstants.SAVE_SERVICE_MACHINES_REJECTED :  {
            return {...state, loading:{...state.loading , saveSaveServiceMachine:false } ,saved:{...state.saved , saveSaveServiceMachine:false } ,error:action.payload}
        }

        case reportTemplateConstants.SAVE_SERVICE_ITEM_USAGE_PENDING : {
            return {...state,loading:{...state.loading , saveServiceItem:true },saved:{...state.saved , saveServiceItem:false } ,}
        }  
        case  reportTemplateConstants.SAVE_SERVICE_ITEM_USAGE_FULFILLED: {
            return {...state,loading:{...state.loading , saveServiceItem:false } ,saved:{...state.saved , saveServiceItem:true } , serviceItemUsage:action?.payload?.data?.data}
        }    
        case reportTemplateConstants.SAVE_SERVICE_ITEM_USAGE_FAILURE :  {
            return {...state, loading:{...state.loading , saveServiceItem:false } ,saved:{...state.saved , saveServiceItem:false } ,error:action.error}
        }
        case reportTemplateConstants.SAVE_SERVICE_ITEM_USAGE_REJECTED :  {
            return {...state, loading:{...state.loading , saveServiceItem:false } ,saved:{...state.saved , saveServiceItem:false } ,error:action.payload}
        }

        case reportTemplateConstants.SAVE_SERVICE_REPORT_PENDING : {
            return {...state,loading:{...state.loading , saveServiceReport:true },saved:{...state.saved , saveServiceReport:false } ,}
        }  
        case  reportTemplateConstants.SAVE_SERVICE_REPORT_FULFILLED: {
            return {...state,loading:{...state.loading , saveServiceReport:false } ,saved:{...state.saved , saveServiceReport:true } , serviceReport:action?.payload?.data?.data}
        }    
        case reportTemplateConstants.SAVE_SERVICE_REPORT_FAILURE :  {
            return {...state, loading:{...state.loading , saveServiceReport:false } ,saved:{...state.saved , saveServiceReport:false } ,error:action.error}
        }
        case reportTemplateConstants.SAVE_SERVICE_REPORT_REJECTED :  {
            return {...state, loading:{...state.loading , saveServiceReport:false } ,saved:{...state.saved , saveServiceReport:false } ,error:action.payload}
        }



        case reportTemplateConstants.SAVE_FALSE :  {
            return {...state,loading:{...state.loading , getOneTestTemplateTestId:false },saved:{...state.saved , 
                     getAllServicesTechnician:false ,appointmentSave:false, 
                     appointmentGetMain:false, appointmentGetService:false,
                     saveServiceItem:false,saveSaveServiceMachine:false,
                     saveServiceReport:false,
                      saveSaveServiceHr:false, } 
                      ,error:{}}
        }

        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_START_PENDING: {
            return {...state,loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceStart:false } ,user: action.payload,saved:false}
        }  
        case  myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_START_FULFILLED: {
            return {...state,loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceStart:true }, myServiceStart:action?.payload?.data?.data}
        }    
        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_START_FAILURE:  {
            return {...state, loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceStart:false },error:action.error}
        }
        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_START_REJECTED:  {
            return {...state, loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceStart:false  },error:action.payload}
        }

        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_END_PENDING: {
            return {...state,loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceEnd:false } ,user: action.payload,saved:false}
        }  
        case  myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_END_FULFILLED: {
            return {...state,loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceEnd:true }, myServiceEnd:action?.payload?.data?.data}
        }    
        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_END_FAILURE:  {
            return {...state, loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceEnd:false },error:action.error}
        }
        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_END_REJECTED:  {
            return {...state, loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceEnd:false  },error:action.payload}
        }

        case reportTemplateConstants.SAVE_SERVICE_APPOINTMENT_FINISH_PENDING : {
            return {...state,loading:{...state.loading , saveServiceFinish:true },saved:{...state.saved , saveServiceFinish:false } ,}
        }  
        case  reportTemplateConstants.SAVE_SERVICE_APPOINTMENT_FINISH_FULFILLED: {
            return {...state,loading:{...state.loading , saveServiceFinish:false } ,saved:{...state.saved , saveServiceFinish:true } , serviceFinish:action?.payload?.data?.data}
        }    
        case reportTemplateConstants.SAVE_SERVICE_APPOINTMENT_FINISH_FAILURE :  {
            return {...state, loading:{...state.loading , saveServiceFinish:false } ,saved:{...state.saved , saveServiceFinish:false } ,error:action.error}
        }
        case reportTemplateConstants.SAVE_SERVICE_APPOINTMENT_FINISH_REJECTED :  {
            return {...state, loading:{...state.loading , saveServiceFinish:false } ,saved:{...state.saved , saveServiceFinish:false } ,error:action.payload}
        }
        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_PENDING: {
            return {...state,loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceDelivered:false } ,user: action.payload,saved:false}
        }  
        case  myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_FULFILLED: {
            return {...state,loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceDelivered:true }, myserviceDelivered:action?.payload?.data?.data}
        }    
        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_FAILURE:  {
            return {...state, loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceDelivered:false },error:action.error}
        }
        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_REJECTED:  {
            return {...state, loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceDelivered:false  },error:action.payload}
        }



        case reportTemplateConstants.SERVICE_APPOINTMENT_STATUS_CHANGE :  {
            return {...state, loading:{...state.loading , saveServiceFinish:false,getAll:false,
                patientSearch:false,
                guardianSearch:false,
                referredDoctorSearch:false,
                referredCenterSearch:false,
                servicesGetAll:false,
                servicesGetAllTimeSlot:false,
                servicesGetAllTimeSlotAppointment:false,
                appointmentSave:false,
                appointmentGetMain:false,
                appointmentGetService:false,
                getAllServicesTechnician:false,
                getAllServicesItems:false,
                getAllServicesMachines:false,
                saveSaveServiceHr:false,
                saveSaveServiceMachine:false,
                reportTemplateSaved:false,
                saveServiceItem:false,
                saveServiceReport:false,
                saveAppointmentService:false, } 
            ,saved:{...state.saved , saveServiceFinish:false,appointmentSave:false,
                appointmentGetMain:false,
                appointmentGetService:false,
                getAllServicesTechnician:false,
                getAllServicesItems:false,
                getAllServicesMachines:false,
                saveSaveServiceHr:false,
                reportTemplateSaved:false,
                saveSaveServiceMachine:false,
                saveServiceItem:false,
                saveServiceReport:false,
                serviceStart:false,
                serviceDelivered:false,
                serviceEnd:false, } 
            
            }
        }

        
        default :
            return state;
    }

}
