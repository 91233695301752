import { serviceScheduleConstants } from "./constants"

export default function serviceScheduleReducer(state={
    loading:false,
    fetched:false,
    updateLoading:false,
    updateFetched:false,
    createLoading:false,
    createFetched:false,
    statusUpdated:false,
    error: null,
    serviceAvailability: {},
    serviceAvailabilities: [],

  }, action) {

      state = {...state, fetched: false , createFetched:false , createLoading:false,statusUpdated:false};
    switch (action.type) {


        case serviceScheduleConstants.FETCH_ALL_AVAILABLITIES_PENDING : {
            return {...state,loading:true, fetched: false, user: action.payload}
        }  
        case  serviceScheduleConstants.FETCH_ALL_AVAILABLITIES_FULFILLED: {
            return {...state,loading: false,fetched: true, serviceAvailabilities:action?.payload?.data?.data}
        }    
        case serviceScheduleConstants.FETCH_ALL_AVAILABLITIES_FAILURE :  {
            return {...state, loading: false, fetched: false, error:action.error}
        }

        case serviceScheduleConstants.CREATE_SERVICE_AVAILABILITY_PENDING : {
            return {...state,createLoading:true,createFetched: false, user: action.payload}
        }  
        case  serviceScheduleConstants.CREATE_SERVICE_AVAILABILITY_FULFILLED: {
            return {...state,createLoading: false,createFetched: true, serviceAvailability:action?.payload?.data?.data}
        }    
        case serviceScheduleConstants.CREATE_SERVICE_AVAILABILITY_FAILURE :  {
            return {...state, createLoading: false,createFetched: false,error:action.error}
        }
        case serviceScheduleConstants.CREATE_SERVICE_AVAILABILITY_REJECTED :  {
            return {...state, createLoading: false,createFetched: false,error:action?.payload?.error}
        }
       
        case serviceScheduleConstants.FETCH_SERVICE_AVAILABLITY_PENDING : {
            return {...state,loading:true,fetched: false, user: action.payload}
        }  
        case  serviceScheduleConstants.FETCH_SERVICE_AVAILABLITY_FULFILLED: {
            return {...state,loading: false,fetched: true, serviceAvailability:action?.payload?.data?.data}
        }    
        case serviceScheduleConstants.FETCH_SERVICE_AVAILABLITY_FAILURE :  {
            return {...state, loading: false,fetched: false,error:action.error}
        }

        case serviceScheduleConstants.CHANGE_STATUS_OF_SERVICE_AVAILABLITY_PENDING : {
            return {...state,statusUpdated: false, user: action.payload}
        }  
        case  serviceScheduleConstants.CHANGE_STATUS_OF_SERVICE_AVAILABLITY_FULFILLED: {
            return {...state,statusUpdated: true, updateService:action?.payload?.data?.data}
        }    
        case serviceScheduleConstants.CHANGE_STATUS_OF_SERVICE_AVAILABLITY_FAILURE :  {
            return {...state, statusUpdated: false,error:action.error}
        }
        case serviceScheduleConstants.CHANGE_STATUS_OF_SERVICE_AVAILABLITY_REJECTED :  {
            return {...state, statusUpdated: false,error:action.error}
        }

        case serviceScheduleConstants.UPDATE_SERVICE_AVAILABLITY_PENDING : {
            return {...state,updateLoading:true, updateFetched: false, user: action.payload}
        }  
        case  serviceScheduleConstants.UPDATE_SERVICE_AVAILABLITY_FULFILLED: {
            return {...state,updateLoading: false,updateFetched: true, serviceAvailability:action?.payload?.data?.data}
        }    
        case serviceScheduleConstants.UPDATE_SERVICE_AVAILABLITY_FAILURE :  {
            return {...state, updateLoading: false,updateFetched: false, error:action.error}
        }

        default :
            return state;
    }

}
