
import {testTypesSelectActionsConstants} from './constants.js';
export default function testTypesSelectReducer(state={
    myServices: [],
    fetched: false,
    loadingSearch:false,
    loading:false,
    saved:false,
  }, action) {

    state = {...state,saved:false}
    switch (action.type) {



        // Add Patients
        case testTypesSelectActionsConstants.GET_SEARCH_TEST_TYPE_PENDING: {
            return {...state,loading:{...state.loading,testLoading:true}, testType: action.payload,saved:false}
        }  
        case  testTypesSelectActionsConstants.GET_SEARCH_TEST_TYPE_FULFILLED: {
            return {...state,loading:{...state.loading,testLoading:false}, testTypes:action?.payload?.data?.data}
        }    
        case testTypesSelectActionsConstants.GET_SEARCH_TEST_TYPE_FAILURE:  {
            return {...state, loading:{...state.loading,testLoading:false},error:action.error}
        }
        case testTypesSelectActionsConstants.GET_SEARCH_TEST_TYPE_REJECTED:  {
            return {...state, loading:{...state.loading,testLoading:false},error:action.payload}
        }



        default :
            return state;
    }

}
