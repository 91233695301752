export const usageTemplateConstants = {




    GET_ALL_SERVICE_TYPE_USAGE_TEMPLATE : 'GET_ALL_SERVICE_TYPE_USAGE_TEMPLATE',
    GET_ALL_SERVICE_TYPE_USAGE_TEMPLATE_PENDING : 'GET_ALL_SERVICE_TYPE_USAGE_TEMPLATE_PENDING',
    GET_ALL_SERVICE_TYPE_USAGE_TEMPLATE_FULFILLED : 'GET_ALL_SERVICE_TYPE_USAGE_TEMPLATE_FULFILLED',
    GET_ALL_SERVICE_TYPE_USAGE_TEMPLATE_FAILURE : 'GET_ALL_SERVICE_TYPE_USAGE_TEMPLATE_FAILURE',
    GET_ALL_SERVICE_TYPE_USAGE_TEMPLATE_REJECTED : 'GET_ALL_SERVICE_TYPE_USAGE_TEMPLATE_REJECTED',

    GET_ALL_SERVICE_TECHNICIAN : 'GET_ALL_SERVICE_TECHNICIAN',
    GET_ALL_SERVICE_TECHNICIAN_PENDING : 'GET_ALL_SERVICE_TECHNICIAN_PENDING',
    GET_ALL_SERVICE_TECHNICIAN_FULFILLED : 'GET_ALL_SERVICE_TECHNICIAN_FULFILLED',
    GET_ALL_SERVICE_TECHNICIAN_FAILURE : 'GET_ALL_SERVICE_TECHNICIAN_FAILURE',
    GET_ALL_SERVICE_TECHNICIAN_REJECTED : 'GET_ALL_SERVICE_TECHNICIAN_REJECTED',

    GET_ONE_USAGE_TEMPLATE : 'GET_ONE_USAGE_TEMPLATE',
    GET_ONE_USAGE_TEMPLATE_PENDING : 'GET_ONE_USAGE_TEMPLATE_PENDING',
    GET_ONE_USAGE_TEMPLATE_FULFILLED : 'GET_ONE_USAGE_TEMPLATE_FULFILLED',
    GET_ONE_USAGE_TEMPLATE_FAILURE : 'GET_ONE_USAGE_TEMPLATE_FAILURE',
    GET_ONE_USAGE_TEMPLATE_REJECTED : 'GET_ONE_USAGE_TEMPLATE_REJECTED',

    SAVE_USAGE_TEMPLATE : 'SAVE_USAGE_TEMPLATE',
    SAVE_USAGE_TEMPLATE_PENDING : 'SAVE_USAGE_TEMPLATE_PENDING',
    SAVE_USAGE_TEMPLATE_FULFILLED : 'SAVE_USAGE_TEMPLATE_FULFILLED',
    SAVE_USAGE_TEMPLATE_FAILURE : 'SAVE_USAGE_TEMPLATE_FAILURE',
    SAVE_USAGE_TEMPLATE_REJECTED : 'SAVE_USAGE_TEMPLATE_REJECTED',


    UPDATE_USAGE_TEMPLATE : 'UPDATE_USAGE_TEMPLATE',
    UPDATE_USAGE_TEMPLATE_PENDING : 'UPDATE_USAGE_TEMPLATE_PENDING',
    UPDATE_USAGE_TEMPLATE_FULFILLED : 'UPDATE_USAGE_TEMPLATE_FULFILLED',
    UPDATE_USAGE_TEMPLATE_FAILURE : 'UPDATE_USAGE_TEMPLATE_FAILURE',
    UPDATE_USAGE_TEMPLATE_REJECTED : 'UPDATE_USAGE_TEMPLATE_REJECTED',

    GET_ALL_SERVICE_MACHINES : 'GET_ALL_SERVICE_MACHINES',
    GET_ALL_SERVICE_MACHINES_PENDING : 'GET_ALL_SERVICE_MACHINES_PENDING',
    GET_ALL_SERVICE_MACHINES_FULFILLED : 'GET_ALL_SERVICE_MACHINES_FULFILLED',
    GET_ALL_SERVICE_MACHINES_FAILURE : 'GET_ALL_SERVICE_MACHINES_FAILURE',
    GET_ALL_SERVICE_MACHINES_REJECTED : 'GET_ALL_SERVICE_MACHINES_REJECTED',

    SAVE_SERVICE_HR_USAGE : 'SAVE_SERVICE_HR_USAGE',
    SAVE_SERVICE_HR_USAGE_PENDING : 'SAVE_SERVICE_HR_USAGE_PENDING',
    SAVE_SERVICE_HR_USAGE_FULFILLED : 'SAVE_SERVICE_HR_USAGE_FULFILLED',
    SAVE_SERVICE_HR_USAGE_FAILURE : 'SAVE_SERVICE_HR_USAGE_FAILURE',
    SAVE_SERVICE_HR_USAGE_REJECTED : 'SAVE_SERVICE_HR_USAGE_REJECTED',


    SAVE_SERVICE_ITEM_USAGE : 'SAVE_SERVICE_ITEM_USAGE',
    SAVE_SERVICE_ITEM_USAGE_PENDING : 'SAVE_SERVICE_ITEM_USAGE_PENDING',
    SAVE_SERVICE_ITEM_USAGE_FULFILLED : 'SAVE_SERVICE_ITEM_USAGE_FULFILLED',
    SAVE_SERVICE_ITEM_USAGE_FAILURE : 'SAVE_SERVICE_ITEM_USAGE_FAILURE',
    SAVE_SERVICE_ITEM_USAGE_REJECTED : 'SAVE_SERVICE_ITEM_USAGE_REJECTED',


    SAVE_SERVICE_MACHINES : 'SAVE_SERVICE_MACHINES',
    SAVE_SERVICE_MACHINES_PENDING : 'SAVE_SERVICE_MACHINES_PENDING',
    SAVE_SERVICE_MACHINES_FULFILLED : 'SAVE_SERVICE_MACHINES_FULFILLED',
    SAVE_SERVICE_MACHINES_FAILURE : 'SAVE_SERVICE_MACHINES_FAILURE',
    SAVE_SERVICE_MACHINES_REJECTED : 'SAVE_SERVICE_MACHINES_REJECTED',

    SAVE_SERVICE_REPORT : 'SAVE_SERVICE_REPORT',
    SAVE_SERVICE_REPORT_PENDING : 'SAVE_SERVICE_REPORT_PENDING',
    SAVE_SERVICE_REPORT_FULFILLED : 'SAVE_SERVICE_REPORT_FULFILLED',
    SAVE_SERVICE_REPORT_FAILURE : 'SAVE_SERVICE_REPORT_FAILURE',
    SAVE_SERVICE_REPORT_REJECTED : 'SAVE_SERVICE_REPORT_REJECTED',


    GET_PATIENTS_FOR_ADD_APPOINTMENT : 'GET_PATIENTS_FOR_ADD_APPOINTMENT',
    GET_PATIENTS_FOR_ADD_APPOINTMENT_PENDING : 'GET_PATIENTS_FOR_ADD_APPOINTMENT_PENDING',
    GET_PATIENTS_FOR_ADD_APPOINTMENT_FULFILLED : 'GET_PATIENTS_FOR_ADD_APPOINTMENT_FULFILLED',
    GET_PATIENTS_FOR_ADD_APPOINTMENT_FAILURE : 'GET_PATIENTS_FOR_ADD_APPOINTMENT_FAILURE',
    GET_PATIENTS_FOR_ADD_APPOINTMENT_REJECTED : 'GET_PATIENTS_FOR_ADD_APPOINTMENT_REJECTED',

    GET_GUARDIAN_FOR_ADD_APPOINTMENT : 'GET_GUARDIAN_FOR_ADD_APPOINTMENT',
    GET_GUARDIAN_FOR_ADD_APPOINTMENT_PENDING : 'GET_GUARDIAN_FOR_ADD_APPOINTMENT_PENDING',
    GET_GUARDIAN_FOR_ADD_APPOINTMENT_FULFILLED : 'GET_GUARDIAN_FOR_ADD_APPOINTMENT_FULFILLED',
    GET_GUARDIAN_FOR_ADD_APPOINTMENT_FAILURE : 'GET_GUARDIAN_FOR_ADD_APPOINTMENT_FAILURE',
    GET_GUARDIAN_FOR_ADD_APPOINTMENT_REJECTED : 'GET_GUARDIAN_FOR_ADD_APPOINTMENT_REJECTED',

    GET_REFERRED_DOCTOR_ADD_APPOINTMENT : 'GET_REFERRED_DOCTOR_ADD_APPOINTMENT',
    GET_REFERRED_DOCTOR_ADD_APPOINTMENT_PENDING : 'GET_REFERRED_DOCTOR_ADD_APPOINTMENT_PENDING',
    GET_REFERRED_DOCTOR_ADD_APPOINTMENT_FULFILLED : 'GET_REFERRED_DOCTOR_ADD_APPOINTMENT_FULFILLED',
    GET_REFERRED_DOCTOR_ADD_APPOINTMENT_FAILURE : 'GET_REFERRED_DOCTOR_ADD_APPOINTMENT_FAILURE',
    GET_REFERRED_DOCTOR_ADD_APPOINTMENT_REJECTED : 'GET_REFERRED_DOCTOR_ADD_APPOINTMENT_REJECTED',

    GET_REFERRED_CENTER_ADD_APPOINTMENT : 'GET_REFERRED_CENTER_ADD_APPOINTMENT',
    GET_REFERRED_CENTER_ADD_APPOINTMENT_PENDING : 'GET_REFERRED_CENTER_ADD_APPOINTMENT_PENDING',
    GET_REFERRED_CENTER_ADD_APPOINTMENT_FULFILLED : 'GET_REFERRED_CENTER_ADD_APPOINTMENT_FULFILLED',
    GET_REFERRED_CENTER_ADD_APPOINTMENT_FAILURE : 'GET_REFERRED_CENTER_ADD_APPOINTMENT_FAILURE',
    GET_REFERRED_CENTER_ADD_APPOINTMENT_REJECTED : 'GET_REFERRED_CENTER_ADD_APPOINTMENT_REJECTED',

    GET_ALL_SERVICES_ADD_APPOINTMENT : 'GET_ALL_SERVICES_ADD_APPOINTMENT',
    GET_ALL_SERVICES_ADD_APPOINTMENT_PENDING : 'GET_ALL_SERVICES_ADD_APPOINTMENT_PENDING',
    GET_ALL_SERVICES_ADD_APPOINTMENT_FULFILLED : 'GET_ALL_SERVICES_ADD_APPOINTMENT_FULFILLED',
    GET_ALL_SERVICES_ADD_APPOINTMENT_FAILURE : 'GET_ALL_SERVICES_ADD_APPOINTMENT_FAILURE',
    GET_ALL_SERVICES_ADD_APPOINTMENT_REJECTED : 'GET_ALL_SERVICES_ADD_APPOINTMENT_REJECTED',

    GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT : 'GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT',
    GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT_PENDING : 'GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT_PENDING',
    GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT_FULFILLED : 'GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT_FULFILLED',
    GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT_FAILURE : 'GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT_FAILURE',
    GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT_REJECTED : 'GET_SERVICES_TIME_SLOTS_ADD_APPOINTMENT_REJECTED',

    GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT : 'GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT',
    GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_PENDING : 'GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_PENDING',
    GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_FULFILLED : 'GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_FULFILLED',
    GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_FAILURE : 'GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_FAILURE',
    GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_REJECTED : 'GET_SERVICES_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_REJECTED',

    SAVE_APPOINTMENT : 'SAVE_APPOINTMENT',
    SAVE_APPOINTMENT_PENDING : 'SAVE_APPOINTMENT_PENDING',
    SAVE_APPOINTMENT_FULFILLED : 'SAVE_APPOINTMENT_FULFILLED',
    SAVE_APPOINTMENT_FAILURE : 'SAVE_APPOINTMENT_FAILURE',
    SAVE_APPOINTMENT_REJECTED : 'SAVE_APPOINTMENT_REJECTED',

    SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT : 'SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT',
    SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_PENDING : 'SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_PENDING',
    SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_FULFILLED : 'SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_FULFILLED',
    SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_FAILURE : 'SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_FAILURE',
    SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_REJECTED : 'SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_REJECTED',


    GET_MAIN_APPOINTMENT : 'GET_MAIN_APPOINTMENT',
    GET_MAIN_APPOINTMENT_PENDING : 'GET_MAIN_APPOINTMENT_PENDING',
    GET_MAIN_APPOINTMENT_FULFILLED : 'GET_MAIN_APPOINTMENT_FULFILLED',
    GET_MAIN_APPOINTMENT_FAILURE : 'GET_MAIN_APPOINTMENT_FAILURE',
    GET_MAIN_APPOINTMENT_REJECTED : 'GET_MAIN_APPOINTMENT_REJECTED',

    GET_SERVICE_APPOINTMENT_SERVICE : 'GET_SERVICE_APPOINTMENT_SERVICE',
    GET_SERVICE_APPOINTMENT_SERVICE_PENDING : 'GET_SERVICE_APPOINTMENT_SERVICE_PENDING',
    GET_SERVICE_APPOINTMENT_SERVICE_FULFILLED : 'GET_SERVICE_APPOINTMENT_SERVICE_FULFILLED',
    GET_SERVICE_APPOINTMENT_SERVICE_FAILURE : 'GET_SERVICE_APPOINTMENT_SERVICE_FAILURE',
    GET_SERVICE_APPOINTMENT_SERVICE_REJECTED : 'GET_SERVICE_APPOINTMENT_SERVICE_REJECTED',

    SAVE_SERVICE_APPOINTMENT_FINISH : 'SAVE_SERVICE_APPOINTMENT_FINISH',
    SAVE_SERVICE_APPOINTMENT_FINISH_PENDING : 'SAVE_SERVICE_APPOINTMENT_FINISH_PENDING',
    SAVE_SERVICE_APPOINTMENT_FINISH_FULFILLED : 'SAVE_SERVICE_APPOINTMENT_FINISH_FULFILLED',
    SAVE_SERVICE_APPOINTMENT_FINISH_FAILURE : 'SAVE_SERVICE_APPOINTMENT_FINISH_FAILURE',
    SAVE_SERVICE_APPOINTMENT_FINISH_REJECTED : 'SAVE_SERVICE_APPOINTMENT_FINISH_REJECTED',


    SAVE_FALSE : 'SAVE_FALSE',
    SERVICE_APPOINTMENT_STATUS_CHANGE : 'SERVICE_APPOINTMENT_STATUS_CHANGE',

   

};
