export const myServicePendingActionsConstants = {


    GET_ALL_MY_SERVICES: 'GET_ALL_MY_SERVICES',
    GET_ALL_MY_SERVICES_PENDING: 'GET_ALL_MY_SERVICES_PENDING',
    GET_ALL_MY_SERVICES_FULFILLED: 'GET_ALL_MY_SERVICES_FULFILLED',
    GET_ALL_MY_SERVICES_FAILURE: 'GET_ALL_MY_SERVICES_FAILURE',
    GET_ALL_MY_SERVICES_REJECTED: 'GET_ALL_MY_SERVICES_REJECTED',

    GET_ALL_OTHERS_SERVICES: 'GET_ALL_OTHERS_SERVICES',
    GET_ALL_OTHERS_SERVICES_PENDING: 'GET_ALL_OTHERS_SERVICES_PENDING',
    GET_ALL_OTHERS_SERVICES_FULFILLED: 'GET_ALL_OTHERS_SERVICES_FULFILLED',
    GET_ALL_OTHERS_SERVICES_FAILURE: 'GET_ALL_OTHERS_SERVICES_FAILURE',
    GET_ALL_OTHERS_SERVICES_REJECTED: 'GET_ALL_OTHERS_SERVICES_REJECTED',

    SAVE_SERVICE_APPOINTMENT_START: 'SAVE_SERVICE_APPOINTMENT_START',
    SAVE_SERVICE_APPOINTMENT_START_PENDING: 'SAVE_SERVICE_APPOINTMENT_START_PENDING',
    SAVE_SERVICE_APPOINTMENT_START_FULFILLED: 'SAVE_SERVICE_APPOINTMENT_START_FULFILLED',
    SAVE_SERVICE_APPOINTMENT_START_FAILURE: 'SAVE_SERVICE_APPOINTMENT_START_FAILURE',
    SAVE_SERVICE_APPOINTMENT_START_REJECTED: 'SAVE_SERVICE_APPOINTMENT_START_REJECTED',

    SAVE_SERVICE_APPOINTMENT_END: 'SAVE_SERVICE_APPOINTMENT_END',
    SAVE_SERVICE_APPOINTMENT_END_PENDING: 'SAVE_SERVICE_APPOINTMENT_END_PENDING',
    SAVE_SERVICE_APPOINTMENT_END_FULFILLED: 'SAVE_SERVICE_APPOINTMENT_END_FULFILLED',
    SAVE_SERVICE_APPOINTMENT_END_FAILURE: 'SAVE_SERVICE_APPOINTMENT_END_FAILURE',
    SAVE_SERVICE_APPOINTMENT_END_REJECTED: 'SAVE_SERVICE_APPOINTMENT_END_REJECTED',

    SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT: 'SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT',
    SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_PENDING: 'SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_PENDING',
    SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_FULFILLED: 'SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_FULFILLED',
    SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_FAILURE: 'SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_FAILURE',
    SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_REJECTED: 'SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_REJECTED',
};
