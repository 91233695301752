import loginLoaderSource from './source.js';

export function laravelLogin(token) {
    
    return function (dispatch) {

        dispatch({type: 'LOGIN_LARAVEL_USER', payload:loginLoaderSource.login(token)});
    }
}

export function getPermissions() {
    
    return function (dispatch) {

        dispatch({type: 'USER_PERMISSIONS', payload:loginLoaderSource.getPermissions()});
    }
    
}


