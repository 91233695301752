
import {serviceApprovalSignedConstants} from './constants.js';
export default function serviceApprovalSignedReducer(state={
    myServicesApproval: {},
    fetched: false,
    loadingSearch:false,
    loading:false,
    saved:false,
  }, action) {

    state = {...state,saved:{...state.saved ,serviceStart:false},loading:{...state.loading ,getService:false }}
    switch (action.type) {



        case serviceApprovalSignedConstants.GET_ALL_MY_SERVICE_APPROVAL_SIGNED_PENDING: {
            return {...state,loading:{...state.loading ,getService:false }, user: action.payload,saved:false}
        }  
        case  serviceApprovalSignedConstants.GET_ALL_MY_SERVICE_APPROVAL_SIGNED_FULFILLED: {
            return {...state,loading:{...state.loading ,getService:false }, myServicesApproval:action?.payload?.data?.data}
        }    
        case serviceApprovalSignedConstants.GET_ALL_MY_SERVICE_APPROVAL_SIGNED_FAILURE:  {
            return {...state, loading:{...state.loading ,getService:false },error:action.error}
        }
        case serviceApprovalSignedConstants.GET_ALL_MY_SERVICE_APPROVAL_SIGNED_REJECTED:  {
            return {...state, loading:{...state.loading ,getService:false },error:action.payload}
        }

        case serviceApprovalSignedConstants.SAVE_SERVICE_APPROVAL_CHECKED_PENDING: {
            return {...state,loading:{...state.loading , saveServiceReport:true },save:{...state.save , saveServiceReport:false } ,}
        }  
        case  serviceApprovalSignedConstants.SAVE_SERVICE_APPROVAL_CHECKED_FULFILLED: {
            return {...state,loading:{...state.loading , saveServiceReport:false } ,save:{...state.save , saveServiceReport:true } , serviceReport:action?.payload?.data?.data}
        }    
        case serviceApprovalSignedConstants.SAVE_SERVICE_APPROVAL_CHECKED_FAILURE:  {
            return {...state, loading:{...state.loading , saveServiceReport:false } ,save:{...state.save , saveServiceReport:false } ,error:action.error}
        }
        case serviceApprovalSignedConstants.SAVE_SERVICE_APPROVAL_CHECKED_REJECTED:  {
            return {...state, loading:{...state.loading , saveServiceReport:false } ,save:{...state.save , saveServiceReport:false } ,error:action.payload}
        }

        case serviceApprovalSignedConstants.SAVE_SERVICE_APPROVAL_SIGNED_PENDING: {
            return {...state,loading:{...state.loading , saveServiceReport:true },save:{...state.save , saveServiceReport:false } ,}
        }  
        case  serviceApprovalSignedConstants.SAVE_SERVICE_APPROVAL_SIGNED_FULFILLED: {
            return {...state,loading:{...state.loading , saveServiceReport:false } ,save:{...state.save , saveServiceReport:true } , serviceReport:action?.payload?.data?.data}
        }    
        case serviceApprovalSignedConstants.SAVE_SERVICE_APPROVAL_SIGNED_FAILURE:  {
            return {...state, loading:{...state.loading , saveServiceReport:false } ,save:{...state.save , saveServiceReport:false } ,error:action.error}
        }
        case serviceApprovalSignedConstants.SAVE_SERVICE_APPROVAL_SIGNED_REJECTED:  {
            return {...state, loading:{...state.loading , saveServiceReport:false } ,save:{...state.save , saveServiceReport:false } ,error:action.payload}
        }

        case serviceApprovalSignedConstants.SAVE_FALSE :  {
            return {...state,save:{...state.save , 
                    
                     saveServiceReport:false } 
                      ,error:{}}
        }
       
        default :
            return state;
    }

}
