export const serviceApprovalCheckedConstants = {


    GET_ALL_MY_SERVICE_APPROVAL_CHECKED: 'GET_ALL_MY_SERVICE_APPROVAL_CHECKED',
    GET_ALL_MY_SERVICE_APPROVAL_CHECKED_PENDING: 'GET_ALL_MY_SERVICE_APPROVAL_CHECKED_PENDING',
    GET_ALL_MY_SERVICE_APPROVAL_CHECKED_FULFILLED: 'GET_ALL_MY_SERVICE_APPROVAL_CHECKED_FULFILLED',
    GET_ALL_MY_SERVICE_APPROVAL_CHECKED_FAILURE: 'GET_ALL_MY_SERVICE_APPROVAL_CHECKED_FAILURE',
    GET_ALL_MY_SERVICE_APPROVAL_CHECKED_REJECTED: 'GET_ALL_MY_SERVICE_APPROVAL_CHECKED_REJECTED',


    GET_ALL_OTHERS_SERVICE_APPROVAL_CHECKED: 'GET_ALL_OTHERS_SERVICE_APPROVAL_CHECKED',
    GET_ALL_OTHERS_SERVICE_APPROVAL_CHECKED_PENDING: 'GET_ALL_OTHERS_SERVICE_APPROVAL_CHECKED_PENDING',
    GET_ALL_OTHERS_SERVICE_APPROVAL_CHECKED_FULFILLED: 'GET_ALL_OTHERS_SERVICE_APPROVAL_CHECKED_FULFILLED',
    GET_ALL_OTHERS_SERVICE_APPROVAL_CHECKED_FAILURE: 'GET_ALL_OTHERS_SERVICE_APPROVAL_CHECKED_FAILURE',
    GET_ALL_OTHERS_SERVICE_APPROVAL_CHECKED_REJECTED: 'GET_ALL_OTHERS_SERVICE_APPROVAL_CHECKED_REJECTED',


    SAVE_SERVICE_APPROVAL_CHECKED: 'SAVE_SERVICE_APPROVAL_CHECKED',
    SAVE_SERVICE_APPROVAL_CHECKED_PENDING: 'SAVE_SERVICE_APPROVAL_CHECKED_PENDING',
    SAVE_SERVICE_APPROVAL_CHECKED_FULFILLED: 'SAVE_SERVICE_APPROVAL_CHECKED_FULFILLED',
    SAVE_SERVICE_APPROVAL_CHECKED_FAILURE: 'SAVE_SERVICE_APPROVAL_CHECKED_FAILURE',
    SAVE_SERVICE_APPROVAL_CHECKED_REJECTED: 'SAVE_SERVICE_APPROVAL_CHECKED_REJECTED',

    SAVE_FALSE : 'SAVE_FALSE',

};
