
import { myLabsPendingActionsConstants } from '../../my_lab_pending/redux/constants.js';
import {appointmentConstants} from './constants.js';
export default function appointmentLabReducer(state={
    error: null,
    loading: {
        getAll:false,
        patientSearch:false,
        referredDoctorSearch:false,
        referredCenterSearch:false,
    },
    fetched: false,
    // Add Patients
    patients: [],
    patient: {},
    saved: {},
    // appointment: {},
    partners: [],
    partner: {},
    guardians: [],
    referredDoctors: [],
    labCenters: [],

    patientAppointment: {},
    patientType: [],
    doctors: [],

    testAttributes : [],
    testFees : {},
    testReports : {},
    tests:[],
    testPatientReport:[],
    
    doctorTimeSlots: [],
    doctorTimeSlotAppointments: [],
    canceledAppointment:{},
    isDeletedAppointment:false,
    cancelTimeSlot:false
    
    

  }, action) {

    state = {...state,error:{} }

    switch (action.type) {

         //GET Doctor Time Slots for doctor id 
        case appointmentConstants.GET_ALL_APPOINTMENTS_PENDING: {
            return {...state,loading:{...state.loading ,getAll:true }}
        }  
        case  appointmentConstants.GET_ALL_APPOINTMENTS_FULFILLED: {
            return {...state,loading:{...state.loading ,getAll:false },allAppointments:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_ALL_APPOINTMENTS_FAILURE:  {
            return {...state, loading:{...state.loading ,getAll:false },error:action.error}
        }
        case appointmentConstants.GET_ALL_APPOINTMENTS_REJECTED:  {
            return {...state, loading:{...state.loading ,getAll:false },error:action.error}
        }
        case appointmentConstants.GET_ALL_TEST_ADD_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,getAllTest:true }}
        }  
        case  appointmentConstants.GET_ALL_TEST_ADD_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,getAllTest:false },allAppointmentTest:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_ALL_TEST_ADD_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,getAllTest:false },error:action.error}
        }
        case appointmentConstants.GET_ALL_TEST_ADD_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,getAllTest:false },error:action.error}
        }


    
        case appointmentConstants.GET_PATIENTS_FOR_ADD_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,patientSearch:true }}
        }  
        case  appointmentConstants.GET_PATIENTS_FOR_ADD_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,patientSearch:false } , patients:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_PATIENTS_FOR_ADD_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,patientSearch:false } ,error:action.error}
        }
        case appointmentConstants.GET_PATIENTS_FOR_ADD_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,patientSearch:false } ,error:action.error}
        }


    
        case appointmentConstants.GET_ALL_IPD_PATIENT_ADD_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,getAllIpdAppointment:true }}
        }  
        case  appointmentConstants.GET_ALL_IPD_PATIENT_ADD_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,getAllIpdAppointment:false } , ipdPatients:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_ALL_IPD_PATIENT_ADD_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,getAllIpdAppointment:false } ,error:action.error}
        }
        case appointmentConstants.GET_ALL_IPD_PATIENT_ADD_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,getAllIpdAppointment:false } ,error:action.error}
        }
    
        case appointmentConstants.GET_GUARDIAN_FOR_ADD_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,guardianSearch:true }}
        }  
        case  appointmentConstants.GET_GUARDIAN_FOR_ADD_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,guardianSearch:false } , guardians:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_GUARDIAN_FOR_ADD_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,guardianSearch:false } ,error:action.error}
        }
        case appointmentConstants.GET_GUARDIAN_FOR_ADD_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,guardianSearch:false } ,error:action.error}
        }
    
        case appointmentConstants.GET_REFERRED_DOCTOR_ADD_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,referredDoctorSearch:true }}
        }  
        case  appointmentConstants.GET_REFERRED_DOCTOR_ADD_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,referredDoctorSearch:false } , referredDoctors:action?.payload?.data?.data?.data}
        }    
        case appointmentConstants.GET_REFERRED_DOCTOR_ADD_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,referredDoctorSearch:false } ,error:action.error}
        }
        case appointmentConstants.GET_REFERRED_DOCTOR_ADD_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,referredDoctorSearch:false } ,error:action.error}
        }
    
        case appointmentConstants.GET_REFERRED_CENTER_ADD_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,referredCenterSearch:true }}
        }  
        case  appointmentConstants.GET_REFERRED_CENTER_ADD_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,referredCenterSearch:false } , referredCenters:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_REFERRED_CENTER_ADD_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,referredCenterSearch:false } ,error:action.error}
        }
        case appointmentConstants.GET_REFERRED_CENTER_ADD_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,referredCenterSearch:false } ,error:action.error}
        }
    
        case appointmentConstants.GET_LAB_CENTER_ADD_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,labCenterSearch:true }}
        }  
        case  appointmentConstants.GET_LAB_CENTER_ADD_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,labCenterSearch:false } , labCenters:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_LAB_CENTER_ADD_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,labCenterSearch:false } ,error:action.error}
        }
        case appointmentConstants.GET_LAB_CENTER_ADD_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,labCenterSearch:false } ,error:action.error}
        }


        case appointmentConstants.GET_ALL_LABS_ADD_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,labsGetAll:true }}
        }  
        case  appointmentConstants.GET_ALL_LABS_ADD_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,labsGetAll:false } , labs:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_ALL_LABS_ADD_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,labsGetAll:false } ,error:action.error}
        }
        case appointmentConstants.GET_ALL_LABS_ADD_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,labsGetAll:false } ,error:action.error}
        }

        case appointmentConstants.GET_LABS_TIME_SLOTS_ADD_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,labsGetAllTimeSlot:true }}
        }  
        case  appointmentConstants.GET_LABS_TIME_SLOTS_ADD_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,labsGetAllTimeSlot:false } , labTimeSlots:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_LABS_TIME_SLOTS_ADD_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,labsGetAllTimeSlot:false } ,error:action.error}
        }
        case appointmentConstants.GET_LABS_TIME_SLOTS_ADD_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,labsGetAllTimeSlot:false } ,error:action.error}
        }



        case appointmentConstants.GET_LABS_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,labsGetAllTimeSlotAppointment:true }}
        }  
        case  appointmentConstants.GET_LABS_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,labsGetAllTimeSlotAppointment:false } , labTimeSlotsAppointment:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_LABS_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,labsGetAllTimeSlotAppointment:false } ,error:action.error}
        }
        case appointmentConstants.GET_LABS_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,labsGetAllTimeSlotAppointment:false } ,error:action.error}
        }


        case appointmentConstants.GET_LABS_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE_PENDING: {
            return {...state,loading:{...state.loading ,labsGetAllTimeSlotAppointmentPatient:true }}
        }  
        case  appointmentConstants.GET_LABS_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE_FULFILLED: {
            return {...state,loading:{...state.loading ,labsGetAllTimeSlotAppointmentPatient:false } , labTimeSlotsAppointmentPatient:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_LABS_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE_FAILURE:  {
            return {...state, loading:{...state.loading ,labsGetAllTimeSlotAppointmentPatient:false } ,error:action.error}
        }
        case appointmentConstants.GET_LABS_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE_REJECTED:  {
            return {...state, loading:{...state.loading ,labsGetAllTimeSlotAppointmentPatient:false } ,error:action.error}
        }



        case appointmentConstants.SAVE_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,appointmentSave:true } , saved:{...state.saved , appointmentSave:false}}
        }  
        case  appointmentConstants.SAVE_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,appointmentSave:false } , saved:{...state.saved , appointmentSave:true} , labSavedAppointment:action?.payload?.data?.data}
        }    
        case appointmentConstants.SAVE_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,appointmentSave:false } , saved:{...state.saved , appointmentSave:false} ,error:action.error}
        }
        case appointmentConstants.SAVE_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,appointmentSave:false } , saved:{...state.saved , appointmentSave:false} ,error:action.payload}
        }

        case appointmentConstants.GET_MAIN_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading ,appointmentGetMain:true } , saved:{...state.saved , appointmentGetMain:false}}
        }  
        case  appointmentConstants.GET_MAIN_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading ,appointmentGetMain:false } , saved:{...state.saved , appointmentGetMain:true} , labsMainAppointment:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_MAIN_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading ,appointmentGetMain:false } , saved:{...state.saved , appointmentGetMain:false} ,error:action.error}
        }
        case appointmentConstants.GET_MAIN_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading ,appointmentGetMain:false } , saved:{...state.saved , appointmentGetMain:false} ,error:action.payload}
        }
        case appointmentConstants.GET_LAB_TEST_DETAILS_PENDING: {
            return {...state,loading:{...state.loading ,appointmentGetLabTest:true } , saved:{...state.saved , appointmentGetLabTest:false}}
        }  
        case  appointmentConstants.GET_LAB_TEST_DETAILS_FULFILLED: {
            return {...state,loading:{...state.loading ,appointmentGetLabTest:false } , saved:{...state.saved , appointmentGetLabTest:true} , labsTestAppointment:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_LAB_TEST_DETAILS_FAILURE:  {
            return {...state, loading:{...state.loading ,appointmentGetLabTest:false } , saved:{...state.saved , appointmentGetLabTest:false} ,error:action.error}
        }
        case appointmentConstants.GET_LAB_TEST_DETAILS_REJECTED:  {
            return {...state, loading:{...state.loading ,appointmentGetLabTest:false } , saved:{...state.saved , appointmentGetLabTest:false} ,error:action.payload}
        }
        case appointmentConstants.GET_LAB_TEST_SAMPLE_DETAILS_PENDING: {
            return {...state,loading:{...state.loading ,appointmentGetLabTestSamples:true } , saved:{...state.saved , appointmentGetLabTestSamples:false}}
        }  
        case  appointmentConstants.GET_LAB_TEST_SAMPLE_DETAILS_FULFILLED: {
            return {...state,loading:{...state.loading ,appointmentGetLabTestSamples:false } , saved:{...state.saved , appointmentGetLabTestSamples:true} , labsTestSamples:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_LAB_TEST_SAMPLE_DETAILS_FAILURE:  {
            return {...state, loading:{...state.loading ,appointmentGetLabTestSamples:false } , saved:{...state.saved , appointmentGetLabTestSamples:false} ,error:action.error}
        }
        case appointmentConstants.GET_LAB_TEST_SAMPLE_DETAILS_REJECTED:  {
            return {...state, loading:{...state.loading ,appointmentGetLabTestSamples:false } , saved:{...state.saved , appointmentGetLabTestSamples:false} ,error:action.payload}
        }
        
        
        case appointmentConstants.GET_ONE_TEST_SAMPLE_DETAIL_PENDING: {
            return {...state,loading:{...state.loading ,appointmentGetOneLabTestSample:true } , saved:{...state.saved , appointmentGetOneLabTestSample:false}}
        }  
        case  appointmentConstants.GET_ONE_TEST_SAMPLE_DETAIL_FULFILLED: {
            return {...state,loading:{...state.loading ,appointmentGetOneLabTestSample:false } , saved:{...state.saved , appointmentGetOneLabTestSample:true} , labsTestOneSample:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_ONE_TEST_SAMPLE_DETAIL_FAILURE:  {
            return {...state, loading:{...state.loading ,appointmentGetOneLabTestSample:false } , saved:{...state.saved , appointmentGetOneLabTestSample:false} ,error:action.error}
        }
        case appointmentConstants.GET_ONE_TEST_SAMPLE_DETAIL_REJECTED:  {
            return {...state, loading:{...state.loading ,appointmentGetOneLabTestSample:false } , saved:{...state.saved , appointmentGetOneLabTestSample:false} ,error:action.payload}
        }
        
        case appointmentConstants.SAVE_LAB_APPOINTMENT_START_PENDING: {
            return {...state,loading:{...state.loading ,appointmentGetOneLabTestSampleStart:true } , saved:{...state.saved , appointmentGetOneLabTestSampleStart:false}}
        }  
        case  appointmentConstants.SAVE_LAB_APPOINTMENT_START_FULFILLED: {
            return {...state,loading:{...state.loading ,appointmentGetOneLabTestSampleStart:false } , saved:{...state.saved , appointmentGetOneLabTestSampleStart:true} , labsTestOneSampleTestStart:action?.payload?.data?.data}
        }    
        case appointmentConstants.SAVE_LAB_APPOINTMENT_START_FAILURE:  {
            return {...state, loading:{...state.loading ,appointmentGetOneLabTestSampleStart:false } , saved:{...state.saved , appointmentGetOneLabTestSampleStart:false} ,error:action.error}
        }
        case appointmentConstants.SAVE_LAB_APPOINTMENT_START_REJECTED:  {
            return {...state, loading:{...state.loading ,appointmentGetOneLabTestSampleStart:false } , saved:{...state.saved , appointmentGetOneLabTestSampleStart:false} ,error:action.payload}
        }
       
       
        case appointmentConstants.SAVE_LAB_APPOINTMENT_END_PENDING: {
            return {...state,loading:{...state.loading ,appointmentGetOneLabTestSampleEnd:true } , saved:{...state.saved , appointmentGetOneLabTestSampleEnd:false}}
        }  
        case  appointmentConstants.SAVE_LAB_APPOINTMENT_END_FULFILLED: {
            return {...state,loading:{...state.loading ,appointmentGetOneLabTestSampleEnd:false } , saved:{...state.saved , appointmentGetOneLabTestSampleEnd:true} , labsTestOneSampleTestEnd:action?.payload?.data?.data}
        }    
        case appointmentConstants.SAVE_LAB_APPOINTMENT_END_FAILURE:  {
            return {...state, loading:{...state.loading ,appointmentGetOneLabTestSampleEnd:false } , saved:{...state.saved , appointmentGetOneLabTestSampleEnd:false} ,error:action.error}
        }
        case appointmentConstants.SAVE_LAB_APPOINTMENT_END_REJECTED:  {
            return {...state, loading:{...state.loading ,appointmentGetOneLabTestSampleEnd:false } , saved:{...state.saved , appointmentGetOneLabTestSampleEnd:false} ,error:action.payload}
        }


        case appointmentConstants.GET_LAB_APPOINTMENT_LAB_PENDING: {
            return {...state,loading:{...state.loading ,appointmentGetLab:true } , saved:{...state.saved , appointmentGetLab:false}}
        }  
        case  appointmentConstants.GET_LAB_APPOINTMENT_LAB_FULFILLED: {
            return {...state,loading:{...state.loading ,appointmentGetLab:false } , saved:{...state.saved , appointmentGetLab:true} , labAppointmentLab:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_LAB_APPOINTMENT_LAB_FAILURE:  {
            return {...state, loading:{...state.loading ,appointmentGetLab:false } , saved:{...state.saved , appointmentGetLab:false} ,error:action.error}
        }
        case appointmentConstants.GET_LAB_APPOINTMENT_LAB_REJECTED:  {
            return {...state, loading:{...state.loading ,appointmentGetLab:false } , saved:{...state.saved , appointmentGetLab:false} ,error:action.payload}
        }

        case appointmentConstants.GET_ALL_LAB_TECHNICIAN_PENDING : {
            return {...state,loading:{...state.loading , getAllLabsTechnician:true },}
        }  
        case  appointmentConstants.GET_ALL_LAB_TECHNICIAN_FULFILLED: {
            return {...state,loading:{...state.loading , getAllLabsTechnician:false } , labTechnician:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_ALL_LAB_TECHNICIAN_FAILURE :  {
            return {...state, loading:{...state.loading , getAllLabsTechnician:false } ,error:action.error}
        }
        case appointmentConstants.GET_ALL_LAB_TECHNICIAN_REJECTED :  {
            return {...state, loading:{...state.loading , getAllLabsTechnician:false } ,error:action.error}
        }
        
        
        case appointmentConstants.GET_ALL_LAB_ITEMS_PENDING : {
            return {...state,loading:{...state.loading , getAllLabsItems:true },}
        }  
        case  appointmentConstants.GET_ALL_LAB_ITEMS_FULFILLED: {
            return {...state,loading:{...state.loading , getAllLabsItems:false } , labItems:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_ALL_LAB_ITEMS_FAILURE :  {
            return {...state, loading:{...state.loading , getAllLabsItems:false } ,error:action.error}
        }
        case appointmentConstants.GET_ALL_LAB_ITEMS_REJECTED :  {
            return {...state, loading:{...state.loading , getAllLabsItems:false } ,error:action.error}
        }
        case appointmentConstants.GET_ALL_LAB_MACHINES_PENDING : {
            return {...state,loading:{...state.loading , getAllLabsMachines:true },}
        }  
        case  appointmentConstants.GET_ALL_LAB_MACHINES_FULFILLED: {
            return {...state,loading:{...state.loading , getAllLabsMachines:false } , labMachines:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_ALL_LAB_MACHINES_FAILURE :  {
            return {...state, loading:{...state.loading , getAllLabsMachines:false } ,error:action.error}
        }
        case appointmentConstants.GET_ALL_LAB_MACHINES_REJECTED :  {
            return {...state, loading:{...state.loading , getAllLabsMachines:false } ,error:action.error}
        }


        case appointmentConstants.SAVE_LAB_TEST_SAMPLE_PENDING : {
            return {...state,loading:{...state.loading , saveSaveLabTestSample:true },saved:{...state.saved , saveSaveLabTestSample:false } ,}
        }  
        case  appointmentConstants.SAVE_LAB_TEST_SAMPLE_FULFILLED: {
            return {...state,loading:{...state.loading , saveSaveLabTestSample:false } ,saved:{...state.saved , saveSaveLabTestSample:true } , labTestSamples:action?.payload?.data?.data}
        }    
        case appointmentConstants.SAVE_LAB_TEST_SAMPLE_FAILURE :  {
            return {...state, loading:{...state.loading , saveSaveLabTestSample:false } ,saved:{...state.saved , saveSaveLabTestSample:false } ,error:action.error}
        }
        case appointmentConstants.SAVE_LAB_TEST_SAMPLE_REJECTED :  {
            return {...state, loading:{...state.loading , saveSaveLabTestSample:false } ,saved:{...state.saved , saveSaveLabTestSample:false } ,error:action.payload}
        }

        case appointmentConstants.UPDATE_APPOINTMENT_STATUS_PENDING : {
            return {...state,loading:{...state.loading , appointmentUpdateStatus:true },saved:{...state.saved , appointmentUpdateStatus:false } ,}
        }  
        case  appointmentConstants.UPDATE_APPOINTMENT_STATUS_FULFILLED: {
            return {...state,loading:{...state.loading , appointmentUpdateStatus:false } ,saved:{...state.saved , appointmentUpdateStatus:true } , appointmentUpdateStatus:action?.payload?.data?.data}
        }    
        case appointmentConstants.UPDATE_APPOINTMENT_STATUS_FAILURE :  {
            return {...state, loading:{...state.loading , appointmentUpdateStatus:false } ,saved:{...state.saved , appointmentUpdateStatus:false } ,error:action.error}
        }
        case appointmentConstants.UPDATE_APPOINTMENT_STATUS_REJECTED :  {
            return {...state, loading:{...state.loading , appointmentUpdateStatus:false } ,saved:{...state.saved , appointmentUpdateStatus:false } ,error:action.payload}
        }


        case appointmentConstants.GET_LAB_TEST_SAMPLE_REPORT_TEST_DETAILS_PENDING : {
            return {...state,loading:{...state.loading , getTestSampleTestReport:true },saved:{...state.saved , getTestSampleTestReport:false } ,}
        }  
        case  appointmentConstants.GET_LAB_TEST_SAMPLE_REPORT_TEST_DETAILS_FULFILLED: {
            return {...state,loading:{...state.loading , getTestSampleTestReport:false } ,saved:{...state.saved , getTestSampleTestReport:true } , labTestSampleReport:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_LAB_TEST_SAMPLE_REPORT_TEST_DETAILS_FAILURE :  {
            return {...state, loading:{...state.loading , getTestSampleTestReport:false } ,saved:{...state.saved , getTestSampleTestReport:false } ,error:action.error}
        }
        case appointmentConstants.GET_LAB_TEST_SAMPLE_REPORT_TEST_DETAILS_REJECTED :  {
            return {...state, loading:{...state.loading , getTestSampleTestReport:false } ,saved:{...state.saved , getTestSampleTestReport:false } ,error:action.payload}
        }

        case appointmentConstants.SAVE_LAB_HR_USAGE_PENDING : {
            return {...state,loading:{...state.loading , saveSaveLabHr:true },saved:{...state.saved , saveSaveLabHr:false } ,}
        }  
        case  appointmentConstants.SAVE_LAB_HR_USAGE_FULFILLED: {
            return {...state,loading:{...state.loading , saveSaveLabHr:false } ,saved:{...state.saved , saveSaveLabHr:true } , labHrUsage:action?.payload?.data?.data}
        }    
        case appointmentConstants.SAVE_LAB_HR_USAGE_FAILURE :  {
            return {...state, loading:{...state.loading , saveSaveLabHr:false } ,saved:{...state.saved , saveSaveLabHr:false } ,error:action.error}
        }
        case appointmentConstants.SAVE_LAB_HR_USAGE_REJECTED :  {
            return {...state, loading:{...state.loading , saveSaveLabHr:false } ,saved:{...state.saved , saveSaveLabHr:false } ,error:action.payload}
        }

        case appointmentConstants.SAVE_LAB_MACHINES_PENDING : {
            return {...state,loading:{...state.loading , saveSaveLabMachine:true },saved:{...state.saved , saveSaveLabMachine:false } ,}
        }  
        case  appointmentConstants.SAVE_LAB_MACHINES_FULFILLED: {
            return {...state,loading:{...state.loading , saveSaveLabMachine:false } ,saved:{...state.saved , saveSaveLabMachine:true } , labMachineUsage:action?.payload?.data?.data}
        }    
        case appointmentConstants.SAVE_LAB_MACHINES_FAILURE :  {
            return {...state, loading:{...state.loading , saveSaveLabMachine:false } ,saved:{...state.saved , saveSaveLabMachine:false } ,error:action.error}
        }
        case appointmentConstants.SAVE_LAB_MACHINES_REJECTED :  {
            return {...state, loading:{...state.loading , saveSaveLabMachine:false } ,saved:{...state.saved , saveSaveLabMachine:false } ,error:action.payload}
        }

        case appointmentConstants.SAVE_LAB_ITEM_USAGE_PENDING : {
            return {...state,loading:{...state.loading , saveLabItem:true },saved:{...state.saved , saveLabItem:false } ,}
        }  
        case  appointmentConstants.SAVE_LAB_ITEM_USAGE_FULFILLED: {
            return {...state,loading:{...state.loading , saveLabItem:false } ,saved:{...state.saved , saveLabItem:true } , labItemUsage:action?.payload?.data?.data}
        }    
        case appointmentConstants.SAVE_LAB_ITEM_USAGE_FAILURE :  {
            return {...state, loading:{...state.loading , saveLabItem:false } ,saved:{...state.saved , saveLabItem:false } ,error:action.error}
        }
        case appointmentConstants.SAVE_LAB_ITEM_USAGE_REJECTED :  {
            return {...state, loading:{...state.loading , saveLabItem:false } ,saved:{...state.saved , saveLabItem:false } ,error:action.payload}
        }

        case appointmentConstants.SAVE_LAB_REPORT_PENDING : {
            return {...state,loading:{...state.loading , saveLabReport:true },saved:{...state.saved , saveLabReport:false } ,}
        }  
        case  appointmentConstants.SAVE_LAB_REPORT_FULFILLED: {
            return {...state,loading:{...state.loading , saveLabReport:false } ,saved:{...state.saved , saveLabReport:true } , labReport:action?.payload?.data?.data}
        }    
        case appointmentConstants.SAVE_LAB_REPORT_FAILURE :  {
            return {...state, loading:{...state.loading , saveLabReport:false } ,saved:{...state.saved , saveLabReport:false } ,error:action.error}
        }
        case appointmentConstants.SAVE_LAB_REPORT_REJECTED :  {
            return {...state, loading:{...state.loading , saveLabReport:false } ,saved:{...state.saved , saveLabReport:false } ,error:action.payload}
        }


        case appointmentConstants.EDIT_LAB_REPORT_PENDING : {
            return {...state,loading:{...state.loading , editLabReport:true },saved:{...state.saved , editLabReport:false } ,}
        }  
        case  appointmentConstants.EDIT_LAB_REPORT_FULFILLED: {
            return {...state,loading:{...state.loading , editLabReport:false } ,saved:{...state.saved , editLabReport:true } , labReport:action?.payload?.data?.data}
        }    
        case appointmentConstants.EDIT_LAB_REPORT_FAILURE :  {
            return {...state, loading:{...state.loading , editLabReport:false } ,saved:{...state.saved , editLabReport:false } ,error:action.error}
        }
        case appointmentConstants.EDIT_LAB_REPORT_REJECTED :  {
            return {...state, loading:{...state.loading , editLabReport:false } ,saved:{...state.saved , editLabReport:false } ,error:action.payload}
        }


        case appointmentConstants.SAVE_LAB_ATTACHMENT_REPORT_PENDING : {
            return {...state,loading:{...state.loading , saveLabAttachmentReport:true },saved:{...state.saved , saveLabAttachmentReport:false } ,}
        }  
        case  appointmentConstants.SAVE_LAB_ATTACHMENT_REPORT_FULFILLED: {
            return {...state,loading:{...state.loading , saveLabAttachmentReport:false } ,saved:{...state.saved , saveLabAttachmentReport:true } , labReport:action?.payload?.data?.data}
        }    
        case appointmentConstants.SAVE_LAB_ATTACHMENT_REPORT_FAILURE :  {
            return {...state, loading:{...state.loading , saveLabAttachmentReport:false } ,saved:{...state.saved , saveLabAttachmentReport:false } ,error:action.error}
        }
        case appointmentConstants.SAVE_LAB_ATTACHMENT_REPORT_REJECTED :  {
            return {...state, loading:{...state.loading , saveLabAttachmentReport:false } ,saved:{...state.saved , saveLabAttachmentReport:false } ,error:action.payload}
        }


        case appointmentConstants.SAVE_TEST_SAMPLE_TRANSFER_IN_PENDING : {
            return {...state,loading:{...state.loading , saveLabTemplateIn:true },saved:{...state.saved , saveLabTemplateIn:false } ,}
        }  
        case  appointmentConstants.SAVE_TEST_SAMPLE_TRANSFER_IN_FULFILLED: {
            return {...state,loading:{...state.loading , saveLabTemplateIn:false } ,saved:{...state.saved , saveLabTemplateIn:true } , labSampleTransferIn:action?.payload?.data?.data}
        }    
        case appointmentConstants.SAVE_TEST_SAMPLE_TRANSFER_IN_FAILURE :  {
            return {...state, loading:{...state.loading , saveLabTemplateIn:false } ,saved:{...state.saved , saveLabTemplateIn:false } ,error:action.error}
        }
        case appointmentConstants.SAVE_TEST_SAMPLE_TRANSFER_IN_REJECTED :  {
            return {...state, loading:{...state.loading , saveLabTemplateIn:false } ,saved:{...state.saved , saveLabTemplateIn:false } ,error:action.payload}
        }

        case appointmentConstants.SAVE_TEST_SAMPLE_TRANSFER_OUT_PENDING : {
            return {...state,loading:{...state.loading , saveLabTemplateOut:true },saved:{...state.saved , saveLabTemplateOut:false } ,}
        }  
        case  appointmentConstants.SAVE_TEST_SAMPLE_TRANSFER_OUT_FULFILLED: {
            return {...state,loading:{...state.loading , saveLabTemplateOut:false } ,saved:{...state.saved , saveLabTemplateOut:true } , labSampleTransferOut:action?.payload?.data?.data}
        }    
        case appointmentConstants.SAVE_TEST_SAMPLE_TRANSFER_OUT_FAILURE :  {
            return {...state, loading:{...state.loading , saveLabTemplateOut:false } ,saved:{...state.saved , saveLabTemplateOut:false } ,error:action.error}
        }
        case appointmentConstants.SAVE_TEST_SAMPLE_TRANSFER_OUT_REJECTED :  {
            return {...state, loading:{...state.loading , saveLabTemplateOut:false } ,saved:{...state.saved , saveLabTemplateOut:false } ,error:action.payload}
        }

        case appointmentConstants.CREATE_RETEST_FOR_THE_SAMPLE_PENDING : {
            return {...state,loading:{...state.loading , saveRetestForSample:true },saved:{...state.saved , saveRetestForSample:false } ,}
        }  
        case  appointmentConstants.CREATE_RETEST_FOR_THE_SAMPLE_FULFILLED: {
            return {...state,loading:{...state.loading , saveRetestForSample:false } ,saved:{...state.saved , saveRetestForSample:true } , retestSample:action?.payload?.data?.data}
        }    
        case appointmentConstants.CREATE_RETEST_FOR_THE_SAMPLE_FAILURE :  {
            return {...state, loading:{...state.loading , saveRetestForSample:false } ,saved:{...state.saved , saveRetestForSample:false } ,error:action.error}
        }
        case appointmentConstants.CREATE_RETEST_FOR_THE_SAMPLE_REJECTED :  {
            return {...state, loading:{...state.loading , saveRetestForSample:false } ,saved:{...state.saved , saveRetestForSample:false } ,error:action.payload}
        }



        case appointmentConstants.SAVE_LAB_FALSE :  {
            return {...state,saved:{...state.saved , 
                     getAllLabsTechnician:false ,appointmentSave:false, 
                     appointmentGetMain:false, appointmentGetLab:false,
                     saveLabItem:false,saveSaveLabMachine:false,updateSaveServiceMachine:false,
                     saveSaveLabHr:false,saveLabTemplateIn:false,saveLabTemplateOut:false,
                     appointmentGetOneLabTestSampleStart:false,
                     appointmentGetOneLabTestSample:false,
                     appointmentGetOneLabTestSampleEnd:false,
                     saveRetestForSample:false,
                     saveLabReport:false,
                     editLabReport:false,
                     saveLabAttachmentReport:false,
                     appointmentUpdateStatus:false,
                     saveSaveLabTestSample:false, 
                     labDelivered : false}
                     ,error:{}}
        }

        case myLabsPendingActionsConstants.SAVE_LAB_APPOINTMENT_START_PENDING: {
            return {...state,loading:{...state.loading ,saveAppointmentLab:false },saved:{...state.saved ,labStart:false } ,user: action.payload,saved:false}
        }  
        case  myLabsPendingActionsConstants.SAVE_LAB_APPOINTMENT_START_FULFILLED: {
            return {...state,loading:{...state.loading ,saveAppointmentLab:false },saved:{...state.saved ,labStart:true }, myLabStart:action?.payload?.data?.data}
        }    
        case myLabsPendingActionsConstants.SAVE_LAB_APPOINTMENT_START_FAILURE:  {
            return {...state, loading:{...state.loading ,saveAppointmentLab:false },saved:{...state.saved ,labStart:false },error:action.error}
        }
        case myLabsPendingActionsConstants.SAVE_LAB_APPOINTMENT_START_REJECTED:  {
            return {...state, loading:{...state.loading ,saveAppointmentLab:false },saved:{...state.saved ,labStart:false  },error:action.payload}
        }

        case myLabsPendingActionsConstants.SAVE_LAB_APPOINTMENT_END_PENDING: {
            return {...state,loading:{...state.loading ,saveAppointmentLab:false },saved:{...state.saved ,labEnd:false } ,user: action.payload,saved:false}
        }  
        case  myLabsPendingActionsConstants.SAVE_LAB_APPOINTMENT_END_FULFILLED: {
            return {...state,loading:{...state.loading ,saveAppointmentLab:false },saved:{...state.saved ,labEnd:true }, myLabEnd:action?.payload?.data?.data}
        }    
        case myLabsPendingActionsConstants.SAVE_LAB_APPOINTMENT_END_FAILURE:  {
            return {...state, loading:{...state.loading ,saveAppointmentLab:false },saved:{...state.saved ,labEnd:false },error:action.error}
        }
        case myLabsPendingActionsConstants.SAVE_LAB_APPOINTMENT_END_REJECTED:  {
            return {...state, loading:{...state.loading ,saveAppointmentLab:false },saved:{...state.saved ,labEnd:false  },error:action.payload}
        }

        case appointmentConstants.SAVE_LAB_APPOINTMENT_FINISH_PENDING : {
            return {...state,loading:{...state.loading , saveLabFinish:true },saved:{...state.saved , saveLabFinish:false } ,}
        }  
        case  appointmentConstants.SAVE_LAB_APPOINTMENT_FINISH_FULFILLED: {
            return {...state,loading:{...state.loading , saveLabFinish:false } ,saved:{...state.saved , saveLabFinish:true } , labFinish:action?.payload?.data?.data}
        }    
        case appointmentConstants.SAVE_LAB_APPOINTMENT_FINISH_FAILURE :  {
            return {...state, loading:{...state.loading , saveLabFinish:false } ,saved:{...state.saved , saveLabFinish:false } ,error:action.error}
        }
        case appointmentConstants.SAVE_LAB_APPOINTMENT_FINISH_REJECTED :  {
            return {...state, loading:{...state.loading , saveLabFinish:false } ,saved:{...state.saved , saveLabFinish:false } ,error:action.payload}
        }
        case myLabsPendingActionsConstants.SAVE_LAB_APPOINTMENT_DELIVERED_REPORT_PENDING: {
            return {...state,loading:{...state.loading ,saveAppointmentLab:false },saved:{...state.saved ,labDelivered:false } ,user: action.payload,saved:false}
        }  
        case  myLabsPendingActionsConstants.SAVE_LAB_APPOINTMENT_DELIVERED_REPORT_FULFILLED: {
            return {...state,loading:{...state.loading ,saveAppointmentLab:false },saved:{...state.saved ,labDelivered:true }, mylabDelivered:action?.payload?.data?.data}
        }    
        case myLabsPendingActionsConstants.SAVE_LAB_APPOINTMENT_DELIVERED_REPORT_FAILURE:  {
            return {...state, loading:{...state.loading ,saveAppointmentLab:false },saved:{...state.saved ,labDelivered:false },error:action.error}
        }
        case myLabsPendingActionsConstants.SAVE_LAB_APPOINTMENT_DELIVERED_REPORT_REJECTED:  {
            return {...state, loading:{...state.loading ,saveAppointmentLab:false },saved:{...state.saved ,labDelivered:false  },error:action.payload}
        }



        case appointmentConstants.LAB_APPOINTMENT_STATUS_CHANGE :  {
            return {...state, loading:{...state.loading , saveLabFinish:false,getAll:false,
                patientSearch:false,
                guardianSearch:false,
                referredDoctorSearch:false,
                referredCenterSearch:false,
                labsGetAll:false,
                labsGetAllTimeSlot:false,
                labsGetAllTimeSlotAppointment:false,
                appointmentSave:false,
                appointmentGetMain:false,
                appointmentGetLabTest:false,
                appointmentGetLabTestSamples:false,
                appointmentGetOneLabTestSample:false,
                appointmentGetOneLabTestSampleEnd:false,
                appointmentGetLab:false,
                getAllLabsTechnician:false,
                getAllLabsItems:false,
                getAllLabsMachines:false,
                saveSaveLabTestSample:false,
                appointmentUpdateStatus:false,
                saveSaveLabMachine:false,
                labsGetAllTimeSlotAppointmentPatient:false,
                appointmentGetOneLabTestSampleStart:false,
                appointmentGetOneLabTestSample:false,
                saveRetestForSample:false,
                saveLabItem:false,
                saveLabReport:false,
                editLabReport:false,
                saveAppointmentLab:false, } 
            ,saved:{...state.saved , saveLabFinish:false,appointmentSave:false,
                appointmentGetMain:false,
                appointmentGetLab:false,
                getAllLabsTechnician:false,
                appointmentGetLabTest:false,
                appointmentGetLabTestSamples:false,
                appointmentGetOneLabTestSample:false,
                appointmentGetOneLabTestSampleEnd:false,
                appointmentGetOneLabTestSampleStart:false,
                appointmentGetOneLabTestSample:false,
                saveRetestForSample:false,
                getAllLabsItems:false,
                getAllLabsMachines:false,
                saveSaveLabTestSample:false,
                appointmentUpdateStatus:false,
                saveSaveLabMachine:false,
                saveLabItem:false,
                editLabReport:false,
                saveLabReport:false,
                labStart:false,
                labDelivered:false,
                labEnd:false, } 
            
            }
        }

        case appointmentConstants.GET_ALL_TEST_ATTRIBUTES_PENDING: {
            return {...state,loading:{...state.loading }}
        }  
        case  appointmentConstants.GET_ALL_TEST_ATTRIBUTES_FULFILLED: {
            return {...state,loading:{...state.loading},testAttributes:action?.payload?.data}
        }    
        case appointmentConstants.GET_ALL_TEST_ATTRIBUTES_FAILURE:  {
            return {...state, loading:{...state.loading } ,error:action.error}
        }
        case appointmentConstants.GET_ALL_TEST_ATTRIBUTES_REJECTED:  {
            return {...state, loading:{...state.loading } ,error:action.payload}
        }

        case appointmentConstants.EDIT_TEST_REPORT_ATTRIBUTE_PENDING: {
            return {...state,loading:{...state.loading }}
        }  
        case  appointmentConstants.EDIT_TEST_REPORT_ATTRIBUTE_FULFILLED: {
            return {...state,loading:{...state.loading}}
        }    
        case appointmentConstants.EDIT_TEST_REPORT_ATTRIBUTE_FAILURE:  {
            return {...state, loading:{...state.loading } ,error:action.error}
        }
        case appointmentConstants.EDIT_TEST_REPORT_ATTRIBUTE_REJECTED:  {
            return {...state, loading:{...state.loading } ,error:action.payload}
        }

        case appointmentConstants.GET_ALL_TEST_FEES_PENDING: {
            return {...state,loading:{...state.loading }}
        }  
        case  appointmentConstants.GET_ALL_TEST_FEES_FULFILLED: {
            return {...state,loading:{...state.loading},testFees:action?.payload?.data.data}
        }    
        case appointmentConstants.GET_ALL_TEST_FEES_FAILURE:  {
            return {...state, loading:{...state.loading } ,error:action.error}
        }
        case appointmentConstants.GET_ALL_TEST_FEES_REJECTED:  {
            return {...state, loading:{...state.loading } ,error:action.payload}
        }

        case appointmentConstants.GET_APPOINTMENTS_MAIN_REPORTS_PENDING: {
            return {...state,loading:{...state.loading }}
        }  
        case  appointmentConstants.GET_APPOINTMENTS_MAIN_REPORTS_FULFILLED: {
            return {...state,loading:{...state.loading},testReports:action?.payload.data.data}
        }    
        case appointmentConstants.GET_APPOINTMENTS_MAIN_REPORTS_FAILURE:  {
            return {...state, loading:{...state.loading } ,error:action.error}
        }
        case appointmentConstants.GET_APPOINTMENTS_MAIN_REPORTS_REJECTED:  {
            return {...state, loading:{...state.loading } ,error:action.payload}
        }

        case appointmentConstants.GET_ALL_TEST_BY_TEST_PENDING: {
            return {...state,loading:{...state.loading }}
        }  
        case  appointmentConstants.GET_ALL_TEST_BY_TEST_FULFILLED: {
            return {...state,loading:{...state.loading},tests:action.payload.data.data}
        }    
        case appointmentConstants.GET_ALL_TEST_BY_TEST_FAILURE:  {
            return {...state, loading:{...state.loading } ,error:action.error}
        }
        case appointmentConstants.GET_ALL_TEST_BY_TEST_REJECTED:  {
            return {...state, loading:{...state.loading } ,error:action.payload}
        }

        case appointmentConstants.EDIT_TEST_SAMPLE_PENDING: {
            return {...state,loading:{...state.loading },saved:{...state.saved ,updateSample:false }}
        }  
        case  appointmentConstants.EDIT_TEST_SAMPLE_FULFILLED: {
            return {...state,loading:{...state.loading},saved:{...state.saved ,updateSample:true }}
        }    
        case appointmentConstants.EDIT_TEST_SAMPLE_FAILURE:  {
            return {...state, loading:{...state.loading },saved:{...state.saved ,updateSample:false } ,error:action.error}
        }
        case appointmentConstants.EDIT_TEST_SAMPLE_REJECTED:  {
            return {...state, loading:{...state.loading },saved:{...state.saved ,updateSample:false } ,error:action.payload}
        }

        case appointmentConstants.REPORT_PRINT_PENDING: {
            return {...state,loading:{...state.loading},saved:{...state.saved ,isPrint:false }}
        }  
        case  appointmentConstants.REPORT_PRINT_FULFILLED: {
            return {...state,loading:{...state.loading},saved:{...state.saved ,isPrint:true }}
        }    
        case appointmentConstants.REPORT_PRINT_FAILURE:  {
            return {...state, loading:{...state.loading },saved:{...state.saved ,isPrint:false } ,error:action.error}
        }
        case appointmentConstants.REPORT_PRINT_REJECTED:  {
            return {...state, loading:{...state.loading },saved:{...state.saved ,isPrint:false } ,error:action.payload}
        }

        case appointmentConstants.TESTS_REPORTS_PENDING: {
            return {...state,loading:{...state.loading},saved:{...state.saved ,isPrint:false }}
        }  
        case  appointmentConstants.TESTS_REPORTS_FULFILLED: {
            return {...state,loading:{...state.loading},saved:{...state.saved ,isPrint:true },testPatientReport:action.payload.data.data}
        }    
        case appointmentConstants.TESTS_REPORTS_FAILURE:  {
            return {...state, loading:{...state.loading },saved:{...state.saved ,isPrint:false } ,error:action.error}
        }
        case appointmentConstants.TESTS_REPORTS_REJECTED:  {
            return {...state, loading:{...state.loading },saved:{...state.saved ,isPrint:false } ,error:action.payload}
        }
        
        default :
            return state;
    }

}
