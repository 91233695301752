import { testSampleConstants } from "./constants"

export default function testSampleReducer(state={
    error: null,
    loading: {
        getAll:false,
    },
    fetched: false,
    data:[]
  }, action) {

    state = {...state,error:{} }

    switch (action.type) {

        case testSampleConstants.GET_ALL_TEST_SAMPLES_PENDING: {
            return {...state,loading:{...state.loading ,getAll:true }}
        }  
        case testSampleConstants.GET_ALL_TEST_SAMPLES_FULFILLED: {
            return {...state,loading:{...state.loading ,getAll:false },data:action?.payload?.data?.data}
        }    
        case testSampleConstants.GET_ALL_TEST_SAMPLES_FAILURE:  {
            return {...state, loading:{...state.loading ,getAll:false },error:action.error}
        }
        case testSampleConstants.GET_ALL_TEST_SAMPLES_REJECTED:  {
            return {...state, loading:{...state.loading ,getAll:false },error:action.error}
        }
       
        default :
            return state;
    }

}
