import React, { useEffect, useState } from 'react'
import testSource from './source'
import { Col, Row } from 'react-bootstrap'
import TableAction from '../../../components/Table/actions'
import { formatCurrencyWithAdd } from '../../../common/format_currency'
import testReportSource from './source'
import BillLogo from '../../../../src/assets/images/bill_logo.png'

const ViewTestReport = () => {
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [data, setData] = useState({});
    const [page, setPage] = useState(1);
    const [hid, setHid] = useState("");

    const columns = [{
        dataField: 'ref_no',
        text: 'Ref Number',
        hidden: false
    }, {
        dataField: 'test_name',
        text: 'Test Name ',
        sort: false,
    },{
        dataField: 'status',
        text: 'Status ',
        sort: false,
        formatter: (cellContent, row) => {
            return (
                <>
                {
                    (row.status == "Ongoing" || row.status == "Cancelled" ) ?
                        <>
                            <span className={"badge badge-warning"}>Pending</span>
                        </>
                        :
                        <>
                            <span className={"badge badge-success"}>Completed</span>
                        </>
                }
                </>
            )
        },
    },{
        dataField: 'action',
        text: 'Action',
        sort: false,
        formatter: (cellContent, row) => {
            return (
                <>
                    <button className='btn btn-primary'>Download</button>
                </>
            )
        },
    }];

    useEffect(() => {
        onFresh();
    }, [search,page,hid])

    useEffect(() => {
        console.log("datadata",data);
    }, [data])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const urlParam = params.get('url');
        setHid(urlParam);
    }, [])
    
    const onFresh = async () => {
        let res = hid && await testReportSource.fetchViewReport(hid);
        if (res) {
            setLoading(false);
            setData(res.data.data)
        }
    }

    return (
        <div>
            <div className="row wrapper white-bg page-heading mt-3 border-bottom">
                <div className='col-1'></div>   
                <div className="col-lg-2 text-center">
                    <img style={{ width:"200px" }} src={BillLogo}/>
                </div>   
                <div className="col-lg-7 align-items-center d-flex">
                    <div className='p-2'>
                        <h2>Hi {data?.patient_name}</h2>
                        <h5>Phone Number: {data?.contact_numbers}</h5>
                        <h5>Date: {data?.appointment_date}</h5>
                    </div>
                </div>
            </div>

            <Row>
                <Col className='mr-3 ml-3 pt-2'>
                    <div className="p-2 bg-white rounded  mt-3 mb-3">
                        <div className="p-2">
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Ref No</th>
                                        <th>Test</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data?.tests?.map((value , index) => {
                                            return <>
                                                <tr>
                                                    <td>{data?.ref_no}</td>
                                                    <td>{value?.name}</td>
                                                    <td>
                                                        {
                                                            value?.report ? 
                                                            <span className={"badge badge-success"}>Completed</span>
                                                            :
                                                            <span className={"badge badge-warning"}>Pending</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            value?.report ? 
                                                            <a href={value?.report} className={"btn btn-primary"} >Download</a>
                                                            :
                                                            <a className={"btn btn-secondary"} disabled={true}>Download</a>
                                                        }

                                                    </td>
                                                </tr>
                                            </>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ViewTestReport