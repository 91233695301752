export const productsSelectActionsConstants = {


    GET_SEARCH_PRODUCTS: 'GET_SEARCH_PRODUCTS',
    GET_SEARCH_PRODUCTS_PENDING: 'GET_SEARCH_PRODUCTS_PENDING',
    GET_SEARCH_PRODUCTS_FULFILLED: 'GET_SEARCH_PRODUCTS_FULFILLED',
    GET_SEARCH_PRODUCTS_FAILURE: 'GET_SEARCH_PRODUCTS_FAILURE',
    GET_SEARCH_PRODUCTS_REJECTED: 'GET_SEARCH_PRODUCTS_REJECTED',


    GET_SEARCH_PRODUCT_BATCHES: 'GET_SEARCH_PRODUCT_BATCHES',
    GET_SEARCH_PRODUCT_BATCHES_PENDING: 'GET_SEARCH_PRODUCT_BATCHES_PENDING',
    GET_SEARCH_PRODUCT_BATCHES_FULFILLED: 'GET_SEARCH_PRODUCT_BATCHES_FULFILLED',
    GET_SEARCH_PRODUCT_BATCHES_FAILURE: 'GET_SEARCH_PRODUCT_BATCHES_FAILURE',
    GET_SEARCH_PRODUCT_BATCHES_REJECTED: 'GET_SEARCH_PRODUCT_BATCHES_REJECTED',


};
