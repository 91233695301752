export const testTypesSelectActionsConstants = {


    GET_SEARCH_TEST_TYPE: 'GET_SEARCH_TEST_TYPE',
    GET_SEARCH_TEST_TYPE_PENDING: 'GET_SEARCH_TEST_TYPE_PENDING',
    GET_SEARCH_TEST_TYPE_FULFILLED: 'GET_SEARCH_TEST_TYPE_FULFILLED',
    GET_SEARCH_TEST_TYPE_FAILURE: 'GET_SEARCH_TEST_TYPE_FAILURE',
    GET_SEARCH_TEST_TYPE_REJECTED: 'GET_SEARCH_TEST_TYPE_REJECTED',


};
