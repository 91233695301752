import { homeConstants } from "./constants"

export default function serviceHomeReducer(state={
    services: [],
    doctors : [],
    loading:{
        getAllServices:false,
        getServiceCalender:false,
    },
    saved:{
    }
  }, action) {

    switch (action.type) {

        case homeConstants.GET_ALL_SERVICES_PENDING : {
            return {...state,loading:{...state.loading , getAllServices:true ,getServiceCalender:true},}
        }  
        case  homeConstants.GET_ALL_SERVICES_FULFILLED: {
            return {...state,loading:{...state.loading , getAllServices:false ,getServiceCalender:false} , services:action?.payload?.data?.data}
        }    
        case homeConstants.GET_ALL_SERVICES_FAILURE :  {
            return {...state, loading:{...state.loading , getAllServices:false ,getServiceCalender:false} ,error:action.error}
        }
        case homeConstants.GET_ALL_SERVICES_REJECTED :  {
            return {...state, loading:{...state.loading , getAllServices:false ,getServiceCalender:true} ,error:action.error}
        }


        case homeConstants.GET_ALL_SERVICE_DOCTOR_PENDING : {
            return {...state,loading:{...state.loading , getAllServicesDoctor:true },}
        }  
        case  homeConstants.GET_ALL_SERVICE_DOCTOR_FULFILLED: {
            return {...state,loading:{...state.loading , getAllServicesDoctor:false } , serviceDoctor:action?.payload?.data?.data}
        }    
        case homeConstants.GET_ALL_SERVICE_DOCTOR_FAILURE :  {
            return {...state, loading:{...state.loading , getAllServicesDoctor:false } ,error:action.error}
        }
        case homeConstants.GET_ALL_SERVICE_DOCTOR_REJECTED :  {
            return {...state, loading:{...state.loading , getAllServicesDoctor:false } ,error:action.error}
        }



        case homeConstants.GET_ALL_SERVICE_TECHNICIAN_PENDING : {
            return {...state,loading:{...state.loading , getAllServicesTechnician:true },}
        }  
        case  homeConstants.GET_ALL_SERVICE_TECHNICIAN_FULFILLED: {
            return {...state,loading:{...state.loading , getAllServicesTechnician:false } , serviceTechnician:action?.payload?.data?.data}
        }    
        case homeConstants.GET_ALL_SERVICE_TECHNICIAN_FAILURE :  {
            return {...state, loading:{...state.loading , getAllServicesTechnician:false } ,error:action.error}
        }
        case homeConstants.GET_ALL_SERVICE_TECHNICIAN_REJECTED :  {
            return {...state, loading:{...state.loading , getAllServicesTechnician:false } ,error:action.error}
        }


        case homeConstants.GET_CALENDER_DATA_FROM_SERVICE_PENDING : {
            return {...state,loading:{...state.loading , getServiceCalender:true},}
        }  
        case  homeConstants.GET_CALENDER_DATA_FROM_SERVICE_FULFILLED: {
            return {...state,loading:{...state.loading , getServiceCalender:false} , servicesCalender:action?.payload?.data?.data}
        }    
        case homeConstants.GET_CALENDER_DATA_FROM_SERVICE_FAILURE :  {
            return {...state, loading:{...state.loading , getServiceCalender:false} ,error:action.error}
        }
        case homeConstants.GET_CALENDER_DATA_FROM_SERVICE_REJECTED :  {
            return {...state, loading:{...state.loading , getServiceCalender:false} ,error:action.error}
        }


        case homeConstants.SAVE_CONFIRM_SERVICE_TIME_SLOT_PENDING : {
            return {...state,loading:{...state.loading , confirmTimeSlotSave:true},}
        }  
        case  homeConstants.SAVE_CONFIRM_SERVICE_TIME_SLOT_FULFILLED: {
            return {...state,loading:{...state.loading , confirmTimeSlotSave:false} , serviceSavedTimeSlot:action?.payload?.data?.data}
        }    
        case homeConstants.SAVE_CONFIRM_SERVICE_TIME_SLOT_FAILURE :  {
            return {...state, loading:{...state.loading , confirmTimeSlotSave:false} ,error:action.error}
        }
        case homeConstants.SAVE_CONFIRM_SERVICE_TIME_SLOT_REJECTED :  {
            return {...state, loading:{...state.loading , confirmTimeSlotSave:false} ,error:action.error}
        }










        case homeConstants.GET_SPECIALIZATION_PENDING : {
            return {...state,loading:true, user: action.payload}
        }  
        case  homeConstants.GET_SPECIALIZATION_FULFILLED: {
            return {...state,loading: false,fetched: false, specialization:action?.payload?.data?.data}
        }    
        case homeConstants.GET_SPECIALIZATION_FAILURE :  {
            return {...state, loading: false,error:action.error}
        }


        case homeConstants.GET_SPECIALIZED_DOCTOR_PENDING : {
            return {...state,loading:true, user: action.payload}
        }  
        case  homeConstants.GET_SPECIALIZED_DOCTOR_FULFILLED: {
            return {...state,loading: false,fetched: false, doctor:action?.payload?.data?.data}
        }    
        case homeConstants.GET_SPECIALIZED_DOCTOR_FAILURE :  {
            return {...state, loading: false,error:action.error}
        }
        case homeConstants.GET_SEARCHED_DOCTOR_PENDING : {
            return {...state,loading:true, user: action.payload}
        }  
        case  homeConstants.GET_SEARCHED_DOCTOR_FULFILLED: {
            return {...state,loading: false,fetched: false, doctor:action?.payload?.data?.data}
        }    
        case homeConstants.GET_SEARCHED_DOCTOR_FAILURE :  {
            return {...state, loading: false,error:action.error}
        }

        case homeConstants.GET_SPECIALIZED_DOCTOR_SCHEDULE_PENDING : {
            return {...state,loading:true, user: action.payload}
        }  
        case  homeConstants.GET_SPECIALIZED_DOCTOR_SCHEDULE_FULFILLED: {
            return {...state,loading: false,fetched: false, schedule:action?.payload?.data?.data}
        }    
        case homeConstants.GET_SPECIALIZED_DOCTOR_SCHEDULE_FAILURE :  {
            return {...state, loading: false,error:action.error}
        }
        
        case homeConstants.GET_SPECIALIZED_SCHEDULE_PENDING : {
            return {...state,loading:true, user: action.payload}
        }  
        case  homeConstants.GET_SPECIALIZED_SCHEDULE_FULFILLED: {
            return {...state,loading: false,fetched: false, schedule:action?.payload?.data?.data}
        }    
        case homeConstants.GET_SPECIALIZED_SCHEDULE_FAILURE :  {
            return {...state, loading: false,error:action.error}
        }

        case homeConstants.GET_SPECIALIZED_DOCTOR_APPOINTMENTS_PENDING : {
            return {...state,loading:true, user: action.payload}
        }  
        case  homeConstants.GET_SPECIALIZED_DOCTOR_APPOINTMENTS_FULFILLED: {
            return {...state,loading: false,fetched: false, appointments:action?.payload?.data?.data}
        }    
        case homeConstants.GET_SPECIALIZED_DOCTOR_APPOINTMENTS_FAILURE :  {
            return {...state, loading: false,error:action.error}
        }

        case homeConstants.GET_APPOINTMENTS_PENDING : {
            return {...state,loading:true, user: action.payload}
        }  
        case  homeConstants.GET_APPOINTMENTS_FULFILLED: {
            return {...state,loading: false,fetched: false, appointments:action?.payload?.data?.data}
        }    
        case homeConstants.GET_APPOINTMENTS_FAILURE :  {
            return {...state, loading: false,error:action.error}
        }
        


        case homeConstants.GET_DOCTORS_AVAILABLITIES_PENDING : {
            return {...state,loading:true, user: action.payload}
        }  
        case  homeConstants.GET_DOCTORS_AVAILABLITIES_FULFILLED: {
            return {...state,loading: false,fetched: false, availablities:action?.payload?.data?.data}
        }    
        case homeConstants.GET_DOCTORS_AVAILABLITIES_FAILURE :  {
            return {...state, loading: false,error:action.error}
        }

        case homeConstants. GET_DOCOTORS_APPOINTMENTS_FOR_TIMESLOT_PENDING : {
            return {...state,loading:true, user: action.payload}
        }  
        case  homeConstants.GET_DOCOTORS_APPOINTMENTS_FOR_TIMESLOT_FULFILLED: {
            return {...state,loading: false,fetched: false, timeSlotData:action?.payload?.data?.data}
        }    
        case homeConstants.GET_DOCOTORS_APPOINTMENTS_FOR_TIMESLOT_FAILURE :  {
            return {...state, loading: false,error:action.error}
        }


        case homeConstants.GET_DOCTOR_FOR_SERVICE_PENDING : {
            return {...state,loading:true, user: action.payload}
        }  
        case  homeConstants.GET_DOCTOR_FOR_SERVICE_FULFILLED: {
            return {...state,loading: false,fetched: false, doctors:action?.payload?.data?.data}
        }    
        case homeConstants.GET_DOCTOR_FOR_SERVICE_FAILURE :  {
            return {...state, loading: false,error:action.error}
        }

        default :
            return state;
    }

}
