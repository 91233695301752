import { messagesEnum } from "../components/Enums/messagesEnum";
import toastr from "toastr";
import { backURL } from "./apptimedRequest";

export function exceptionHandling(rejectedResponse) {
console.log("🚀 ~ file: exceprtionHandling.js ~ line 2 ~ exceptionHandling ~ rejectedResponse", rejectedResponse)
    
    if(rejectedResponse === undefined || rejectedResponse === null ){
        return "Unable process data";
    }
    
    if(rejectedResponse.data === undefined || rejectedResponse.data === null ){
        return "Unable process data";
    }

    if(rejectedResponse.status === undefined || rejectedResponse.status === null ){
        return "Unable process data";
    }
    if(rejectedResponse.status === 502 || rejectedResponse.status === null ){
        return "Server Under Down";
    }

    if(rejectedResponse.status === 417  ){
        return {msg:rejectedResponse.data.msg , error:rejectedResponse.data.error};
    }

    if(rejectedResponse.status === 400  ){
        return {msg:rejectedResponse.data.msg , error:rejectedResponse.data.error};
    }

    if(rejectedResponse.status === 404  ){
        return rejectedResponse.data.msg;
    }

    if(rejectedResponse.status === 401  ){
        toastr.error(messagesEnum.ERROR , messagesEnum.SESSION_OUT)
        window.location.assign(backURL)
        return rejectedResponse.data.msg;
    }


}
