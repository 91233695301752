export const labApprovalCheckedConstants = {


    GET_ALL_MY_LAB_APPROVAL_CHECKED: 'GET_ALL_MY_LAB_APPROVAL_CHECKED',
    GET_ALL_MY_LAB_APPROVAL_CHECKED_PENDING: 'GET_ALL_MY_LAB_APPROVAL_CHECKED_PENDING',
    GET_ALL_MY_LAB_APPROVAL_CHECKED_FULFILLED: 'GET_ALL_MY_LAB_APPROVAL_CHECKED_FULFILLED',
    GET_ALL_MY_LAB_APPROVAL_CHECKED_FAILURE: 'GET_ALL_MY_LAB_APPROVAL_CHECKED_FAILURE',
    GET_ALL_MY_LAB_APPROVAL_CHECKED_REJECTED: 'GET_ALL_MY_LAB_APPROVAL_CHECKED_REJECTED',


    GET_ALL_OTHERS_LAB_APPROVAL_CHECKED: 'GET_ALL_OTHERS_LAB_APPROVAL_CHECKED',
    GET_ALL_OTHERS_LAB_APPROVAL_CHECKED_PENDING: 'GET_ALL_OTHERS_LAB_APPROVAL_CHECKED_PENDING',
    GET_ALL_OTHERS_LAB_APPROVAL_CHECKED_FULFILLED: 'GET_ALL_OTHERS_LAB_APPROVAL_CHECKED_FULFILLED',
    GET_ALL_OTHERS_LAB_APPROVAL_CHECKED_FAILURE: 'GET_ALL_OTHERS_LAB_APPROVAL_CHECKED_FAILURE',
    GET_ALL_OTHERS_LAB_APPROVAL_CHECKED_REJECTED: 'GET_ALL_OTHERS_LAB_APPROVAL_CHECKED_REJECTED',

    GET_LAB_DEPARTMENT: 'GET_LAB_DEPARTMENT',
    GET_LAB_DEPARTMENT_PENDING: 'GET_LAB_DEPARTMENT_PENDING',
    GET_LAB_DEPARTMENT_FULFILLED: 'GET_LAB_DEPARTMENT_FULFILLED',
    GET_LAB_DEPARTMENT_FAILURE: 'GET_LAB_DEPARTMENT_FAILURE',
    GET_LAB_DEPARTMENT_REJECTED: 'GET_LAB_DEPARTMENT_REJECTED',

    SAVE_LAB_APPROVAL_CHECKED: 'SAVE_LAB_APPROVAL_CHECKED',
    SAVE_LAB_APPROVAL_CHECKED_PENDING: 'SAVE_LAB_APPROVAL_CHECKED_PENDING',
    SAVE_LAB_APPROVAL_CHECKED_FULFILLED: 'SAVE_LAB_APPROVAL_CHECKED_FULFILLED',
    SAVE_LAB_APPROVAL_CHECKED_FAILURE: 'SAVE_LAB_APPROVAL_CHECKED_FAILURE',
    SAVE_LAB_APPROVAL_CHECKED_REJECTED: 'SAVE_LAB_APPROVAL_CHECKED_REJECTED',

    SAVE_LAB_FALSE : 'SAVE_LAB_FALSE',

};
