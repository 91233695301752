export const appointmentConstants = {




    GET_ALL_APPOINTMENTS : 'GET_ALL_APPOINTMENTS',
    GET_ALL_APPOINTMENTS_PENDING : 'GET_ALL_APPOINTMENTS_PENDING',
    GET_ALL_APPOINTMENTS_FULFILLED : 'GET_ALL_APPOINTMENTS_FULFILLED',
    GET_ALL_APPOINTMENTS_FAILURE : 'GET_ALL_APPOINTMENTS_FAILURE',
    GET_ALL_APPOINTMENTS_REJECTED : 'GET_ALL_APPOINTMENTS_REJECTED',

    GET_ONE_APPOINTMENT_DETAILS : 'GET_ONE_APPOINTMENT_DETAILS',
    GET_ONE_APPOINTMENT_DETAILS_PENDING : 'GET_ONE_APPOINTMENT_DETAILS_PENDING',
    GET_ONE_APPOINTMENT_DETAILS_FULFILLED : 'GET_ONE_APPOINTMENT_DETAILS_FULFILLED',
    GET_ONE_APPOINTMENT_DETAILS_FAILURE : 'GET_ONE_APPOINTMENT_DETAILS_FAILURE',
    GET_ONE_APPOINTMENT_DETAILS_REJECTED : 'GET_ONE_APPOINTMENT_DETAILS_REJECTED',

    GET_ALL_IPD_PATIENT_ADD_APPOINTMENT : 'GET_ALL_IPD_PATIENT_ADD_APPOINTMENT',
    GET_ALL_IPD_PATIENT_ADD_APPOINTMENT_PENDING : 'GET_ALL_IPD_PATIENT_ADD_APPOINTMENT_PENDING',
    GET_ALL_IPD_PATIENT_ADD_APPOINTMENT_FULFILLED : 'GET_ALL_IPD_PATIENT_ADD_APPOINTMENT_FULFILLED',
    GET_ALL_IPD_PATIENT_ADD_APPOINTMENT_FAILURE : 'GET_ALL_IPD_PATIENT_ADD_APPOINTMENT_FAILURE',
    GET_ALL_IPD_PATIENT_ADD_APPOINTMENT_REJECTED : 'GET_ALL_IPD_PATIENT_ADD_APPOINTMENT_REJECTED',


    GET_ALL_TEST_ADD_APPOINTMENT : 'GET_ALL_TEST_ADD_APPOINTMENT',
    GET_ALL_TEST_ADD_APPOINTMENT_PENDING : 'GET_ALL_TEST_ADD_APPOINTMENT_PENDING',
    GET_ALL_TEST_ADD_APPOINTMENT_FULFILLED : 'GET_ALL_TEST_ADD_APPOINTMENT_FULFILLED',
    GET_ALL_TEST_ADD_APPOINTMENT_FAILURE : 'GET_ALL_TEST_ADD_APPOINTMENT_FAILURE',
    GET_ALL_TEST_ADD_APPOINTMENT_REJECTED : 'GET_ALL_TEST_ADD_APPOINTMENT_REJECTED',

    GET_LAB_TEST_DETAILS : 'GET_LAB_TEST_DETAILS',
    GET_LAB_TEST_DETAILS_PENDING : 'GET_LAB_TEST_DETAILS_PENDING',
    GET_LAB_TEST_DETAILS_FULFILLED : 'GET_LAB_TEST_DETAILS_FULFILLED',
    GET_LAB_TEST_DETAILS_FAILURE : 'GET_LAB_TEST_DETAILS_FAILURE',
    GET_LAB_TEST_DETAILS_REJECTED : 'GET_LAB_TEST_DETAILS_REJECTED',

    GET_LAB_TEST_SAMPLE_DETAILS : 'GET_LAB_TEST_SAMPLE_DETAILS',
    GET_LAB_TEST_SAMPLE_DETAILS_PENDING : 'GET_LAB_TEST_SAMPLE_DETAILS_PENDING',
    GET_LAB_TEST_SAMPLE_DETAILS_FULFILLED : 'GET_LAB_TEST_SAMPLE_DETAILS_FULFILLED',
    GET_LAB_TEST_SAMPLE_DETAILS_FAILURE : 'GET_LAB_TEST_SAMPLE_DETAILS_FAILURE',
    GET_LAB_TEST_SAMPLE_DETAILS_REJECTED : 'GET_LAB_TEST_SAMPLE_DETAILS_REJECTED',

    GET_ONE_TEST_SAMPLE_DETAIL : 'GET_ONE_TEST_SAMPLE_DETAIL',
    GET_ONE_TEST_SAMPLE_DETAIL_PENDING : 'GET_ONE_TEST_SAMPLE_DETAIL_PENDING',
    GET_ONE_TEST_SAMPLE_DETAIL_FULFILLED : 'GET_ONE_TEST_SAMPLE_DETAIL_FULFILLED',
    GET_ONE_TEST_SAMPLE_DETAIL_FAILURE : 'GET_ONE_TEST_SAMPLE_DETAIL_FAILURE',
    GET_ONE_TEST_SAMPLE_DETAIL_REJECTED : 'GET_ONE_TEST_SAMPLE_DETAIL_REJECTED',

    GET_LAB_TEST_SAMPLE_REPORT_TEST_DETAILS : 'GET_LAB_TEST_SAMPLE_REPORT_TEST_DETAILS',
    GET_LAB_TEST_SAMPLE_REPORT_TEST_DETAILS_PENDING : 'GET_LAB_TEST_SAMPLE_REPORT_TEST_DETAILS_PENDING',
    GET_LAB_TEST_SAMPLE_REPORT_TEST_DETAILS_FULFILLED : 'GET_LAB_TEST_SAMPLE_REPORT_TEST_DETAILS_FULFILLED',
    GET_LAB_TEST_SAMPLE_REPORT_TEST_DETAILS_FAILURE : 'GET_LAB_TEST_SAMPLE_REPORT_TEST_DETAILS_FAILURE',
    GET_LAB_TEST_SAMPLE_REPORT_TEST_DETAILS_REJECTED : 'GET_LAB_TEST_SAMPLE_REPORT_TEST_DETAILS_REJECTED',

    SAVE_LAB_APPOINTMENT_START : 'SAVE_LAB_APPOINTMENT_START',
    SAVE_LAB_APPOINTMENT_START_PENDING : 'SAVE_LAB_APPOINTMENT_START_PENDING',
    SAVE_LAB_APPOINTMENT_START_FULFILLED : 'SAVE_LAB_APPOINTMENT_START_FULFILLED',
    SAVE_LAB_APPOINTMENT_START_FAILURE : 'SAVE_LAB_APPOINTMENT_START_FAILURE',
    SAVE_LAB_APPOINTMENT_START_REJECTED : 'SAVE_LAB_APPOINTMENT_START_REJECTED',

    SAVE_LAB_APPOINTMENT_END : 'SAVE_LAB_APPOINTMENT_END',
    SAVE_LAB_APPOINTMENT_END_PENDING : 'SAVE_LAB_APPOINTMENT_END_PENDING',
    SAVE_LAB_APPOINTMENT_END_FULFILLED : 'SAVE_LAB_APPOINTMENT_END_FULFILLED',
    SAVE_LAB_APPOINTMENT_END_FAILURE : 'SAVE_LAB_APPOINTMENT_END_FAILURE',
    SAVE_LAB_APPOINTMENT_END_REJECTED : 'SAVE_LAB_APPOINTMENT_END_REJECTED',

    GET_ALL_APPOINTMENTS_PENDING : 'GET_ALL_APPOINTMENTS_PENDING',
    GET_ALL_APPOINTMENTS_PENDING_PENDING : 'GET_ALL_APPOINTMENTS_PENDING_PENDING',
    GET_ALL_APPOINTMENTS_PENDING_FULFILLED : 'GET_ALL_APPOINTMENTS_PENDING_FULFILLED',
    GET_ALL_APPOINTMENTS_PENDING_FAILURE : 'GET_ALL_APPOINTMENTS_PENDING_FAILURE',
    GET_ALL_APPOINTMENTS_PENDING_REJECTED : 'GET_ALL_APPOINTMENTS_PENDING_REJECTED',

    UPDATE_APPOINTMENT_STATUS : 'UPDATE_APPOINTMENT_STATUS',
    UPDATE_APPOINTMENT_STATUS_PENDING : 'UPDATE_APPOINTMENT_STATUS_PENDING',
    UPDATE_APPOINTMENT_STATUS_FULFILLED : 'UPDATE_APPOINTMENT_STATUS_FULFILLED',
    UPDATE_APPOINTMENT_STATUS_FAILURE : 'UPDATE_APPOINTMENT_STATUS_FAILURE',
    UPDATE_APPOINTMENT_STATUS_REJECTED : 'UPDATE_APPOINTMENT_STATUS_REJECTED',

    CREATE_RETEST_FOR_THE_SAMPLE : 'CREATE_RETEST_FOR_THE_SAMPLE',
    CREATE_RETEST_FOR_THE_SAMPLE_PENDING : 'CREATE_RETEST_FOR_THE_SAMPLE_PENDING',
    CREATE_RETEST_FOR_THE_SAMPLE_FULFILLED : 'CREATE_RETEST_FOR_THE_SAMPLE_FULFILLED',
    CREATE_RETEST_FOR_THE_SAMPLE_FAILURE : 'CREATE_RETEST_FOR_THE_SAMPLE_FAILURE',
    CREATE_RETEST_FOR_THE_SAMPLE_REJECTED : 'CREATE_RETEST_FOR_THE_SAMPLE_REJECTED',

    GET_ALL_LAB_TECHNICIAN : 'GET_ALL_LAB_TECHNICIAN',
    GET_ALL_LAB_TECHNICIAN_PENDING : 'GET_ALL_LAB_TECHNICIAN_PENDING',
    GET_ALL_LAB_TECHNICIAN_FULFILLED : 'GET_ALL_LAB_TECHNICIAN_FULFILLED',
    GET_ALL_LAB_TECHNICIAN_FAILURE : 'GET_ALL_LAB_TECHNICIAN_FAILURE',
    GET_ALL_LAB_TECHNICIAN_REJECTED : 'GET_ALL_LAB_TECHNICIAN_REJECTED',


    GET_ALL_LAB_ITEMS : 'GET_ALL_LAB_ITEMS',
    GET_ALL_LAB_ITEMS_PENDING : 'GET_ALL_LAB_ITEMS_PENDING',
    GET_ALL_LAB_ITEMS_FULFILLED : 'GET_ALL_LAB_ITEMS_FULFILLED',
    GET_ALL_LAB_ITEMS_FAILURE : 'GET_ALL_LAB_ITEMS_FAILURE',
    GET_ALL_LAB_ITEMS_REJECTED : 'GET_ALL_LAB_ITEMS_REJECTED',

    GET_LAB_CENTER_ADD_APPOINTMENT : 'GET_LAB_CENTER_ADD_APPOINTMENT',
    GET_LAB_CENTER_ADD_APPOINTMENT_PENDING : 'GET_LAB_CENTER_ADD_APPOINTMENT_PENDING',
    GET_LAB_CENTER_ADD_APPOINTMENT_FULFILLED : 'GET_LAB_CENTER_ADD_APPOINTMENT_FULFILLED',
    GET_LAB_CENTER_ADD_APPOINTMENT_FAILURE : 'GET_LAB_CENTER_ADD_APPOINTMENT_FAILURE',
    GET_LAB_CENTER_ADD_APPOINTMENT_REJECTED : 'GET_LAB_CENTER_ADD_APPOINTMENT_REJECTED',

    GET_ALL_LAB_MACHINES : 'GET_ALL_LAB_MACHINES',
    GET_ALL_LAB_MACHINES_PENDING : 'GET_ALL_LAB_MACHINES_PENDING',
    GET_ALL_LAB_MACHINES_FULFILLED : 'GET_ALL_LAB_MACHINES_FULFILLED',
    GET_ALL_LAB_MACHINES_FAILURE : 'GET_ALL_LAB_MACHINES_FAILURE',
    GET_ALL_LAB_MACHINES_REJECTED : 'GET_ALL_LAB_MACHINES_REJECTED',

    SAVE_LAB_TEST_SAMPLE : 'SAVE_LAB_TEST_SAMPLE',
    SAVE_LAB_TEST_SAMPLE_PENDING : 'SAVE_LAB_TEST_SAMPLE_PENDING',
    SAVE_LAB_TEST_SAMPLE_FULFILLED : 'SAVE_LAB_TEST_SAMPLE_FULFILLED',
    SAVE_LAB_TEST_SAMPLE_FAILURE : 'SAVE_LAB_TEST_SAMPLE_FAILURE',
    SAVE_LAB_TEST_SAMPLE_REJECTED : 'SAVE_LAB_TEST_SAMPLE_REJECTED',


    SAVE_LAB_HR_USAGE : 'SAVE_LAB_HR_USAGE',
    SAVE_LAB_HR_USAGE_PENDING : 'SAVE_LAB_HR_USAGE_PENDING',
    SAVE_LAB_HR_USAGE_FULFILLED : 'SAVE_LAB_HR_USAGE_FULFILLED',
    SAVE_LAB_HR_USAGE_FAILURE : 'SAVE_LAB_HR_USAGE_FAILURE',
    SAVE_LAB_HR_USAGE_REJECTED : 'SAVE_LAB_HR_USAGE_REJECTED',


    SAVE_LAB_ITEM_USAGE : 'SAVE_LAB_ITEM_USAGE',
    SAVE_LAB_ITEM_USAGE_PENDING : 'SAVE_LAB_ITEM_USAGE_PENDING',
    SAVE_LAB_ITEM_USAGE_FULFILLED : 'SAVE_LAB_ITEM_USAGE_FULFILLED',
    SAVE_LAB_ITEM_USAGE_FAILURE : 'SAVE_LAB_ITEM_USAGE_FAILURE',
    SAVE_LAB_ITEM_USAGE_REJECTED : 'SAVE_LAB_ITEM_USAGE_REJECTED',


    SAVE_LAB_MACHINES : 'SAVE_LAB_MACHINES',
    SAVE_LAB_MACHINES_PENDING : 'SAVE_LAB_MACHINES_PENDING',
    SAVE_LAB_MACHINES_FULFILLED : 'SAVE_LAB_MACHINES_FULFILLED',
    SAVE_LAB_MACHINES_FAILURE : 'SAVE_LAB_MACHINES_FAILURE',
    SAVE_LAB_MACHINES_REJECTED : 'SAVE_LAB_MACHINES_REJECTED',

    SAVE_LAB_REPORT : 'SAVE_LAB_REPORT',
    SAVE_LAB_REPORT_PENDING : 'SAVE_LAB_REPORT_PENDING',
    SAVE_LAB_REPORT_FULFILLED : 'SAVE_LAB_REPORT_FULFILLED',
    SAVE_LAB_REPORT_FAILURE : 'SAVE_LAB_REPORT_FAILURE',
    SAVE_LAB_REPORT_REJECTED : 'SAVE_LAB_REPORT_REJECTED',

    EDIT_LAB_REPORT : 'EDIT_LAB_REPORT',
    EDIT_LAB_REPORT_PENDING : 'EDIT_LAB_REPORT_PENDING',
    EDIT_LAB_REPORT_FULFILLED : 'EDIT_LAB_REPORT_FULFILLED',
    EDIT_LAB_REPORT_FAILURE : 'EDIT_LAB_REPORT_FAILURE',
    EDIT_LAB_REPORT_REJECTED : 'EDIT_LAB_REPORT_REJECTED',

    SAVE_LAB_ATTACHMENT_REPORT : 'SAVE_LAB_ATTACHMENT_REPORT',
    SAVE_LAB_ATTACHMENT_REPORT_PENDING : 'SAVE_LAB_ATTACHMENT_REPORT_PENDING',
    SAVE_LAB_ATTACHMENT_REPORT_FULFILLED : 'SAVE_LAB_ATTACHMENT_REPORT_FULFILLED',
    SAVE_LAB_ATTACHMENT_REPORT_FAILURE : 'SAVE_LAB_ATTACHMENT_REPORT_FAILURE',
    SAVE_LAB_ATTACHMENT_REPORT_REJECTED : 'SAVE_LAB_ATTACHMENT_REPORT_REJECTED',

    SAVE_TEST_SAMPLE_TRANSFER_IN : 'SAVE_TEST_SAMPLE_TRANSFER_IN',
    SAVE_TEST_SAMPLE_TRANSFER_IN_PENDING : 'SAVE_TEST_SAMPLE_TRANSFER_IN_PENDING',
    SAVE_TEST_SAMPLE_TRANSFER_IN_FULFILLED : 'SAVE_TEST_SAMPLE_TRANSFER_IN_FULFILLED',
    SAVE_TEST_SAMPLE_TRANSFER_IN_FAILURE : 'SAVE_TEST_SAMPLE_TRANSFER_IN_FAILURE',
    SAVE_TEST_SAMPLE_TRANSFER_IN_REJECTED : 'SAVE_TEST_SAMPLE_TRANSFER_IN_REJECTED',

    SAVE_TEST_SAMPLE_TRANSFER_OUT : 'SAVE_TEST_SAMPLE_TRANSFER_OUT',
    SAVE_TEST_SAMPLE_TRANSFER_OUT_PENDING : 'SAVE_TEST_SAMPLE_TRANSFER_OUT_PENDING',
    SAVE_TEST_SAMPLE_TRANSFER_OUT_FULFILLED : 'SAVE_TEST_SAMPLE_TRANSFER_OUT_FULFILLED',
    SAVE_TEST_SAMPLE_TRANSFER_OUT_FAILURE : 'SAVE_TEST_SAMPLE_TRANSFER_OUT_FAILURE',
    SAVE_TEST_SAMPLE_TRANSFER_OUT_REJECTED : 'SAVE_TEST_SAMPLE_TRANSFER_OUT_REJECTED',


    GET_PATIENTS_FOR_ADD_APPOINTMENT : 'GET_PATIENTS_FOR_ADD_APPOINTMENT',
    GET_PATIENTS_FOR_ADD_APPOINTMENT_PENDING : 'GET_PATIENTS_FOR_ADD_APPOINTMENT_PENDING',
    GET_PATIENTS_FOR_ADD_APPOINTMENT_FULFILLED : 'GET_PATIENTS_FOR_ADD_APPOINTMENT_FULFILLED',
    GET_PATIENTS_FOR_ADD_APPOINTMENT_FAILURE : 'GET_PATIENTS_FOR_ADD_APPOINTMENT_FAILURE',
    GET_PATIENTS_FOR_ADD_APPOINTMENT_REJECTED : 'GET_PATIENTS_FOR_ADD_APPOINTMENT_REJECTED',

    GET_LABS_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE : 'GET_LABS_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE',
    GET_LABS_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE_PENDING : 'GET_LABS_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE_PENDING',
    GET_LABS_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE_FULFILLED : 'GET_LABS_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE_FULFILLED',
    GET_LABS_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE_FAILURE : 'GET_LABS_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE_FAILURE',
    GET_LABS_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE_REJECTED : 'GET_LABS_TIME_SLOTS_APPOINTMENT_PATIENT_RESCHEDULE_REJECTED',

    GET_GUARDIAN_FOR_ADD_APPOINTMENT : 'GET_GUARDIAN_FOR_ADD_APPOINTMENT',
    GET_GUARDIAN_FOR_ADD_APPOINTMENT_PENDING : 'GET_GUARDIAN_FOR_ADD_APPOINTMENT_PENDING',
    GET_GUARDIAN_FOR_ADD_APPOINTMENT_FULFILLED : 'GET_GUARDIAN_FOR_ADD_APPOINTMENT_FULFILLED',
    GET_GUARDIAN_FOR_ADD_APPOINTMENT_FAILURE : 'GET_GUARDIAN_FOR_ADD_APPOINTMENT_FAILURE',
    GET_GUARDIAN_FOR_ADD_APPOINTMENT_REJECTED : 'GET_GUARDIAN_FOR_ADD_APPOINTMENT_REJECTED',

    GET_REFERRED_DOCTOR_ADD_APPOINTMENT : 'GET_REFERRED_DOCTOR_ADD_APPOINTMENT',
    GET_REFERRED_DOCTOR_ADD_APPOINTMENT_PENDING : 'GET_REFERRED_DOCTOR_ADD_APPOINTMENT_PENDING',
    GET_REFERRED_DOCTOR_ADD_APPOINTMENT_FULFILLED : 'GET_REFERRED_DOCTOR_ADD_APPOINTMENT_FULFILLED',
    GET_REFERRED_DOCTOR_ADD_APPOINTMENT_FAILURE : 'GET_REFERRED_DOCTOR_ADD_APPOINTMENT_FAILURE',
    GET_REFERRED_DOCTOR_ADD_APPOINTMENT_REJECTED : 'GET_REFERRED_DOCTOR_ADD_APPOINTMENT_REJECTED',

    GET_REFERRED_CENTER_ADD_APPOINTMENT : 'GET_REFERRED_CENTER_ADD_APPOINTMENT',
    GET_REFERRED_CENTER_ADD_APPOINTMENT_PENDING : 'GET_REFERRED_CENTER_ADD_APPOINTMENT_PENDING',
    GET_REFERRED_CENTER_ADD_APPOINTMENT_FULFILLED : 'GET_REFERRED_CENTER_ADD_APPOINTMENT_FULFILLED',
    GET_REFERRED_CENTER_ADD_APPOINTMENT_FAILURE : 'GET_REFERRED_CENTER_ADD_APPOINTMENT_FAILURE',
    GET_REFERRED_CENTER_ADD_APPOINTMENT_REJECTED : 'GET_REFERRED_CENTER_ADD_APPOINTMENT_REJECTED',

    GET_ALL_LABS_ADD_APPOINTMENT : 'GET_ALL_LABS_ADD_APPOINTMENT',
    GET_ALL_LABS_ADD_APPOINTMENT_PENDING : 'GET_ALL_LABS_ADD_APPOINTMENT_PENDING',
    GET_ALL_LABS_ADD_APPOINTMENT_FULFILLED : 'GET_ALL_LABS_ADD_APPOINTMENT_FULFILLED',
    GET_ALL_LABS_ADD_APPOINTMENT_FAILURE : 'GET_ALL_LABS_ADD_APPOINTMENT_FAILURE',
    GET_ALL_LABS_ADD_APPOINTMENT_REJECTED : 'GET_ALL_LABS_ADD_APPOINTMENT_REJECTED',

    GET_LABS_TIME_SLOTS_ADD_APPOINTMENT : 'GET_LABS_TIME_SLOTS_ADD_APPOINTMENT',
    GET_LABS_TIME_SLOTS_ADD_APPOINTMENT_PENDING : 'GET_LABS_TIME_SLOTS_ADD_APPOINTMENT_PENDING',
    GET_LABS_TIME_SLOTS_ADD_APPOINTMENT_FULFILLED : 'GET_LABS_TIME_SLOTS_ADD_APPOINTMENT_FULFILLED',
    GET_LABS_TIME_SLOTS_ADD_APPOINTMENT_FAILURE : 'GET_LABS_TIME_SLOTS_ADD_APPOINTMENT_FAILURE',
    GET_LABS_TIME_SLOTS_ADD_APPOINTMENT_REJECTED : 'GET_LABS_TIME_SLOTS_ADD_APPOINTMENT_REJECTED',

    GET_LABS_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT : 'GET_LABS_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT',
    GET_LABS_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_PENDING : 'GET_LABS_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_PENDING',
    GET_LABS_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_FULFILLED : 'GET_LABS_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_FULFILLED',
    GET_LABS_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_FAILURE : 'GET_LABS_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_FAILURE',
    GET_LABS_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_REJECTED : 'GET_LABS_TIME_SLOTS_APPOINTMENT_ADD_APPOINTMENT_REJECTED',

    SAVE_APPOINTMENT : 'SAVE_APPOINTMENT',
    SAVE_APPOINTMENT_PENDING : 'SAVE_APPOINTMENT_PENDING',
    SAVE_APPOINTMENT_FULFILLED : 'SAVE_APPOINTMENT_FULFILLED',
    SAVE_APPOINTMENT_FAILURE : 'SAVE_APPOINTMENT_FAILURE',
    SAVE_APPOINTMENT_REJECTED : 'SAVE_APPOINTMENT_REJECTED',

    SAVE_LAB_APPOINTMENT_DELIVERED_REPORT : 'SAVE_LAB_APPOINTMENT_DELIVERED_REPORT',
    SAVE_LAB_APPOINTMENT_DELIVERED_REPORT_PENDING : 'SAVE_LAB_APPOINTMENT_DELIVERED_REPORT_PENDING',
    SAVE_LAB_APPOINTMENT_DELIVERED_REPORT_FULFILLED : 'SAVE_LAB_APPOINTMENT_DELIVERED_REPORT_FULFILLED',
    SAVE_LAB_APPOINTMENT_DELIVERED_REPORT_FAILURE : 'SAVE_LAB_APPOINTMENT_DELIVERED_REPORT_FAILURE',
    SAVE_LAB_APPOINTMENT_DELIVERED_REPORT_REJECTED : 'SAVE_LAB_APPOINTMENT_DELIVERED_REPORT_REJECTED',


    GET_MAIN_APPOINTMENT : 'GET_MAIN_APPOINTMENT',
    GET_MAIN_APPOINTMENT_PENDING : 'GET_MAIN_APPOINTMENT_PENDING',
    GET_MAIN_APPOINTMENT_FULFILLED : 'GET_MAIN_APPOINTMENT_FULFILLED',
    GET_MAIN_APPOINTMENT_FAILURE : 'GET_MAIN_APPOINTMENT_FAILURE',
    GET_MAIN_APPOINTMENT_REJECTED : 'GET_MAIN_APPOINTMENT_REJECTED',

    GET_LAB_APPOINTMENT_LAB : 'GET_LAB_APPOINTMENT_LAB',
    GET_LAB_APPOINTMENT_LAB_PENDING : 'GET_LAB_APPOINTMENT_LAB_PENDING',
    GET_LAB_APPOINTMENT_LAB_FULFILLED : 'GET_LAB_APPOINTMENT_LAB_FULFILLED',
    GET_LAB_APPOINTMENT_LAB_FAILURE : 'GET_LAB_APPOINTMENT_LAB_FAILURE',
    GET_LAB_APPOINTMENT_LAB_REJECTED : 'GET_LAB_APPOINTMENT_LAB_REJECTED',

    SAVE_LAB_APPOINTMENT_FINISH : 'SAVE_LAB_APPOINTMENT_FINISH',
    SAVE_LAB_APPOINTMENT_FINISH_PENDING : 'SAVE_LAB_APPOINTMENT_FINISH_PENDING',
    SAVE_LAB_APPOINTMENT_FINISH_FULFILLED : 'SAVE_LAB_APPOINTMENT_FINISH_FULFILLED',
    SAVE_LAB_APPOINTMENT_FINISH_FAILURE : 'SAVE_LAB_APPOINTMENT_FINISH_FAILURE',
    SAVE_LAB_APPOINTMENT_FINISH_REJECTED : 'SAVE_LAB_APPOINTMENT_FINISH_REJECTED',


    SAVE_FALSE : 'SAVE_FALSE',
    SAVE_LAB_FALSE : 'SAVE_LAB_FALSE',
    LAB_APPOINTMENT_STATUS_CHANGE : 'LAB_APPOINTMENT_STATUS_CHANGE',

    GET_ALL_TEST_ATTRIBUTES : 'GET_ALL_TEST_ATTRIBUTES',
    GET_ALL_TEST_ATTRIBUTES_PENDING : 'GET_ALL_TEST_ATTRIBUTES_PENDING',
    GET_ALL_TEST_ATTRIBUTES_FULFILLED : 'GET_ALL_TEST_ATTRIBUTES_FULFILLED',
    GET_ALL_TEST_ATTRIBUTES_FAILURE : 'GET_ALL_TEST_ATTRIBUTES_FAILURE',
    GET_ALL_TEST_ATTRIBUTES_REJECTED : 'GET_ALL_TEST_ATTRIBUTES_REJECTED',

    EDIT_TEST_REPORT_ATTRIBUTE : 'EDIT_TEST_REPORT_ATTRIBUTE',
    EDIT_TEST_REPORT_ATTRIBUTE_PENDING : 'EDIT_TEST_REPORT_ATTRIBUTE_PENDING',
    EDIT_TEST_REPORT_ATTRIBUTE_FULFILLED : 'EDIT_TEST_REPORT_ATTRIBUTE_FULFILLED',
    EDIT_TEST_REPORT_ATTRIBUTE_FAILURE : 'EDIT_TEST_REPORT_ATTRIBUTE_FAILURE',
    EDIT_TEST_REPORT_ATTRIBUTE_REJECTED : 'EDIT_TEST_REPORT_ATTRIBUTE_REJECTED',

    GET_ALL_TEST_FEES : 'GET_ALL_TEST_FEES',
    GET_ALL_TEST_FEES_PENDING : 'GET_ALL_TEST_FEES_PENDING',
    GET_ALL_TEST_FEES_FULFILLED : 'GET_ALL_TEST_FEES_FULFILLED',
    GET_ALL_TEST_FEES_FAILURE : 'GET_ALL_TEST_FEES_FAILURE',
    GET_ALL_TEST_FEES_REJECTED : 'GET_ALL_TEST_FEES_REJECTED',

    GET_APPOINTMENTS_MAIN_REPORTS : 'GET_APPOINTMENTS_MAIN_REPORTS',
    GET_APPOINTMENTS_MAIN_REPORTS_PENDING : 'GET_APPOINTMENTS_MAIN_REPORTS_PENDING',
    GET_APPOINTMENTS_MAIN_REPORTS_FULFILLED : 'GET_APPOINTMENTS_MAIN_REPORTS_FULFILLED',
    GET_APPOINTMENTS_MAIN_REPORTS_FAILURE : 'GET_APPOINTMENTS_MAIN_REPORTS_FAILURE',
    GET_APPOINTMENTS_MAIN_REPORTS_REJECTED : 'GET_APPOINTMENTS_MAIN_REPORTS_REJECTED',


    GET_ALL_TEST_BY_TEST : 'GET_ALL_TEST_BY_TEST',
    GET_ALL_TEST_BY_TEST_PENDING : 'GET_ALL_TEST_BY_TEST_PENDING',
    GET_ALL_TEST_BY_TEST_FULFILLED : 'GET_ALL_TEST_BY_TEST_FULFILLED',
    GET_ALL_TEST_BY_TEST_FAILURE : 'GET_ALL_TEST_BY_TEST_FAILURE',
    GET_ALL_TEST_BY_TEST_REJECTED : 'GET_ALL_TEST_BY_TEST_REJECTED',

    EDIT_TEST_SAMPLE : 'EDIT_TEST_SAMPLE',
    EDIT_TEST_SAMPLE_PENDING : 'EDIT_TEST_SAMPLE_PENDING',
    EDIT_TEST_SAMPLE_FULFILLED : 'EDIT_TEST_SAMPLE_FULFILLED',
    EDIT_TEST_SAMPLE_FAILURE : 'EDIT_TEST_SAMPLE_FAILURE',
    EDIT_TEST_SAMPLE_REJECTED : 'EDIT_TEST_SAMPLE_REJECTED',

    REPORT_PRINT : 'REPORT_PRINT',
    REPORT_PRINT_PENDING : 'REPORT_PRINT_PENDING',
    REPORT_PRINT_FULFILLED : 'REPORT_PRINT_FULFILLED',
    REPORT_PRINT_FAILURE : 'REPORT_PRINT_FAILURE',
    REPORT_PRINT_REJECTED : 'REPORT_PRINT_REJECTED',


    TESTS_REPORTS : 'TESTS_REPORTS',
    TESTS_REPORTS_PENDING : 'TESTS_REPORTS_PENDING',
    TESTS_REPORTS_FULFILLED : 'TESTS_REPORTS_FULFILLED',
    TESTS_REPORTS_FAILURE : 'TESTS_REPORTS_FAILURE',
    TESTS_REPORTS_REJECTED : 'TESTS_REPORTS_REJECTED',

};
