
import {myServicePendingActionsConstants} from './constants.js';
export default function myServicePendingReducer(state={
    myServices: [],
    fetched: false,
    loadingSearch:false,
    loading:false,
    saved:false,
  }, action) {

    state = {...state,saved:{...state.saved ,serviceStart:false},loading:{...state.loading ,getService:false }}
    switch (action.type) {



        // Add Patients
        case myServicePendingActionsConstants.GET_ALL_MY_SERVICES_PENDING: {
            return {...state,loading:{...state.loading ,getService:false }, user: action.payload,saved:false}
        }  
        case  myServicePendingActionsConstants.GET_ALL_MY_SERVICES_FULFILLED: {
            return {...state,loading:{...state.loading ,getService:false }, myServices:action?.payload?.data?.data}
        }    
        case myServicePendingActionsConstants.GET_ALL_MY_SERVICES_FAILURE:  {
            return {...state, loading:{...state.loading ,getService:false },error:action.error}
        }
        case myServicePendingActionsConstants.GET_ALL_MY_SERVICES_REJECTED:  {
            return {...state, loading:{...state.loading ,getService:false },error:action.payload}
        }
        
        case myServicePendingActionsConstants.GET_ALL_OTHERS_SERVICES_PENDING: {
            return {...state,loading:{...state.loading ,getService:false }, user: action.payload,saved:false}
        }  
        case  myServicePendingActionsConstants.GET_ALL_OTHERS_SERVICES_FULFILLED: {
            console.log("getAllOthersServices Reducer");
            console.log(action?.payload?.data?.data);
            return {...state,loading:{...state.loading ,getService:false }, otherServices:action?.payload?.data?.data}
        }    
        case myServicePendingActionsConstants.GET_ALL_OTHERS_SERVICES_FAILURE:  {
            return {...state, loading:{...state.loading ,getService:false },error:action.error}
        }
        case myServicePendingActionsConstants.GET_ALL_OTHERS_SERVICES_REJECTED:  {
            return {...state, loading:{...state.loading ,getService:false },error:action.payload}
        }


        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_START_PENDING: {
            return {...state,loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceStart:false } ,user: action.payload,saved:false}
        }  
        case  myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_START_FULFILLED: {
            return {...state,loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceStart:true }, myServiceStart:action?.payload?.data?.data}
        }    
        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_START_FAILURE:  {
            return {...state, loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceStart:false },error:action.error}
        }
        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_START_REJECTED:  {
            return {...state, loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceStart:false  },error:action.payload}
        }

        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_END_PENDING: {
            return {...state,loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceEnd:false } ,user: action.payload,saved:false}
        }  
        case  myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_END_FULFILLED: {
            return {...state,loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceEnd:true }, myServiceEnd:action?.payload?.data?.data}
        }    
        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_END_FAILURE:  {
            return {...state, loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceEnd:false },error:action.error}
        }
        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_END_REJECTED:  {
            return {...state, loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceEnd:false  },error:action.payload}
        }


        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_PENDING: {
            return {...state,loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceEnd:false } ,user: action.payload,saved:false}
        }  
        case  myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_FULFILLED: {
            return {...state,loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceEnd:true }, myServiceEnd:action?.payload?.data?.data}
        }    
        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_FAILURE:  {
            return {...state, loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceEnd:false },error:action.error}
        }
        case myServicePendingActionsConstants.SAVE_SERVICE_APPOINTMENT_DELIVERED_REPORT_REJECTED:  {
            return {...state, loading:{...state.loading ,saveAppointmentService:false },saved:{...state.saved ,serviceEnd:false  },error:action.payload}
        }



        default :
            return state;
    }

}
