export default function reducer(state={
    user: {},
    token: "",
    fetching: false,
    fetched: false,
    fetchedError:false,
    error: null,
  }, action) {

    switch (action.type) {

        case "LOGIN_LARAVEL_USER_PENDING": {
            return {...state, fetching: true}
        }
        case "LOGIN_LARAVEL_USER_REJECTED": {
            
            return {...state, fetching: false, fetchedError: true,error: action.payload}
        }
        case "LOGIN_LARAVEL_USER_FULFILLED": {
            return {
                ...state,
                fetching: false,
                fetched: true,
                user: action.payload.data.data.user,
                token: action.payload.data.data.token,
                permissions: action.payload.data.data.permissions,
                role: action.payload.data.data.role,
                did: action.payload.data.data.did,
            }
        }
        
        case "USER_PERMISSIONS_PENDING": {
            return {...state, fetching: true}
        }
        case "USER_PERMISSIONS_REJECTED": {
            
            return {...state, fetching: false, fetchedError: true,error: action.payload}
        }
        case "USER_PERMISSIONS_FULFILLED": {
            return {
                ...state,
                fetching: false,
                fetched: true,
                permissions: action.payload,
            }
        }
        

        default :
            return state;
    }

}
