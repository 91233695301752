
import {serviceCentersSelectActionsConstants} from './constants.js';
export default function serviceCentersSelectReducer(state={
    myServices: [],
    fetched: false,
    loadingSearch:false,
    loading:false,
    saved:false,
  }, action) {

    state = {...state,saved:false}
    switch (action.type) {



        // Add Patients
        case serviceCentersSelectActionsConstants.GET_SEARCH_SERVICE_CENTER_PENDING: {
            return {...state,loading:{...state.loading,serviceCenterLoading:true}, serviceCenter: action.payload,saved:false}
        }  
        case  serviceCentersSelectActionsConstants.GET_SEARCH_SERVICE_CENTER_FULFILLED: {
            return {...state,loading:{...state.loading,serviceCenterLoading:false}, serviceCenters:action?.payload?.data?.data}
        }    
        case serviceCentersSelectActionsConstants.GET_SEARCH_SERVICE_CENTER_FAILURE:  {
            return {...state, loading:{...state.loading,serviceCenterLoading:false},error:action.error}
        }
        case serviceCentersSelectActionsConstants.GET_SEARCH_SERVICE_CENTER_REJECTED:  {
            return {...state, loading:{...state.loading,serviceCenterLoading:false},error:action.payload}
        }



        default :
            return state;
    }

}
