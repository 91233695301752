export const homeConstants = {

    GET_ALL_SERVICES : 'GET_ALL_SERVICES',
    GET_ALL_SERVICES_PENDING : 'GET_ALL_SERVICES_PENDING',
    GET_ALL_SERVICES_FULFILLED : 'GET_ALL_SERVICES_FULFILLED',
    GET_ALL_SERVICES_FAILURE : 'GET_ALL_SERVICES_FAILURE',
    GET_ALL_SERVICES_REJECTED : 'GET_ALL_SERVICES_REJECTED',


    GET_ALL_SERVICE_DOCTOR : 'GET_ALL_SERVICE_DOCTOR',
    GET_ALL_SERVICE_DOCTOR_PENDING : 'GET_ALL_SERVICE_DOCTOR_PENDING',
    GET_ALL_SERVICE_DOCTOR_FULFILLED : 'GET_ALL_SERVICE_DOCTOR_FULFILLED',
    GET_ALL_SERVICE_DOCTOR_FAILURE : 'GET_ALL_SERVICE_DOCTOR_FAILURE',
    GET_ALL_SERVICE_DOCTOR_REJECTED : 'GET_ALL_SERVICE_DOCTOR_REJECTED',


    GET_ALL_SERVICE_TECHNICIAN : 'GET_ALL_SERVICE_TECHNICIAN',
    GET_ALL_SERVICE_TECHNICIAN_PENDING : 'GET_ALL_SERVICE_TECHNICIAN_PENDING',
    GET_ALL_SERVICE_TECHNICIAN_FULFILLED : 'GET_ALL_SERVICE_TECHNICIAN_FULFILLED',
    GET_ALL_SERVICE_TECHNICIAN_FAILURE : 'GET_ALL_SERVICE_TECHNICIAN_FAILURE',
    GET_ALL_SERVICE_TECHNICIAN_REJECTED : 'GET_ALL_SERVICE_TECHNICIAN_REJECTED',


    GET_CALENDER_DATA_FROM_SERVICE : 'GET_CALENDER_DATA_FROM_SERVICE',
    GET_CALENDER_DATA_FROM_SERVICE_PENDING : 'GET_CALENDER_DATA_FROM_SERVICE_PENDING',
    GET_CALENDER_DATA_FROM_SERVICE_FULFILLED : 'GET_CALENDER_DATA_FROM_SERVICE_FULFILLED',
    GET_CALENDER_DATA_FROM_SERVICE_FAILURE : 'GET_CALENDER_DATA_FROM_SERVICE_FAILURE',
    GET_CALENDER_DATA_FROM_SERVICE_REJECTED : 'GET_CALENDER_DATA_FROM_SERVICE_REJECTED',


    SAVE_CONFIRM_SERVICE_TIME_SLOT : 'SAVE_CONFIRM_SERVICE_TIME_SLOT',
    SAVE_CONFIRM_SERVICE_TIME_SLOT_PENDING : 'SAVE_CONFIRM_SERVICE_TIME_SLOT_PENDING',
    SAVE_CONFIRM_SERVICE_TIME_SLOT_FULFILLED : 'SAVE_CONFIRM_SERVICE_TIME_SLOT_FULFILLED',
    SAVE_CONFIRM_SERVICE_TIME_SLOT_FAILURE : 'SAVE_CONFIRM_SERVICE_TIME_SLOT_FAILURE',
    SAVE_CONFIRM_SERVICE_TIME_SLOT_REJECTED : 'SAVE_CONFIRM_SERVICE_TIME_SLOT_REJECTED',


    GET_DOCTOR_FOR_SERVICE : 'GET_DOCTOR_FOR_SERVICE',
    GET_DOCTOR_FOR_SERVICE_PENDING : 'GET_DOCTOR_FOR_SERVICE_PENDING',
    GET_DOCTOR_FOR_SERVICE_FULFILLED : 'GET_DOCTOR_FOR_SERVICE_FULFILLED',
    GET_DOCTOR_FOR_SERVICE_FAILURE : 'GET_DOCTOR_FOR_SERVICE_FAILURE',
    GET_DOCTOR_FOR_SERVICE_REJECTED : 'GET_DOCTOR_FOR_SERVICE_REJECTED',




    GET_SPECIALIZATION : 'GET_SPECIALIZATION',
    GET_SPECIALIZATION_PENDING : 'GET_SPECIALIZATION_PENDING',
    GET_SPECIALIZATION_FULFILLED : 'GET_SPECIALIZATION_FULFILLED',
    GET_SPECIALIZATION_FAILURE : 'GET_SPECIALIZATION_FAILURE',

    GET_SPECIALIZED_DOCTOR : 'GET_SPECIALIZED_DOCTOR',
    GET_SPECIALIZED_DOCTOR_PENDING : 'GET_SPECIALIZED_DOCTOR_PENDING',
    GET_SPECIALIZED_DOCTOR_FULFILLED : 'GET_SPECIALIZED_DOCTOR_FULFILLED',
    GET_SPECIALIZED_DOCTOR_FAILURE : 'GET_SPECIALIZED_DOCTOR_FAILURE',

    GET_SPECIALIZED_DOCTOR_SCHEDULE : 'GET_SPECIALIZED_DOCTOR_SCHEDULE',
    GET_SPECIALIZED_DOCTOR_SCHEDULE_PENDING : 'GET_SPECIALIZED_DOCTOR_SCHEDULE_PENDING',
    GET_SPECIALIZED_DOCTOR_SCHEDULE_FULFILLED : 'GET_SPECIALIZED_DOCTOR_SCHEDULE_FULFILLED',
    GET_SPECIALIZED_DOCTOR_SCHEDULE_FAILURE : 'GET_SPECIALIZED_DOCTOR_SCHEDULE_FAILURE',

    GET_SEARCHED_DOCTOR : 'GET_SEARCHED_DOCTOR',
    GET_SEARCHED_DOCTOR_PENDING : 'GET_SEARCHED_DOCTOR_PENDING',
    GET_SEARCHED_DOCTOR_FULFILLED : 'GET_SEARCHED_DOCTOR_FULFILLED',
    GET_SEARCHED_DOCTOR_FAILURE : 'GET_SEARCHED_DOCTOR_FAILURE',

    GET_SPECIALIZED_SCHEDULE : 'GET_SPECIALIZED_SCHEDULE',
    GET_SPECIALIZED_SCHEDULE_PENDING : 'GET_SPECIALIZED_SCHEDULE_PENDING',
    GET_SPECIALIZED_SCHEDULE_FULFILLED : 'GET_SPECIALIZED_SCHEDULE_FULFILLED',
    GET_SPECIALIZED_SCHEDULE_FAILURE : 'GET_SPECIALIZED_SCHEDULE_FAILURE',

    GET_SPECIALIZED_DOCTOR_APPOINTMENTS : 'GET_SPECIALIZED_DOCTOR_APPOINTMENTS',
    GET_SPECIALIZED_DOCTOR_APPOINTMENTS_PENDING : 'GET_SPECIALIZED_DOCTOR_APPOINTMENTS_PENDING',
    GET_SPECIALIZED_DOCTOR_APPOINTMENTS_FULFILLED : 'GET_SPECIALIZED_DOCTOR_APPOINTMENTS_FULFILLED',
    GET_SPECIALIZED_DOCTOR_APPOINTMENTS_FAILURE : 'GET_SPECIALIZED_DOCTOR_APPOINTMENTS_FAILURE',

    GET_APPOINTMENTS : 'GET_APPOINTMENTS',
    GET_APPOINTMENTS_PENDING : 'GET_APPOINTMENTS_PENDING',
    GET_APPOINTMENTS_FULFILLED : 'GET_APPOINTMENTS_FULFILLED',
    GET_APPOINTMENTS_FAILURE : 'GET_APPOINTMENTS_FAILURE',


    GET_DOCTORS_AVAILABLITIES : 'GET_DOCTORS_AVAILABLITIES',
    GET_DOCTORS_AVAILABLITIES_PENDING : 'GET_DOCTORS_AVAILABLITIES_PENDING',
    GET_DOCTORS_AVAILABLITIES_FULFILLED : 'GET_DOCTORS_AVAILABLITIES_FULFILLED',
    GET_DOCTORS_AVAILABLITIES_FAILURE : 'GET_DOCTORS_AVAILABLITIES_FAILURE',

    GET_DOCOTORS_APPOINTMENTS_FOR_TIMESLOT : 'GET_DOCOTORS_APPOINTMENTS_FOR_TIMESLOT',
    GET_DOCOTORS_APPOINTMENTS_FOR_TIMESLOT_PENDING : 'GET_DOCOTORS_APPOINTMENTS_FOR_TIMESLOT_PENDING',
    GET_DOCOTORS_APPOINTMENTS_FOR_TIMESLOT_FULFILLED : 'GET_DOCOTORS_APPOINTMENTS_FOR_TIMESLOT_FULFILLED',
    GET_DOCOTORS_APPOINTMENTS_FOR_TIMESLOT_FAILURE : 'GET_DOCOTORS_APPOINTMENTS_FOR_TIMESLOT_FAILURE',

};
