
import {serviceTypesSelectActionsConstants} from './constants.js';
export default function serviceTypesSelectReducer(state={
    myServices: [],
    fetched: false,
    loadingSearch:false,
    loading:false,
    saved:false,
  }, action) {

    state = {...state,saved:false}
    switch (action.type) {



        // Add Patients
        case serviceTypesSelectActionsConstants.GET_SEARCH_SERVICE_TYPE_PENDING: {
            return {...state,loading:{...state.loading , doctorLoading:true}, serviceType: action.payload,saved:false}
        }  
        case  serviceTypesSelectActionsConstants.GET_SEARCH_SERVICE_TYPE_FULFILLED: {
            return {...state,loading:{...state.loading , doctorLoading:false}, serviceTypes:action?.payload?.data?.data}
        }    
        case serviceTypesSelectActionsConstants.GET_SEARCH_SERVICE_TYPE_FAILURE:  {
            return {...state, loading:{...state.loading , doctorLoading:false},error:action.error}
        }
        case serviceTypesSelectActionsConstants.GET_SEARCH_SERVICE_TYPE_REJECTED:  {
            return {...state, loading:{...state.loading , doctorLoading:false},error:action.payload}
        }



        default :
            return state;
    }

}
