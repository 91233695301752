export const labApprovalSignedConstants = {


    GET_ALL_MY_LAB_APPROVAL_SIGNED: 'GET_ALL_MY_LAB_APPROVAL_SIGNED',
    GET_ALL_MY_LAB_APPROVAL_SIGNED_PENDING: 'GET_ALL_MY_LAB_APPROVAL_SIGNED_PENDING',
    GET_ALL_MY_LAB_APPROVAL_SIGNED_FULFILLED: 'GET_ALL_MY_LAB_APPROVAL_SIGNED_FULFILLED',
    GET_ALL_MY_LAB_APPROVAL_SIGNED_FAILURE: 'GET_ALL_MY_LAB_APPROVAL_SIGNED_FAILURE',
    GET_ALL_MY_LAB_APPROVAL_SIGNED_REJECTED: 'GET_ALL_MY_LAB_APPROVAL_SIGNED_REJECTED',


    SAVE_LAB_APPROVAL_CHECKED: 'SAVE_LAB_APPROVAL_CHECKED',
    SAVE_LAB_APPROVAL_CHECKED_PENDING: 'SAVE_LAB_APPROVAL_CHECKED_PENDING',
    SAVE_LAB_APPROVAL_CHECKED_FULFILLED: 'SAVE_LAB_APPROVAL_CHECKED_FULFILLED',
    SAVE_LAB_APPROVAL_CHECKED_FAILURE: 'SAVE_LAB_APPROVAL_CHECKED_FAILURE',
    SAVE_LAB_APPROVAL_CHECKED_REJECTED: 'SAVE_LAB_APPROVAL_CHECKED_REJECTED',

    
    SAVE_LAB_APPROVAL_SIGNED: 'SAVE_LAB_APPROVAL_SIGNED',
    SAVE_LAB_APPROVAL_SIGNED_PENDING: 'SAVE_LAB_APPROVAL_SIGNED_PENDING',
    SAVE_LAB_APPROVAL_SIGNED_FULFILLED: 'SAVE_LAB_APPROVAL_SIGNED_FULFILLED',
    SAVE_LAB_APPROVAL_SIGNED_FAILURE: 'SAVE_LAB_APPROVAL_SIGNED_FAILURE',
    SAVE_LAB_APPROVAL_SIGNED_REJECTED: 'SAVE_LAB_APPROVAL_SIGNED_REJECTED',

    SAVE_LAB_PDF_REPORT: 'SAVE_LAB_PDF_REPORT',
    SAVE_LAB_PDF_REPORT_PENDING: 'SAVE_LAB_PDF_REPORT_PENDING',
    SAVE_LAB_PDF_REPORT_FULFILLED: 'SAVE_LAB_PDF_REPORT_FULFILLED',
    SAVE_LAB_PDF_REPORT_FAILURE: 'SAVE_LAB_PDF_REPORT_FAILURE',
    SAVE_LAB_PDF_REPORT_REJECTED: 'SAVE_LAB_PDF_REPORT_REJECTED',

    SAVE_LAB_FALSE : 'SAVE_LAB_FALSE',

};
