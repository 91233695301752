export const serviceScheduleConstants = {

    FETCH_ALL_AVAILABLITIES : 'FETCH_ALL_AVAILABLITIES',
    FETCH_ALL_AVAILABLITIES_PENDING : 'FETCH_ALL_AVAILABLITIES_PENDING',
    FETCH_ALL_AVAILABLITIES_FULFILLED : 'FETCH_ALL_AVAILABLITIES_FULFILLED',
    FETCH_ALL_AVAILABLITIES_FAILURE : 'FETCH_ALL_AVAILABLITIES_FAILURE',

    CREATE_SERVICE_AVAILABILITY : 'CREATE_SERVICE_AVAILABILITY',
    CREATE_SERVICE_AVAILABILITY_PENDING : 'CREATE_SERVICE_AVAILABILITY_PENDING',
    CREATE_SERVICE_AVAILABILITY_FULFILLED : 'CREATE_SERVICE_AVAILABILITY_FULFILLED',
    CREATE_SERVICE_AVAILABILITY_FAILURE : 'CREATE_SERVICE_AVAILABILITY_FAILURE',
    CREATE_SERVICE_AVAILABILITY_REJECTED : 'CREATE_SERVICE_AVAILABILITY_REJECTED',


    FETCH_SERVICE_AVAILABLITY : 'FETCH_SERVICE_AVAILABLITY',
    FETCH_SERVICE_AVAILABLITY_PENDING : 'FETCH_SERVICE_AVAILABLITY_PENDING',
    FETCH_SERVICE_AVAILABLITY_FULFILLED : 'FETCH_SERVICE_AVAILABLITY_FULFILLED',
    FETCH_SERVICE_AVAILABLITY_FAILURE : 'FETCH_SERVICE_AVAILABLITY_FAILURE',


    CHANGE_STATUS_OF_SERVICE_AVAILABLITY : 'CHANGE_STATUS_OF_SERVICE_AVAILABLITY',
    CHANGE_STATUS_OF_SERVICE_AVAILABLITY_PENDING : 'CHANGE_STATUS_OF_SERVICE_AVAILABLITY_PENDING',
    CHANGE_STATUS_OF_SERVICE_AVAILABLITY_FULFILLED : 'CHANGE_STATUS_OF_SERVICE_AVAILABLITY_FULFILLED',
    CHANGE_STATUS_OF_SERVICE_AVAILABLITY_FAILURE : 'CHANGE_STATUS_OF_SERVICE_AVAILABLITY_FAILURE',
    CHANGE_STATUS_OF_SERVICE_AVAILABLITY_REJECTED : 'CHANGE_STATUS_OF_SERVICE_AVAILABLITY_REJECTED',


    UPDATE_SERVICE_AVAILABLITY : 'UPDATE_SERVICE_AVAILABLITY',
    UPDATE_SERVICE_AVAILABLITY_PENDING : 'UPDATE_SERVICE_AVAILABLITY_PENDING',
    UPDATE_SERVICE_AVAILABLITY_FULFILLED : 'UPDATE_SERVICE_AVAILABLITY_FULFILLED',
    UPDATE_SERVICE_AVAILABLITY_FAILURE : 'UPDATE_SERVICE_AVAILABLITY_FAILURE',


};
