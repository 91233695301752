
import {labApprovalCheckedConstants} from './constants.js';
export default function labApprovalCheckedReducer(state={
    myLabsApproval: {},
    othersLabsApproval: {},
    labDepartments: [],
    fetched: false,
    loadingSearch:false,
    loading:false,
    saved:false,
    save : {}
  }, action) {

    state = {...state,saved:{...state.saved ,labStart:false},loading:{...state.loading ,getLab:false }}
    switch (action.type) {



        // My 
        case labApprovalCheckedConstants.GET_ALL_MY_LAB_APPROVAL_CHECKED_PENDING: {
            return {...state,loading:{...state.loading ,getLab:false }, user: action.payload,saved:false}
        }  
        case  labApprovalCheckedConstants.GET_ALL_MY_LAB_APPROVAL_CHECKED_FULFILLED: {
            return {...state,loading:{...state.loading ,getLab:false }, myLabsApproval:action?.payload?.data?.data}
        }    
        case labApprovalCheckedConstants.GET_ALL_MY_LAB_APPROVAL_CHECKED_FAILURE:  {
            return {...state, loading:{...state.loading ,getLab:false },error:action.error}
        }
        case labApprovalCheckedConstants.GET_ALL_MY_LAB_APPROVAL_CHECKED_REJECTED:  {
            return {...state, loading:{...state.loading ,getLab:false },error:action.payload}
        }


         // Others
        case labApprovalCheckedConstants.GET_ALL_OTHERS_LAB_APPROVAL_CHECKED_PENDING: {
            return {...state,loading:{...state.loading ,getLab:false }, user: action.payload,saved:false}
        }  
        case  labApprovalCheckedConstants.GET_ALL_OTHERS_LAB_APPROVAL_CHECKED_FULFILLED: {
            return {...state,loading:{...state.loading ,getLab:false }, othersLabsApproval:action?.payload?.data?.data}
        }    
        case labApprovalCheckedConstants.GET_ALL_OTHERS_LAB_APPROVAL_CHECKED_FAILURE:  {
            return {...state, loading:{...state.loading ,getLab:false },error:action.error}
        }
        case labApprovalCheckedConstants.GET_ALL_OTHERS_LAB_APPROVAL_CHECKED_REJECTED:  {
            return {...state, loading:{...state.loading ,getLab:false },error:action.payload}
        }


        case labApprovalCheckedConstants.SAVE_LAB_APPROVAL_CHECKED_PENDING: {
            return {...state,loading:{...state.loading , saveLabReport:true },save:{...state.save , saveLabReport:false , checkedError : false } ,}
        }  
        case  labApprovalCheckedConstants.SAVE_LAB_APPROVAL_CHECKED_FULFILLED: {
            return {...state,loading:{...state.loading , saveLabReport:false } ,save:{...state.save , saveLabReport:true , checkedError : false } , labReport:action?.payload?.data?.data}
        }    
        case labApprovalCheckedConstants.SAVE_LAB_APPROVAL_CHECKED_FAILURE:  {
            
            return {...state, loading:{...state.loading , saveLabReport:false } ,save:{...state.save , saveLabReport:false , checkedError : true } ,error:action.error}
        }
        case labApprovalCheckedConstants.SAVE_LAB_APPROVAL_CHECKED_REJECTED:  {
            return {...state, loading:{...state.loading , saveLabReport:false } ,save:{...state.save , saveLabReport:false , checkedError : true} ,error:action.payload}
        }


        case labApprovalCheckedConstants.GET_LAB_DEPARTMENT_PENDING: {
            return {...state,error:action.payload}
        }  
        case  labApprovalCheckedConstants.GET_LAB_DEPARTMENT_FULFILLED: {
            return {...state,labDepartments:action?.payload?.data?.data}
        }    
        case labApprovalCheckedConstants.GET_LAB_DEPARTMENT_FAILURE:  {
            return {...state,error:action.payload}
        }
        case labApprovalCheckedConstants.GET_LAB_DEPARTMENT_REJECTED:  {
            return {...state,error:action.payload}
        }

        case labApprovalCheckedConstants.SAVE_LAB_FALSE :  {
            return {...state,save:{...state.save , 
                    
                     saveLabReport:false } 
                      ,error:{}}
        }
       
        default :
            return state;
    }

}
